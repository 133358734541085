import { withTranslation } from 'react-i18next';
import { Container, InputError } from '../Input/styles';
import { Label } from '../TextArea/styles';
import { datePickeProps, datePickerEditRecordProps } from '../types';
import moment from 'moment';
import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

// disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}

const DatePickersEditRecord = ({ name, onChange, error, t, value, disable }: datePickerEditRecordProps) => {
  return (
    <Container>
      <Label htmlFor={name}>{t(name)}</Label>
      <DatePicker
        value={value}
        style={{ width: '100%' }}
        // size="large"
        onChange={onChange}
        format={'MM-DD-YYYY'}
        disabledDate={disable ? (current) => current.isBefore(moment()) : () => false}
      />
      <InputError>{error}</InputError>
    </Container>
  );
};

export default withTranslation()(DatePickersEditRecord);
