import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Camera, CameraType } from './Camera';
import { UploadOutlined } from '@ant-design/icons';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  @media (max-width: 900px) {
    width: 90%;
    height: 45%;
    //heigth: 80%;
  }
`;

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  //width: 30%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  align-items: center;
  //justify-content: space-evenly;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column;
  @media (max-width: 900px) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 30%;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;

const TakePhotoButton = styled(Button)`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const ChangeFacingCameraButton = styled(Button)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  padding: 40px;
  &:disabled {
    opacity: 0;
    cursor: default;
    padding: 60px;
  }
  @media (max-width: 400px) {
    padding: 40px 5px;
    &:disabled {
      padding: 40px 25px;
    }
  }
`;

const ImagePreview = styled.div<{ image: string | null }>`
  width: 120px;
  height: 120px;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 400px) {
    width: 100px;
    height: 120px;
  }
`;

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 30px;
  height: 70%;
  @media (max-width: 900px) {
    //flex-direction: row;
    margin-top: 5px;
    width: 50%;
    height: 90%;
  }
`;
const CameraContainer = styled.div`
  display: flex;
  justify-content: center;
  //@media (max-width: 400px) {
  //  flex-direction: row;
  //}
`;

const LeftContainer = styled.div`
  height: 30%;
  @media (max-width: 900px) {
    width: 50%;
    height: 100%;
    //heigth: 80%;
  }
`;

const FullScreenImagePreview = styled.div<{ image: string | null }>`
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  background-color: black;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
type CameraTypes = {
  handleUpload: (x: any) => void;
};
const App = ({ handleUpload }: CameraTypes) => {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [image, setImage] = useState<string | null>(null);
  const [showImage, setShowImage] = useState<boolean>(false);
  const camera = useRef<CameraType>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == 'videoinput');
      setDevices(videoDevices);
    })();
  });

  return (
    <Wrapper>
      {showImage ? (
        <FullScreenImagePreview
          image={image}
          onClick={() => {
            setShowImage(!showImage);
          }}
        />
      ) : (
        <Camera
          ref={camera}
          aspectRatio="cover"
          numberOfCamerasCallback={(i: any) => setNumberOfCameras(i)}
          videoSourceDeviceId={activeDeviceId}
          errorMessages={{
            noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
            permissionDenied: 'Permission denied. Please refresh and give camera permission.',
            switchCamera:
              'It is not possible to switch camera to different one because there is only one video device accessible.',
            canvas: 'Canvas is not supported.',
          }}
        />
      )}
      {numberOfCameras > 0 && (
        <Control>
          <LeftContainer>
            <CameraContainer>
              <>
                <TakePhotoButton
                  onClick={() => {
                    if (camera.current) {
                      const photo = camera.current.takePhoto();
                      setImage(photo);
                    }
                  }}
                />
                {numberOfCameras > 1 && (
                  <ChangeFacingCameraButton
                    disabled={numberOfCameras <= 1}
                    onClick={() => {
                      if (camera.current) {
                        const result = camera.current.switchCamera();
                      }
                    }}
                  />
                )}
              </>
            </CameraContainer>
            {image && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '20px' }}>
                <Button
                  style={{
                    width: '170px',
                    height: '50px',
                    color: 'white',
                    fontSize: '20px',
                    backgroundColor: 'red',
                    borderRadius: '10px',
                  }}
                  onClick={() => handleUpload(image)}
                >
                  upload
                </Button>
              </div>
            )}
          </LeftContainer>
          <PreviewContainer>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <select
                style={{
                  width: '200px',
                  height: '30px',
                  backgroundColor: 'lightyellow',
                  color: 'black',
                  borderRadius: '10px',
                }}
                onChange={(event) => {
                  setActiveDeviceId(event.target.value);
                }}
              >
                {devices.map((d) => (
                  <option key={d.deviceId} value={d.deviceId}>
                    {d.label}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
              <ImagePreview
                image={image}
                onClick={() => {
                  setShowImage(!showImage);
                }}
              />
            </div>
          </PreviewContainer>
        </Control>
      )}
    </Wrapper>
  );
};

export default App;
