import * as yup from 'yup';
//Schema

export const ResetPasswordSchema = yup.object().shape({
  currentPassword: yup.string().required('Enter Your Current Password'),
  newPassword: yup
    .string()
    .matches(/^[^&]*$/, 'The "&" character is not allowed in passwords. Please choose a different password.')
    .min(8, 'Password must be grater than 8 characters.')
    .max(20, 'Password must be less than 20 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^*!?])[A-Za-z\d@#$%^*!?]{8,}$/,
      'Must contain 8 characters, one Uppercase, one Lowercase, one Number and one special case Character.',
    )
    .required('Enter New Password'),
  confirmPassword: yup
    .string()
    .matches(/^[^&]*$/, 'The "&" character is not allowed in passwords. Please choose a different password.')
    .min(8, 'Password must be grater than 8 characters.')
    .max(20, 'Password must be less than 20 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^*!?])[A-Za-z\d@#$%^*!?]{8,}$/,
      'Must contain 8 characters, one Uppercase, one Lowercase, one Number and one special case Character.',
    )
    .required('Enter Confirm Password')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
