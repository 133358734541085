import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doChangeManagerPassword } from '@app/store/slices/managerSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Common from '@app/components/layouts/Common/CommonLayout.styles';
import { Button, Col, Row, Spin, Tooltip } from 'antd';
import { ButtonContainer, FormGroup } from '@app/components/forms/AddBookingForm/styles';
import { Controller, useForm } from 'react-hook-form';
import { Label } from '@app/components/MyComponents/TextArea/styles';
import { InputError } from '@app/components/MyComponents/PasswordManagerInput/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateSlotsSchema } from '@app/components/forms/schemaAddManager';
import Input from '../../MyComponents/Input';
import _ from 'lodash';
import { doEditSlots } from '@app/store/slices/bookingSlice';

type comProps = {
  submitted: () => void;
  record: any;
};

export const EditSlotsForm: React.FC<comProps> = (props) => {
  const { submitted, record } = props;
  const dispatch = useAppDispatch();
  const defaultValues = { slot: record };
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UpdateSlotsSchema),
    defaultValues,
  });

  const { t } = useTranslation();

  const Submit = (values: any) => {
    setLoading(true);
    dispatch(doEditSlots(values))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
        submitted();
        setValue('slot', '');
        setLoading(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Row justify="center" align="middle">
      <div style={{ position: 'relative' }}>
        <FormGroup autoComplete="off" onSubmit={handleSubmit(Submit)}>
          <Common.FormTitle>Total Slots: {record}</Common.FormTitle>

          <Col span={24}>
            <>
              <Controller
                name="slot"
                control={control}
                render={({ field }) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      {...register('slot')}
                      type="number"
                      name="Enter Slots"
                      placeholder=""
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      error={errors.slot && errors.slot.message}
                    />
                  </div>
                )}
              />
            </>
          </Col>

          <ButtonContainer>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              Update {isLoading && <Spin />}
            </Button>
          </ButtonContainer>
        </FormGroup>
      </div>
    </Row>
  );
};
