import React, { useEffect, useState } from 'react';
import { Col, Row, Spin, Card, Typography, Button, Table, Space, Switch } from 'antd';
import { Table as NewTable } from '@app/components/common/Table/Table';
import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';
import { doBulkCheckIn, doCheckIn, doGetReservations, doUnused } from '@app/store/slices/bookingSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import CameraApp from '@app/pages/Calendar/cameraApp';
import { QrReader } from 'react-qr-reader';
import axios from 'axios';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import _ from 'lodash';
import { ArrowDownOutlined, CameraOutlined, ExpandOutlined, RedoOutlined } from '@ant-design/icons';
import { useResponsive } from '@app/hooks/useResponsive';
import { Ribbon } from '@app/components/common/Badge/Badge';
import { Modal } from '@app/components/common/Modal/Modal';
import AddManagerForm from '@app/components/forms/addManagerForm';
import { ChangeManagerPasswordForm } from '@app/components/forms/ChangeManagerPasswordForm/ChangeManagerForm';
import { EditRecordForm } from '@app/components/forms/EditRecordForm/EditRecord';
import { EditSailingDateSchema } from '@app/components/forms/EditRecordSchema';
import { EditSailingDateFrom } from '@app/components/forms/EditSailingDateForm/EditSailingDate';
import { httpApi } from '@app/api/http.api';
import EmailNotificationForm from '@app/components/forms/EmailNotificationForm';
import { getNotificationEmail } from '@app/store/slices/authSlice';
const CryptoJS = require('crypto-js');

const { Column } = Table;
function App() {
  const bookings = useAppSelector((state) => state.booking);
  const token = useAppSelector((state) => state.auth);
  const bookingsData: any = bookings.ReservationData;
  const tokenData: any = token.token;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingCheckin, setLoadingCheckin] = useState(false);
  const [loadingCheckinImage, setLoadingCheckinImage] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const dispatch = useAppDispatch();
  const [showImage, setShowImage] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtersPlate, setFiltersPlate] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [unusedLoading, setUnusedLoading] = useState(false);
  const [resendEmailLoading, setResendEmailLoading] = useState(false);
  const [resendemailId, setResendemailId] = useState('');
  const [bulkLoadingOther, setBulkLoadingOther] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emaiModalOpen, setEmaiModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [newData, setNewData] = useState([]);
  const [data, setData] = useState({});
  const { isTablet } = useResponsive();
  const User = useAppSelector((state) => state.auth);
  const UserData: any = User.UserData;
  const { reservationsEmail } = User;
  const { Title } = Typography;

  const ConvertDate = (date: any) => {
    if (date.endsWith('T00:00:00.000Z')) {
      // If true, split by "T" and return the date part ([0] index)
      return date.split('T')[0];
    } else {
      // If false, return the entire date string unchanged
      return date;
    }
  };

  useEffect(() => {
    if (_.isEmpty(bookingsData)) {
      setFilters([]);
      setSelectedRowKeys([]);
      setNewData(bookingsData);
      return;
    } else {
      const x: any = [];
      const y: any = [];
      const z: any = [];
      let dataForManager = [...bookingsData];
      if (UserData && UserData.type === 'manager') {
        dataForManager = dataForManager.filter(
          (item: any) =>
            moment(ConvertDate(item.sailingDate ? item.sailingDate : '')).format('YYYY-MM-DD') ===
            moment().format('YYYY-MM-DD'),
        );
      }
      dataForManager.map((item: any) =>
        x.push({
          text: item.fullName ? item.fullName : 'Quickbook',
          value: item.fullName ? item.fullName : 'Quickbook',
        }),
      );
      dataForManager.map((item: any) =>
        y.push({
          text: item.licensePlate.toUpperCase(),
          value: item.licensePlate,
        }),
      );
      dataForManager.map((item: any) => z.push({ ...item, key: item._id }));
      setNewData(z);
      setFiltersPlate(y);
      setSelectedRowKeys([]);
      setFilters(x);
    }
  }, [bookingsData]);

  const getReserved = () => {
    const d = {
      status: 'reserved',
    };

    setLoading(true);
    dispatch(doGetReservations(d))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getReserved();
  }, []);

  // const getCellData = (x: any) => bookingsData.find((sI: any) => sI.checkIn.includes(moment(x).format('YYYY-MM-DD')));

  const handleNavigate = () => {
    navigate('/addNewBooking');
  };
  const dataURLtoFile = (dataurl: any, filename: any) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  const Link =
    process.env.REACT_APP_ENVIROMENTS === 'production'
      ? 'https://api.parkgenix.com/api/'
      : 'https://uat.parkgenix.com/api/';
  const handleImage = (x: any) => {
    setLoadingCheckinImage(true);
    const file = dataURLtoFile(x, 'plate.jpeg');
    const ImageData: any = new FormData();
    ImageData.append('plate', file);
    setShowImage(!showImage);

    axios
      .post(Link + 'reservation/check/in/plate', ImageData, {
        headers: {
          'Content-type': 'multipart/form-date',
          Authorization: `Bearer ${tokenData}`,
        },
      })
      .then((res) => {
        notificationController.success({ message: res.data.message });
        getReserved();
      })
      .catch((err) => {
        console.log(err.response.data.message);
        console.log(err.response.message);
        if (err.response?.status === 401) {
          navigate('/logout');
          notificationController.error({
            message: 'Your Session has been expired',
          });
        } else {
          notificationController.error({
            message: err.response?.data.message || 'Error completing your request. Please try again in a few minutes',
          });
        }
      })
      .finally(() => setLoadingCheckinImage(false));
  };

  const handleCheckQr = (x: any) => {
    if (!showQr) return;

    const val = CryptoJS.AES.decrypt(x, process.env.REACT_APP_KEY_SCAN);
    const originalText = val.toString(CryptoJS.enc.Utf8);
    if (!originalText) {
      notificationController.error({
        message: 'Invalid QR Code.',
      });
      return;
    }
    setShowQr(!showQr);
    const y = { id: originalText };
    setLoadingCheckin(true);
    dispatch(doCheckIn(y))
      .unwrap()
      .then((res) => notificationController.success({ message: res.message }))
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoadingCheckin(false));
  };

  const MobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      )
        check = true;
    })(navigator.userAgent);
    return check;
  };

  useEffect(() => {
    const a = MobileCheck();
    setIsMobile(a);
  }, []);

  const BulkCheckIn = () => {
    const d = {
      id: selectedRowKeys,
    };
    setBulkLoading(true);
    dispatch(doBulkCheckIn(d))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setBulkLoading(false);
        // selectedRowKeys([]);
        setSelectedRowKeys([]);
      });
  };

  const handleUnused = () => {
    const d = {
      id: selectedRowKeys,
      status: 'unused',
    };
    setUnusedLoading(true);
    dispatch(doUnused(d))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
        getReserved();
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setUnusedLoading(false);
        setSelectedRowKeys([]);
      });
  };

  const BulkCheckInOther = () => {
    const x: any = [];
    bookingsData.map((item: any) => {
      x.push(item._id);
    });
    const dif = _.differenceWith(x, selectedRowKeys, _.isEqual);
    const d = {
      id: dif,
    };
    setBulkLoadingOther(true);
    dispatch(doBulkCheckIn(d))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setBulkLoadingOther(false);
        selectedRowKeys([]);
      });
  };
  const handleOpen = (d: any) => {
    setData(d);
    setIsModalOpen(true);
  };

  const handleResendEmail = (d: any) => {
    setResendemailId(d._id);
    setResendEmailLoading(true);
    httpApi
      .post(Link + 'reservation/resend/email', { id: d._id })
      .then((res: any) => {
        notificationController.success({ message: res.data.message });
        setResendEmailLoading(false);
        setResendemailId('');
      })
      .catch((err) => {
        setResendemailId('');
        setResendEmailLoading(false);
        notificationController.error({ message: err.message });
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setData({});
  };
  const handleSubmitted = () => {
    setIsModalOpen(false);
    setData({});
    setSelectedRowKeys([]);
    getReserved();
  };
  const handleRowSelectionChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: handleRowSelectionChange,
  };

  const handleEmailNotification = () => {
    setEmaiModalOpen(true);
  };

  const handleEmailNotificationOk = (x: any) => {
    setLoader(true);
    httpApi
      .post(Link + 'auth/update/email/status', { reservationsEmail: !x })
      .then((res: any) => {
        notificationController.success({ message: res.data.message });
        dispatch(getNotificationEmail(!x));
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setLoader(false);
        handleCancelEmailNotification();
      });
  };

  const handleCancelEmailNotification = () => {
    setEmaiModalOpen(false);
  };

  return (
    <div>
      <PageTitle>Reserved</PageTitle>
      {showImage && (
        <Button icon={<CameraOutlined />} style={{ marginBottom: '20px' }} onClick={() => setShowImage(!showImage)}>
          Close Camera
        </Button>
      )}
      {showQr && (
        <Button icon={<ExpandOutlined />} style={{ marginBottom: '20px' }} onClick={() => setShowQr(!showQr)}>
          Close QR
        </Button>
      )}
      {isMobile && (
        <div
          style={
            isTablet
              ? { display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }
              : { display: 'flex', flexDirection: 'column', textAlign: 'center' }
          }
        >
          {!showImage && !showQr && <Title level={isTablet ? 4 : 5}>To Park Any Car, Use Plate Scan / QR Scan</Title>}
          <div style={isTablet ? { display: 'flex' } : { display: 'flex', justifyContent: 'center' }}>
            {!showImage && !showQr && (
              <div style={isTablet ? { marginRight: '20px' } : { marginRight: '10px' }}>
                <Button
                  disabled={loadingCheckinImage}
                  size="small"
                  onClick={() => {
                    setShowImage(!showImage);
                  }}
                  icon={<CameraOutlined />}
                >
                  Plate Scan {loadingCheckinImage && <Spin />}
                </Button>
              </div>
            )}
            {!showQr && !showImage && (
              <div>
                <Button
                  size="small"
                  disabled={loadingCheckin}
                  onClick={() => {
                    setShowQr(!showQr);
                  }}
                  icon={<ExpandOutlined />}
                >
                  QR Scan {loadingCheckin && <Spin />}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      {showImage && (
        <Row gutter={{ xs: 32, sm: 32, md: 24, lg: 12, xl: 12 }}>
          <Col className="gutter-row" lg={12} sm={24} xs={24} xl={12} md={24}>
            <CameraApp handleUpload={(x: any) => handleImage(x)} />
          </Col>
        </Row>
      )}
      {showQr && (
        <Row gutter={{ xs: 32, sm: 32, md: 24, lg: 12, xl: 12 }}>
          <Col className="gutter-row" lg={12} sm={24} xs={24} xl={12} md={24}>
            <QrReader
              key="environment"
              constraints={{ facingMode: 'environment' }}
              onResult={(result: any, error) => {
                if (!!result) {
                  // setData(result?.text);
                  handleCheckQr(result?.text);
                }
                // if (!!error) {
                //   console.info(error);
                // }
              }}
            />
          </Col>
        </Row>
      )}
      {!showImage && !showQr && (
        <Card
          style={{ marginTop: '10px' }}
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Title level={5} style={{ fontWeight: 'bold' }}>
                  Reserved: {loading ? <Spin /> : bookingsData && bookingsData.length}
                </Title>
                {UserData && UserData.type === 'admin' && (
                  <Space size="small" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Typography style={{ whiteSpace: 'normal' }}>
                      Do you want to receive all the reservation emails?
                    </Typography>
                    <Switch
                      disabled={loading}
                      loading={loading}
                      onChange={() => handleEmailNotification()}
                      checked={reservationsEmail}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />
                  </Space>
                )}
              </div>
              <div>
                <Button
                  size={isTablet ? 'large' : 'small'}
                  disabled={loading}
                  icon={loading ? <Spin /> : <RedoOutlined />}
                  onClick={() => getReserved()}
                />
              </div>
            </div>
          }
        >
          {!_.isEmpty(selectedRowKeys) && (
            <div style={isTablet ? { display: 'flex' } : { display: 'flex', flexDirection: 'column' }}>
              <Button size={'small'} onClick={BulkCheckIn} disabled={unusedLoading || bulkLoading || bulkLoadingOther}>
                Checkin selected items {bulkLoading && <Spin />}
              </Button>
              <Button
                style={isMobile ? { marginTop: '10px', marginLeft: '0px' } : { marginTop: '0px', marginLeft: '10px' }}
                size={'small'}
                onClick={handleUnused}
                disabled={unusedLoading || bulkLoading || bulkLoadingOther}
              >
                Marked as unused {unusedLoading && <Spin />}
              </Button>
              {/*<Button*/}
              {/*  disabled={bulkLoading || bulkLoadingOther || bookingsData.length === selectedData.length}*/}
              {/*  size={'small'}*/}
              {/*  style={isTablet ? { marginLeft: '10px' } : { marginTop: '5px' }}*/}
              {/*  onClick={BulkCheckInOther}*/}
              {/*>*/}
              {/*  Checkin unselected items {bulkLoadingOther && <Spin />}*/}
              {/*</Button>*/}
            </div>
          )}

          <Row gutter={[30, 30]} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <NewTable
                loading={loading}
                rowSelection={rowSelection}
                style={{ background: 'transparent' }}
                dataSource={newData}
                pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
              >
                <Column
                  title="Details"
                  responsive={['sm']}
                  sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.createdAt && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Booking:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.createdAt)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}
                      {record && record.sailingDate && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Sailing:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.sailingDate)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}
                      {record && record.checkOut && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Exit:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.checkOut)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                />
                <Column
                  title="Ship"
                  responsive={['sm']}
                  sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.ship && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ marginLeft: '3px' }}>{record.ship}</Typography>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                />
                <Column
                  title="Make / Model"
                  responsive={['sm']}
                  sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.carMakeModel && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ marginLeft: '3px' }}>{record.carMakeModel}</Typography>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                />
                <Column
                  title="Details"
                  filters={filtersPlate}
                  filterSearch={true}
                  onFilter={(value: any, record: any) => record.licensePlate.includes(value)}
                  // sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.fullName && record.fullName ? (
                        <>
                          {record && record.fullName && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px', fontWeight: 'bold' }}>
                                {record.fullName}
                              </Typography>
                            </div>
                          )}
                          {record && record.email && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.email}</Typography>
                            </div>
                          )}
                          {record && record.ship && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.ship}</Typography>
                            </div>
                          )}
                          {record && record.carMakeModel && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.carMakeModel}</Typography>
                            </div>
                          )}
                          {record && record.phone && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.phone}</Typography>
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Ribbon style={{ borderRadius: '3px 0px 0px 3px' }} text={'Quickbook'} color={'green'} />
                        </div>
                      )}
                      {record && record.createdAt && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Booking:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.createdAt)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}
                      {record && record.sailingDate && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Sailing:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.sailingDate)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}
                      {record && record.checkOut && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Exit:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.checkOut)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}
                      {record && record.amountDetail && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Sail Safe:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>{record.amountDetail.CI ? 'Yes' : 'No'}</Typography>
                        </div>
                      )}
                      <span style={{ fontWeight: 'bold' }}>{record.licensePlate.toUpperCase()}</span>
                      <br />
                      {record && record.color && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ marginLeft: '3px' }}>
                            {record.color.charAt(0).toUpperCase() + record.color.slice(1)}
                          </Typography>
                        </div>
                      )}
                      <Space size="large">
                        {UserData && UserData.type === 'admin' && selectedRowKeys.includes(record._id) && (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Button
                              size={'small'}
                              style={{ marginBottom: '5px' }}
                              disabled={resendEmailLoading}
                              // loading={cRowOperation === record._id}
                              onClick={() => handleOpen(record)}
                            >
                              Update Sailing
                            </Button>
                            {record.email && (
                              <Button
                                size={'small'}
                                style={{ marginTop: '5px' }}
                                disabled={resendEmailLoading}
                                // loading={cRowOperation === record._id}
                                onClick={() => handleResendEmail(record)}
                              >
                                Resend Email {resendemailId === record._id && resendEmailLoading && <Spin />}
                              </Button>
                            )}
                          </div>
                        )}
                      </Space>
                    </React.Fragment>
                  )}
                  responsive={['xs']}
                />
                {/*<Column*/}
                {/*  title="Check Out"*/}
                {/*  dataIndex="checkOut"*/}
                {/*  key="checkOut"*/}
                {/*  responsive={['sm']}*/}
                {/*  sorter={(a: any, b: any) => (a.checkOut < b.checkOut ? -1 : 1)}*/}
                {/*  render={(checkOut: string) => <>{checkOut && `${moment(checkOut).format('MM/DD/YYYY')}`}</>}*/}
                {/*/>*/}
                {/*<Column responsive={['sm']} title="Color" dataIndex="color" key="color" />*/}
                <Column
                  filters={filters}
                  filterSearch={true}
                  onFilter={(value: any, record: any) =>
                    record.fullName
                      ? record.fullName.toLowerCase().includes(value.toLowerCase())
                      : value === 'Quickbook' && !record.fullName
                  }
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.fullName && record.fullName ? (
                        <>
                          {record && record.fullName && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px', fontWeight: 'bold' }}>
                                {record.fullName}
                              </Typography>
                            </div>
                          )}
                          {record && record.email && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.email}</Typography>
                            </div>
                          )}
                          {record && record.phone && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.phone}</Typography>
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Ribbon style={{ borderRadius: '3px 0px 0px 3px' }} text={'Quickbook'} color={'green'} />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  responsive={['sm']}
                  title="Name / Email / Phone"
                />
                <Column
                  filters={filters}
                  filterSearch={true}
                  onFilter={(value: any, record: any) =>
                    record.fullName
                      ? record.fullName.toLowerCase().includes(value.toLowerCase())
                      : value === 'Quickbook' && !record.fullName
                  }
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.amountDetail ? (
                        <>
                          {record.amountDetail.CI ? (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px', fontWeight: 'bold' }}>Yes</Typography>
                            </div>
                          ) : (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px', fontWeight: 'bold' }}>No</Typography>
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Ribbon style={{ borderRadius: '3px 0px 0px 3px' }} text={'Quickbook'} color={'green'} />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  responsive={['sm']}
                  title="Sail Safe"
                />
                <Column
                  filters={filtersPlate}
                  filterSearch={true}
                  onFilter={(value: any, record: any) => record.licensePlate.includes(value)}
                  render={(record: any) => (
                    <React.Fragment>
                      {record.licensePlate && record.licensePlate.toUpperCase()}
                      <br />
                      {record && record.color && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ marginLeft: '3px' }}>
                            {record.color.charAt(0).toUpperCase() + record.color.slice(1)}
                          </Typography>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                  responsive={['sm']}
                  title="License / Color"
                />

                {UserData && UserData.type === 'admin' && selectedRowKeys && !_.isEmpty(selectedRowKeys) && (
                  <Column
                    title="Action"
                    render={(record: any) => (
                      <Space size="large">
                        {selectedRowKeys.includes(record._id) && record.bookedBy == 'user' && (
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Button
                              size={'small'}
                              style={{ marginBottom: '5px' }}
                              disabled={resendEmailLoading}
                              // loading={cRowOperation === record._id}
                              onClick={() => handleOpen(record)}
                            >
                              Update Sailing
                            </Button>
                            {record.email && (
                              <Button
                                size={'small'}
                                style={{ marginTop: '5px' }}
                                disabled={resendEmailLoading}
                                // loading={cRowOperation === record._id}
                                onClick={() => handleResendEmail(record)}
                              >
                                Resend Email {resendemailId === record._id && resendEmailLoading && <Spin />}
                              </Button>
                            )}
                          </div>
                        )}
                      </Space>
                    )}
                    responsive={['sm']}
                  />
                )}
                {/*<Column*/}
                {/*  title="Updated At"*/}
                {/*  dataIndex="updatedAt"*/}
                {/*  key="updatedAt"*/}
                {/*  responsive={['sm']}*/}
                {/*  sorter={(a: any, b: any) => (a.updatedAt < b.updatedAt ? -1 : 1)}*/}
                {/*  render={(updatedAt: string) => <>{updatedAt && `${moment(updatedAt).format('MM/DD/YYYY')}`}</>}*/}
                {/*/>*/}
                {/*<Column*/}
                {/*  title="Change Status"*/}
                {/*  key="action"*/}
                {/*  render={(_: any, record: any) => (*/}
                {/*    <div style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
                {/*      <Button*/}
                {/*        disabled={loadingCheckinImage && record?._id === id}*/}
                {/*        size="small"*/}
                {/*        onClick={() => {*/}
                {/*          setId(record?._id);*/}
                {/*          setShowImage(!showImage);*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        PLate {loadingCheckinImage && record?._id === id && <Spin />}*/}
                {/*      </Button>*/}
                {/*      <Button*/}
                {/*        size="small"*/}
                {/*        disabled={loadingCheckin && record?._id === id}*/}
                {/*        onClick={() => {*/}
                {/*          setShowQr(!showQr);*/}
                {/*          setId(record?._id);*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        QR {loadingCheckin && record?._id === id && <Spin />}*/}
                {/*      </Button>*/}
                {/*    </div>*/}
                {/*  )}*/}
                {/*/>*/}
              </NewTable>
            </Col>
          </Row>
        </Card>
      )}
      <Modal
        footer={[]}
        title="Update Record"
        open={isModalOpen}
        onOk={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <EditSailingDateFrom record={data} submitted={handleSubmitted} />
      </Modal>

      <Modal
        footer={[]}
        title="Email Notification"
        open={emaiModalOpen}
        // onOk={handleCancelRefund}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => handleCancelEmailNotification()}
      >
        <Typography>
          Are you sure you want to {reservationsEmail ? <>disable</> : <>enable</>} email notifications?
        </Typography>
        <br></br>

        <EmailNotificationForm
          loader={loader}
          cancel={handleCancelEmailNotification}
          status={reservationsEmail}
          ok={(val: any) => handleEmailNotificationOk(val)}
        />
      </Modal>
    </div>
  );
}

export default App;
