import { Row, Col, Button, Spin, Typography } from 'antd';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactProps } from './types';
import { AddQuickBook, ContactSchema } from './schema';
import Input from '../../MyComponents/Input';

import { ContactContainer, FormGroup, ButtonContainer } from './styles';
import SquarePaymentForm from './SquarePayment';

import moment, { Moment } from 'moment';
import PaymentIdForm from '@app/components/forms/AddBookingForm/paymentIdForm';
import { useNavigate } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { httpApi } from '@app/api/http.api';
import { httpApiMock } from '@app/api/mocks/http.api.mock';

//
const steps = [
  {
    title: 'Booking ',
    content: 'Booking',
  },
  // {
  //   title: 'Personal',
  //   content: 'Personal',
  // },
  // {
  //   title: 'Vehicle',
  //   content: 'Vehicle',
  // },
  // {
  //   title: 'Address',
  //   content: 'Address',
  // },
];

const Contact = ({ id }: ContactProps) => {
  const [current, setCurrent] = useState(0);
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const [loader, setLoader] = useState(false);
  const [paymentView, setPaymentView] = useState(false);
  const [data, setData] = useState<any>({});
  const [ship, setShips] = useState<any>([]);
  const [amount, setAmount] = useState<any>('');
  const [dates, setDates] = useState<any>([]);
  const [reservationSucess, setResrvationSuccess] = useState(false);
  const [shipsLoader, setShipsLoader] = useState(false);
  // const [checkOutDate, setCheckOutDate] = useState<any>('');
  const [availableSlots, setAvailableSlots] = useState(0);
  const [shipData, setShipData] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [reservationError, setResrvationError] = useState<any>(null);
  const [mailFlag, setMailFlag] = useState<any>(false);
  const navigate = useNavigate();

  const Link =
    process.env.REACT_APP_ENVIROMENTS === 'production'
      ? 'https://api.parkgenix.com/api/'
      : 'https://uat.parkgenix.com/api/';

  const apiCall = () => {
    setShipsLoader(true);
    httpApi
      .get(Link + 'reservation/get/ship/active')
      .then((res) => {
        setShips(res.data.data);
        setShipsLoader(false);
      })
      .catch(() => {
        setShipsLoader(false);
        console.log('error');
      });
  };

  const handleShip = (x: any) => {
    const dates = ship.find((w: any) => w.name == x.label);
    setDates(dates.sailing_dates);
  };

  // const validateEmail = (email: any) => {
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(email).toLowerCase());
  // };

  // const handleEmailBlur = (x: any) => {
  //   const v = validateEmail(x);
  //   if (v) {
  //     setMailFlag(true);
  //     axios
  //       .post('https://api.parkgenix.com/api/auth/validateEmailAddress', { email: x })
  //       .then(({ data }) => {
  //         if (data.status && data.data.isDeliverable) {
  //           setInvalidEmail(false);
  //         } else {
  //           setInvalidEmail(true);
  //           setError('email', { type: 'string', message: 'Not deliverable email.' });
  //         }
  //       })
  //       .catch((err) => {
  //         setInvalidEmail(true);
  //         setError('email', { type: 'string', message: 'Not deliverable email.' });
  //       })
  //       .finally(() => setMailFlag(false));
  //   }
  // };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddQuickBook),
  });
  //
  const prev = () => {
    setCurrent(current - 1);
  };

  const getNumberOfDays = (s: any, cIn: any) => {
    let duration = 0;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const wk = s.sort((a, b) => new Date(a) - new Date(b));
    const w = wk.indexOf(cIn);

    let a = moment(wk[w]);

    let b = moment(wk[w + 1]);
    if (!wk[w + 1]) {
      a = moment(wk[w - 1]);
      b = moment(wk[w]);
    }
    // setCheckOutDate(b);
    duration = b.diff(a, 'days');
    return duration;
  };

  const OnSubmit = (x: any) => {
    setResrvationError(null);
    setResrvationSuccess(false);
    const s = shipData?.name;
    const finalData = {
      ship: s,
    };
    const duration = getNumberOfDays(shipData?.sailing_dates, moment().format('YYYY-MM-DD'));
    setAmount(duration * shipData?.rate);
    const w = { ...x, ...finalData };
    setData(w);
    setPaymentView(true);
    setCurrent(current + 1);
  };

  const checkDate = (d: any) => {
    if (moment(d).format('MM-DD-YYYY') > moment().format('MM-DD-YYYY')) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitAllData = () => {
    const duration = getNumberOfDays(shipData?.sailing_dates, moment().format('YYYY-MM-DD'));
    setAmount(duration * shipData?.rate);
    const dataX = {
      ...data,
      status: 'parked',
      checkIn: moment().format('YYYY-MM-DD'),
    };
    setLoader(true);
    httpApi
      .post('reservation/quick/booking', dataX)
      .then((e) => {
        setLoader(false);
        navigate('/parked');
      })
      .catch((e) => {
        notificationController.error({
          message:
            (e.response && e.response.data && e.response.data?.message) ||
            e.message ||
            'Error completing your request. Please try again in a few minutes.',
        });
        setLoader(false);
      });
  };

  const checkSpace = () => {
    setLoader(true);
    httpApi
      .get('reservation/check/quick/book')
      .then((e) => {
        if (e && e.data && e.data.data) {
          setAvailableSlots(e.data.data.slot);
          setShipData(e.data.data.ship);
          setVisible(true);
        }
        // setCurrent(current + 1);
      })
      .catch((err) => {
        setAvailableSlots(0);
        setShipData({});
        notificationController.error({
          message:
            (err.response && err.response.data && err.response.data?.message) ||
            err.message ||
            'Error completing your request. Please try again in a few minutes.',
        });
      })
      .finally(() => setLoader(false));
  };

  React.useEffect(() => {
    checkSpace();
  }, []);

  return (
    <ContactContainer id={id}>
      <Row justify="center" align="middle">
        <Col lg={12} md={16} sm={24} xs={24}>
          <div />

          <div style={{ position: 'relative' }}>
            {shipsLoader && (
              <div style={{ top: '50%', left: '50%', right: 0, zIndex: 20 }}>
                {' '}
                <Spin size={'large'} />{' '}
              </div>
            )}
            {!paymentView && (
              <FormGroup autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
                {current === 0 && (
                  <>
                    <div>
                      {' '}
                      {visible && (
                        <Typography style={{ fontWeight: 'bold', float: 'right' }}>
                          Available Slots: {availableSlots}
                        </Typography>
                      )}
                    </div>

                    <Col span={24}>
                      <Controller
                        name="licensePlate"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...register('licensePlate')}
                            type="phone"
                            name="License Plate"
                            placeholder="Enter License Plate"
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                            error={errors.licensePlate && errors.licensePlate.message}
                          />
                        )}
                      />
                    </Col>
                  </>
                )}

                <ButtonContainer>
                  <Button type="primary" htmlType="submit" disabled={loader}>
                    {current === steps.length - 1 ? 'Submit' : 'Next'} {(mailFlag || loader) && <Spin />}
                  </Button>
                </ButtonContainer>
              </FormGroup>
            )}
          </div>

          <div>
            {paymentView && (
              <>
                <SquarePaymentForm
                  data={data}
                  weekCount={amount}
                  handleSubmitAllData={handleSubmitAllData}
                  loader={loader}
                />
              </>
            )}
          </div>
        </Col>
      </Row>
    </ContactContainer>
  );
};

export default withTranslation()(Contact);
