import { Row, Col, Button, Spin, Tooltip, Switch, Space, DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../MyComponents/Input';
import { FormGroup, ButtonContainer } from './AddBookingForm/styles';
import { notificationController } from '@app/controllers/notificationController';
import { AddShip } from '@app/components/forms/addShipSchema';
import { doAddShip, doGetShips } from '@app/store/slices/shipSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import ShipComponent from '@app/pages/Ships/ShipComponent';

interface managerForm {
  submitted: any;
}

const AddShipFrom = ({ submitted }: managerForm) => {
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const ships = useAppSelector((state) => state.ship);
  const shipsData: any = ships.shipsData;
  const colorsArray =
    shipsData && shipsData.length > 0 && shipsData.map((item: any) => item.color && item.color.toLowerCase());
  const nameArray = shipsData && shipsData.length > 0 ? shipsData.map((item: any) => item.name) : [];

  const [colorPickerValue, setColorPickerValue] = useState('#808080');
  const [shipName, setShipName] = useState('');

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(AddShip(nameArray, colorsArray)),
  });

  const OnSubmit = (x: any) => {
    setLoader(true);
    dispatch(doAddShip(x))
      .unwrap()
      .then((res: any) => {
        dispatch(doGetShips());
        notificationController.success({ message: res.message });
        submitted();
        setLoader(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoader(false);
      });
  };

  return (
    <Row justify="center" align="middle">
      <div style={{ position: 'relative', width: '100%', padding: '0px', margin: '0px' }}>
        <FormGroup style={{ margin: '0px', padding: '0px' }} autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
          <>
            <Col span={24}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...register('name')}
                    type="text"
                    name="Ship Name"
                    placeholder="Enter Ship Name"
                    value={field.value}
                    onKeyDown={(value: any) => {
                      if (!/^[a-zA-Z ]*$/.test(value.key)) {
                        value.preventDefault();
                      }
                    }}
                    onChange={(value) => {
                      field.onChange(value);
                      setShipName(value.target.value);
                    }}
                    error={errors.name && errors.name.message}
                  />
                )}
              />
            </Col>
            <style>
              {`
                .no-spinners input[type='number'] {
                  -moz-appearance: textfield;
                }
                .no-spinners input[type='number']::-webkit-inner-spin-button,
                .no-spinners input[type='number']::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }
              `}
            </style>
            <div className="no-spinners">
              <Col span={24}>
                <Controller
                  name="rate"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...register('rate')}
                      type="number"
                      name="Daily Rate"
                      placeholder="Enter Daily Rate"
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                      error={errors.rate && errors.rate.message}
                    />
                  )}
                />
              </Col>
            </div>
            {/* <Col span={24}>
              <Col span={12}>
                <Label htmlFor={'status'}>Ship Status</Label>
              </Col>
              <Col span={12}>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <Space size="large">
                      <Switch
                        // disabled={UserData && UserData.type === 'manager'}
                        // loading={cRowOperation === record._id}
                        onChange={(x) => {
                          field.onChange(x);
                          // handleChangeStatus(x, record);
                        }}
                        defaultChecked={field.value}
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />
                    </Space>
                  )}
                />
              </Col>
            </Col> */}
            <Col span={24}>
              <>
                <Col span={24}>
                  <Controller
                    name="color"
                    control={control}
                    render={({ field }) => (
                      <Col span={24}>
                        <Input
                          {...register('color')}
                          type="color"
                          // id="colorPicker"
                          placeholder=""
                          name="Pick Color"
                          value={colorPickerValue}
                          onChange={(event) => {
                            field.onChange(event.target.value);
                            setColorPickerValue(event.target.value);
                          }}
                          error={errors.color && errors.color.message}
                        />
                      </Col>
                    )}
                  />
                </Col>
              </>
            </Col>
            <Col span={8}>
              <ShipComponent color={colorPickerValue} />
            </Col>
          </>

          <ButtonContainer>
            <Button type="primary" htmlType="submit" disabled={loader || !isDirty}>
              Add Ship {loader && <Spin />}
            </Button>
          </ButtonContainer>
        </FormGroup>
      </div>
    </Row>
  );
};

export default withTranslation()(AddShipFrom);
