import React, { useEffect, useState } from 'react';
import { Col, Row, Spin, Card, Typography, Table, Button, Space, Timeline } from 'antd';
import { Table as NewTable } from '@app/components/common/Table/Table';
import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';
import { doGetExited, doRevertStatus, filterBookingData } from '@app/store/slices/bookingSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
// import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import _ from 'lodash';
import { Ribbon } from '@app/components/common/Badge/Badge';
import { RedoOutlined } from '@ant-design/icons';
import { useResponsive } from '@app/hooks/useResponsive';
import { EditRecordForm } from '@app/components/forms/EditRecordForm/EditRecord';
import { Modal } from '@app/components/common/Modal/Modal';
import { TimelineProps } from 'antd/es';
import ExitsSearchForm from '@app/components/forms/ExitsSearchForm';

interface MyTimelineProps extends TimelineProps {
  items: {
    label?: string;
    children: string;
  }[];
}

const MyTimeline: React.FC<MyTimelineProps> = ({ items, ...rest }) => {
  return (
    <Timeline {...rest}>
      {items.map((item, index) => (
        <>
          <Timeline.Item key={index} label={item.label}>
            {item.children}
          </Timeline.Item>
        </>
      ))}
    </Timeline>
  );
};

const { Column } = Table;

function App() {
  // const bookings = useAppSelector((state) => state.booking);
  // const User = useAppSelector((state) => state.auth);
  // const UserData: any = User.UserData;
  // const bookingsData: any = bookings.ExitedData;
  // const [loading, setLoading] = useState(false);
  // const [filters, setFilters] = useState([]);
  // const [filtersPlate, setFiltersPlate] = useState([]);
  // const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  // const [revertStatusLoading, setRevertStatusLoading] = useState(false);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [data, setData] = useState({});
  // const [newData, setNewData] = useState([]);
  const dispatch = useAppDispatch();
  // const { isTablet } = useResponsive();
  const [timeLineData, setTimeLineData] = useState([]);
  const [isTimeLineModal, setIsTimeLineModal] = useState(false);
  const data = useAppSelector((state) => state.booking);
  const { filterBookingLoader, bookingFilterData, bookingFilterMsg } = data;
  const { Title } = Typography;

  // useEffect(() => {
  //   if (_.isEmpty(bookingsData)) {
  //     setFilters([]);
  //     setFiltersPlate([]);
  //     setNewData(bookingsData);
  //     return;
  //   } else {
  //     const x: any = [];
  //     const y: any = [];
  //     const z: any = [];
  //     bookingsData.map((item: any) =>
  //       x.push({
  //         text: item.fullName ? item.fullName : 'Quickbook',
  //         value: item.fullName ? item.fullName : 'Quickbook',
  //         key: item._id,
  //       }),
  //     );
  //     bookingsData.map((item: any) =>
  //       z.push({
  //         text: item.licensePlate.toUpperCase(),
  //         value: item.licensePlate,
  //       }),
  //     );
  //     bookingsData.map((item: any) => y.push({ ...item, key: item._id }));
  //     setFiltersPlate(z);
  //     setNewData(y);
  //     setFilters(x);
  //   }
  // }, [bookingsData]);

  // const getCheckout = () => {
  //   const d = {
  //     status: 'exited',
  //   };

  //   setLoading(true);
  //   dispatch(doGetExited(d))
  //     .unwrap()
  //     .catch((err) => {
  //       notificationController.error({ message: err.message });
  //     })
  //     .finally(() => setLoading(false));
  // };

  // useEffect(() => {
  //   getCheckout();
  // }, []);

  // const ConvertDate = (date: any) => {
  //   const newD = date.split('T')[0];
  //   return newD;
  // };

  // const handleOpen = (d: any) => {
  //   setData(d);
  //   setIsModalOpen(true);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  //   setData({});
  // };

  // const handleSubmitted = () => {
  //   setIsModalOpen(false);
  //   setData({});
  //   setSelectedRowKeys([]);
  //   getCheckout();
  // };

  // const handleRowSelectionChange = (selectedRowKeys: any) => {
  //   setSelectedRowKeys(selectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: handleRowSelectionChange,
  // };

  // const BulkRevertStatus = () => {
  //   const d = {
  //     id: selectedRowKeys,
  //     status: 'parked'
  //   };
  //   setRevertStatusLoading(true);
  //   dispatch(doRevertStatus(d))
  //   .unwrap()
  //   .then((res: any) => {
  //     notificationController.success({ message: res.message });
  //     getCheckout();
  //   })
  //   .catch((err) => {
  //       notificationController.error({ message: err.message });
  //     })
  //     .finally(() => {
  //       setRevertStatusLoading(false);
  //       setSelectedRowKeys([]);
  //     });
  // };

  const handleOpenTimeLine = (d: any) => {
    const x: any = [];
    if (d.EventTimeline) {
      d.EventTimeline.map((item: any) => {
        x.push({ label: moment(item.date).format('MM-DD-YYYY hh:mm a'), children: item.event });
      });
    }
    setTimeLineData(x);
    setIsTimeLineModal(true);
  };

  const handleCancelTimeLine = () => {
    setIsTimeLineModal(false);
    setTimeLineData([]);
  };

  const handleSubmit = (x: any) => {
    dispatch(filterBookingData(x))
      .unwrap()
      .then((res: any) => {
        console.log('res', res);
      })
      .catch((err: any) => {
        console.log('err', err);
      });
  };

  return (
    <div>
      <PageTitle>Exited</PageTitle>
      <Card
        style={{ marginTop: '10px', minHeight: '50vh' }}
        title={
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Title level={5} style={{ fontWeight: 'bold' }}>
                Exits: {filterBookingLoader ? <Spin /> : bookingFilterData && bookingFilterData.length}
              </Title>
            </div>
            <div>
              <ExitsSearchForm submit={handleSubmit} />
            </div>
          </div>
        }
      >
        {bookingFilterMsg != '' && <h3 style={{ fontSize: '22px' }}>{bookingFilterMsg}</h3>}

        {bookingFilterData && bookingFilterData.length > 0 && (
          <Row gutter={[30, 30]} style={{ marginTop: '0px' }}>
            <Col span={24}>
              <NewTable
                loading={filterBookingLoader}
                dataSource={bookingFilterData}
                pagination={{ defaultPageSize: 10, hideOnSinglePage: false }}
              >
                <Column
                  title="Details"
                  responsive={['sm']}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.createdAt && (
                        <div style={{ display: 'flex', margin: '5px 0px 5px 0px' }}>
                          <Button size="small" onClick={() => handleOpenTimeLine(record)}>
                            View Timeline
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                />
                <Column
                  title="Check In"
                  responsive={['sm']}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.checkIn && (
                        <Typography>{moment(record.checkIn).format('MM/DD/YYYY')}</Typography>
                      )}
                    </React.Fragment>
                  )}
                />
                <Column
                  title="Check Out"
                  responsive={['sm']}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.checkOut && (
                        <Typography>{moment(record.checkOut).format('MM/DD/YYYY')}</Typography>
                      )}
                    </React.Fragment>
                  )}
                />
                <Column
                  title="Details"
                  responsive={['xs']}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.createdAt && (
                        <div style={{ display: 'flex', margin: '5px 0px 5px 0px' }}>
                          <Button size="small" onClick={() => handleOpenTimeLine(record)}>
                            View Timeline
                          </Button>
                        </div>
                      )}

                      {record && record.checkIn && (
                        <Typography style={{ marginTop: '10px', marginBottom: '10px' }}>
                          {moment(record.checkIn).format('MM/DD/YYYY')}
                        </Typography>
                      )}
                      {record && record.checkOut && (
                        <Typography>{moment(record.checkOut).format('MM/DD/YYYY')}</Typography>
                      )}
                    </React.Fragment>
                  )}
                />
              </NewTable>
            </Col>
          </Row>
        )}
      </Card>
      <Modal
        footer={[]}
        title="Reservation Timeline"
        open={isTimeLineModal}
        onOk={handleCancelTimeLine}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleCancelTimeLine}
      >
        <MyTimeline items={timeLineData} />
      </Modal>
    </div>
  );
}

export default App;
