import React from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExitsSearchFormSchema } from './AddBookingForm/schema';
import Input from '../MyComponents/Input';
import { FormGroup } from './AddBookingForm/styles';
import { Row, Col, Button, Spin } from 'antd';
import { useAppSelector } from '@app/hooks/reduxHooks';
import DatePickersEditRecord from '@app/components/MyComponents/DatePickerEditRecord';
import moment from 'moment';
//

const ExitsSearchForm = ({ submit }: any) => {
  const defaultValues = {};
  const loader = useAppSelector((state) => state.booking.filterBookingLoader);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(ExitsSearchFormSchema),
    // defaultValues,
  });

  const OnSubmit = (x: any) => {
    const d = {};
    if (x.email) {
      Object.assign(d, { email: x.email });
    }
    if (x.licensePlate) {
      Object.assign(d, { licensePlate: x.licensePlate });
    }
    if (x.name) {
      Object.assign(d, { name: x.name });
    }
    if (x.phone) {
      Object.assign(d, { phone: x.phone });
    }
    submit(d);
  };

  const handlePhoneChange = (val: any) => {
    if (val.target.value.length < 13) {
      if (val && val.nativeEvent?.data && (val.target.value.length == 3 || val.target.value.length == 7)) {
        setValue('phone', val.target.value + '-');
      } else {
        setValue('phone', val.target.value);
      }
    }
  };

  return (
    <>
      <FormGroup style={{ maxWidth: '100%' }} autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
        <Row align="middle">
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('email')}
                  type="text"
                  name="email"
                  placeholder="Enter email"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={errors.email && errors.email.message}
                />
              )}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="licensePlate"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('licensePlate')}
                  type="text"
                  name="License Plate"
                  placeholder="Enter license plate"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={errors.licensePlate && errors.licensePlate.message}
                />
              )}
            />
          </Col>
          <Col xs={24} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('name')}
                  type="text"
                  name="Name"
                  placeholder="Enter name"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={errors.name && errors.name.message}
                />
              )}
            />
          </Col>
          <Col span={24} sm={12} md={6} lg={6} xl={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('phone')}
                  type="tel"
                  name="phone"
                  placeholder="Enter Phone"
                  value={field.value}
                  onChange={(value: any) => {
                    // field.onChange(value)
                    handlePhoneChange(value);
                  }}
                  error={errors.phone && errors.phone.message}
                />
              )}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ display: 'flex', justifyContent: 'end' }}>
            <Button type="primary" htmlType="submit" disabled={loader}>
              Search {loader && <Spin />}
            </Button>
          </Col>
        </Row>
      </FormGroup>

      {errors && errors[''] && errors['']['message'] && <p style={{ color: 'red' }}>{errors['']['message']}</p>}
    </>
  );
};

export default withTranslation()(ExitsSearchForm);
