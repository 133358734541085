import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doResetPassword } from '@app/store/slices/managerSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Button, Col, Row, Spin } from 'antd';
import { ButtonContainer, FormGroup } from '@app/components/forms/AddBookingForm/styles';
import { Controller, useForm } from 'react-hook-form';
import Input from '@app/components/MyComponents/PasswordInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { ResetPasswordSchema } from '@app/components/forms/resetPasswordSchema';
import * as Common from '@app/components/layouts/Common/CommonLayout.styles';

type comProps = {
  submitted: () => void;
  record: any;
};

export const ResetPasswordForm: React.FC<comProps> = (props) => {
  const { submitted, record } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const submit = (values: any) => {
    setLoading(true);
    const newValues = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    };
    dispatch(doResetPassword(newValues))
      .unwrap()
      .then((res: any) => {
        setValue('currentPassword', '');
        setValue('newPassword', '');
        setValue('confirmPassword', '');
        notificationController.success({ message: res.message });
        submitted();
        setLoading(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Row justify="center" align="middle">
      <Common.FormTitle>
        {record?.firstName} {record?.lastName}
      </Common.FormTitle>
      <FormGroup autoComplete="off" onSubmit={handleSubmit(submit)}>
        <>
          <Col span={24}>
            <Controller
              name="currentPassword"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('currentPassword')}
                  type="password"
                  name="Current Password"
                  placeholder="Enter Current Password"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={errors.currentPassword && errors.currentPassword.message}
                />
              )}
            />
          </Col>
          <Col span={24}>
            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('newPassword')}
                  type="password"
                  name="New Password"
                  placeholder="Enter New Password"
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  error={errors.newPassword && errors.newPassword.message}
                />
              )}
            />
          </Col>
          <Col span={24}>
            <Controller
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('confirmPassword')}
                  type="password"
                  name="Confirm Password"
                  placeholder="Enter Confirm Password"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={errors.confirmPassword && errors.confirmPassword.message}
                />
              )}
            />
          </Col>
        </>

        <ButtonContainer>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            Update {isLoading && <Spin />}
          </Button>
        </ButtonContainer>
      </FormGroup>
    </Row>
  );
};
