import { withTranslation } from 'react-i18next';
import { Container, InputError } from './styles';
import { Input } from 'antd';
import { Label } from '../TextArea/styles';
import { InputProps } from '../types';

const Input1 = ({
  name,
  placeholder,
  onChange,
  type,
  type2,
  error,
  t,
  value,
  phone1,
  hideLabel,
  onKeyDown,
  suffix,
}: InputProps) => (
  <Container style={{ width: '100%' }}>
    {!hideLabel ? <Label htmlFor={name}>{t(name)}</Label> : ''}
    <Input
      placeholder={t(placeholder)}
      name={name}
      type={type}
      value={value}
      onKeyDown={onKeyDown}
      // id={name}
      // onBlur={onBlur}
      onChange={onChange}
      suffix={suffix}
      // style={{fontSize: type2  && type2 === "placeText" ? "12px" : "14px"}}
    />
    {phone1 ? <p style={{ fontSize: '0.8rem', marginTop: '1px' }}>Required Pattern 111-111-1111</p> : null}
    <InputError>{error}</InputError>
  </Container>
);

export default withTranslation()(Input1);
