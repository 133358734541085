import { combineReducers } from 'redux';
import userReducer from '@app/store/slices/userSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import shipsReducer from '@app/store/slices/shipSlice';
import managersReducer from '@app/store/slices/managerSlice';
import bookingsReducer from '@app/store/slices/bookingSlice';
import dashboardReducer from '@app/store/slices/dashboardSlice';
import billingReducer from '@app/store/slices/billingSlice';
import invoiceReducer from '@app/store/slices/invoicesSlice';
import refundRequestReducer from '@app/store/slices/refundRequestSlice';

export const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  ship: shipsReducer,
  manager: managersReducer,
  booking: bookingsReducer,
  dashboard: dashboardReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  billing: billingReducer,
  invoices: invoiceReducer,
  refundRequests: refundRequestReducer,
});
