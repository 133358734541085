import React from 'react';

const ShipComponent = ({ color, width, height }: any) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    id="Layer_2_00000061446136755273763470000005102449357854115760_"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 213.7 121.8"
    style={{ fill: color }}
    xmlSpace="preserve"
  >
    <g id="Layer_1-2">
      <g>
        <g>
          <path
            className="st0"
            d="M199.9,37.1l-48.5,11.2C105.6,58.9,60,69.5,14.3,80.1c-1.8,0.4-3.6,0.8-5.4,1.2C3.7,82.4,0,86.9,0,92.2v18.3
            c0,6.2,5,11.2,11.2,11.2l138.8,0.1h8.1c2.3,0,4.4-1.4,5.3-3.3c0-0.1,0.1-0.2,0.1-0.3c3.6-8,9.6-14.1,16.5-19.4
            c6-4.7,12.1-9.2,18.2-13.8c5-3.7,8.1-8.8,10.4-14.4c2.8-6.8,4.2-14,5-21.3c0,0,0-0.1,0-0.2C214.4,41.5,207.4,35.4,199.9,37.1
            L199.9,37.1z"
          />
          <path
            className="st0"
            d="M163.9,39.4c-7.7-4.3-15.4-8.5-23.2-12.7c-0.5-0.3-1.3-0.3-1.9-0.1c-17.7,4-48.4,10.8-66.1,14.9
            c-10.7,2.4-38.7,9-49.3,11.3c-1.4,0.3-2,0.9-2.1,2.3c-0.1,1.5-0.5,2.9-0.8,4.4c-0.9,4.8-1.8,9.5-2.8,14.5c39-9,108.3-25,147.3-34
            C164.9,40,164.1,39.5,163.9,39.4L163.9,39.4z"
          />
          <path
            className="st0"
            d="M132,21.8c-11.9-6.5-24.8-9.6-38.2-6.9c-20.3,4.1-40.4,9-60.6,13.6c-3.9,0.9-6.7,3.6-7.9,7.4
                    c-1.1,3.6-1.6,7.3-2.5,11.3C59.7,38.9,96,30.6,132.4,22.3C132.1,22,132.1,21.8,132,21.8L132,21.8z"
          />
          <path
            className="st0"
            d="M42.6,21.5c0.2,0.8,1,1.2,1.7,1c5.1-1.2,10.2-2.3,15.3-3.5c0.8-0.2,1.3-1,1.1-1.8c-1.2-4.6-2.5-9.1-3.7-13.7
                    c-0.6-2.4-3-3.9-5.4-3.5L43,1.4c-2.8,0.4-4.6,3.2-3.9,5.9C40.3,12,41.4,16.7,42.6,21.5L42.6,21.5z"
          />
          <path
            className="st0"
            d="M77.2,13.6c0.2,0.7,1,1.2,1.7,1c2.6-0.6,5.1-1.2,7.6-1.8c0.8-0.2,1.3-1,1-1.8c-0.6-2.1-1.2-4-1.8-6
                    c-0.2-0.5-0.9-1.2-1.3-1.2c-2.3-0.1-4.6-0.1-6.9-0.1c-1.6,0-2.7,1.5-2.3,3C75.8,9,76.5,11.3,77.2,13.6L77.2,13.6z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ShipComponent;
