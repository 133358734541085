import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeStatus, getInvoices } from '@app/api/invoices.api';

export interface InvoicesSlice {
  invoicesData: [] | null;
  billingDataActive: [] | null;
}

const initialState: InvoicesSlice = {
  invoicesData: [],
  billingDataActive: [],
};

export const doGetInvoices = createAsyncThunk('getInvoices', async (data: any) => getInvoices(data).then((res) => res));

export const updateInvoicesStatus = createAsyncThunk('changeInvoicesStatus', async (changeStatusPayload: any) =>
  changeStatus(changeStatusPayload).then((res) => res),
);

const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetInvoices.fulfilled, (state, action) => {
      state.invoicesData = action.payload.data;
    });
    builder.addCase(updateInvoicesStatus.fulfilled, (state, action) => {
      let Index: any = 0;
      Index = state.invoicesData?.findIndex((bill: any) => bill?._id === action.payload.data?._id);
      const x: any = state.invoicesData;
      x[Index] = action.payload.data;
      state.invoicesData = x;
    });
  },
});

export default invoiceSlice.reducer;
