import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeRate, getBilling, getBillingActive, addNewBilling, dltBilling } from '@app/api/billing.api';
import { changeStatus } from '@app/api/billing.api';

export interface BillingSlice {
  billingData: [] | null;
  billingDataActive: [] | null;
  addNewBillingLoader: boolean;
  dltBillingLoader: boolean;
}

const initialState: BillingSlice = {
  billingData: [],
  billingDataActive: [],
  addNewBillingLoader: false,
  dltBillingLoader: false,
};

export const doGetBilling = createAsyncThunk('getBilling', async () => getBilling().then((res) => res));
export const doGetBillingActive = createAsyncThunk('getBillingActive', async () =>
  getBillingActive().then((res) => res),
);

export const updateBillingStatus = createAsyncThunk('changeBillingStatus', async (changeStatusPayload: any) =>
  changeStatus(changeStatusPayload).then((res) => res),
);
export const updateBillingRate = createAsyncThunk('UpdateBillingRate', async (changeStatusPayload: any) =>
  changeRate(changeStatusPayload).then((res) => res),
);

export const addNewBillingRate = createAsyncThunk('addBillingRate', async (payload: any) =>
  addNewBilling(payload).then((res) => res),
);

export const dltBillingRate = createAsyncThunk('dltBillingRate', async (payload: any) =>
  dltBilling(payload).then((res) => res),
);

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetBilling.fulfilled, (state, action) => {
      state.billingData = action.payload.data;
    });
    builder.addCase(doGetBillingActive.fulfilled, (state, action) => {
      state.billingDataActive = action.payload.data;
    });
    builder.addCase(updateBillingStatus.fulfilled, (state, action) => {
      let Index: any = 0;
      Index = state.billingData?.findIndex((bill: any) => bill?._id === action.payload.data?._id);
      const x: any = state.billingData;
      x[Index] = action.payload.data;
      state.billingData = x;
    });
    builder.addCase(updateBillingRate.fulfilled, (state, action) => {
      let Index: any = 0;
      Index = state.billingData?.findIndex((bill: any) => bill?._id === action.payload.data?._id);
      const x: any = state.billingData;
      x[Index] = action.payload.data;
      state.billingData = x;
    });
    builder.addCase(addNewBillingRate.pending, (state, action) => {
      state.addNewBillingLoader = true;
    });
    builder.addCase(addNewBillingRate.fulfilled, (state, action) => {
      state.addNewBillingLoader = false;
    });
    builder.addCase(addNewBillingRate.rejected, (state, action) => {
      state.addNewBillingLoader = false;
    });

    builder.addCase(dltBillingRate.pending, (state, action) => {
      state.dltBillingLoader = true;
    });
    builder.addCase(dltBillingRate.fulfilled, (state, action) => {
      state.dltBillingLoader = false;
    });
    builder.addCase(dltBillingRate.rejected, (state, action) => {
      state.dltBillingLoader = false;
    });
  },
});

export default billingSlice.reducer;
