import { Row, Col, Button, Spin, Tooltip } from 'antd';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '../MyComponents/Input';
import { FormGroup, ButtonContainer } from './AddBookingForm/styles';

import { notificationController } from '@app/controllers/notificationController';
import { CopyOutlined, SyncOutlined } from '@ant-design/icons';
import { ManagerSchema } from '@app/components/forms/schemaAddManager';
import { Label } from '@app/components/MyComponents/TextArea/styles';
import PasswordManagerInput from '@app/components/MyComponents/PasswordManagerInput';
import { InputError } from '@app/components/MyComponents/PasswordManagerInput/styles';
import { doAddManager } from '@app/store/slices/managerSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';

interface managerForm {
  submitted: any;
}

const Contact = ({ submitted }: managerForm) => {
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [emailValue, setEmailValue] = useState('');

  const [mailFlag, setMailFlag] = useState<any>(false);

  const validateEmail = (email: any) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const Link =
    process.env.REACT_APP_ENVIROMENTS === 'production'
      ? 'https://api.parkgenix.com/api/'
      : 'https://uat.parkgenix.com/api/';
  const handleEmailBlur = (x: any) => {
    const v = validateEmail(x);
    if (v) {
      setMailFlag(true);
      axios
        .post(Link + 'auth/validateEmailAddress', { email: x })
        .then(({ data }) => {
          if (data.status && data.data.isDeliverable) {
            setInvalidEmail(false);
          } else {
            setInvalidEmail(true);
            setError('email', { type: 'string', message: 'Not deliverable email.' });
          }
        })
        .catch((err) => {
          setInvalidEmail(true);
          setError('email', { type: 'string', message: 'Not deliverable email.' });
        })
        .finally(() => setMailFlag(false));
    }
  };

  React.useEffect(() => {
    const getData = setTimeout(() => {
      handleEmailBlur(emailValue);
    }, 1000);

    return () => clearTimeout(getData);
  }, [emailValue]);

  const handleChangeEmail = (val: any) => {
    setEmailValue(val.target.value);
    // console.log(val);
    // console.log(val.target.value);
  };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ManagerSchema),
  });
  //

  const OnSubmit = (x: any) => {
    if (invalidEmail) {
      setError('email', { type: 'string', message: 'Not deliverable email.' });
    } else {
      setLoader(true);
      dispatch(doAddManager(x))
        .unwrap()
        .then((res: any) => {
          setValue('firstName', '');
          setValue('lastName', '');
          setValue('email', '');
          setValue('password', '');
          notificationController.success({ message: res.message });
          submitted();
          setLoader(false);
        })
        .catch((err) => {
          setValue('firstName', '');
          setValue('lastName', '');
          setValue('email', '');
          setValue('password', '');
          notificationController.error({ message: err.message });
          setLoader(false);
        });
    }
  };

  const chars = 'abcdefghijklmnopqrstuvwxyz';
  const chars1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const chars2 = '0123456789';
  const chars3 = '@#$%^*!?';
  const charsLength = 3;
  const chars1Length = 3;
  const chars2Length = 2;
  const chars3Length = 2;

  const generatePassword = () => {
    let a = '';
    let b = '';
    let c = '';
    let d = '';
    for (let i = 0; i <= charsLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      a += chars.substring(randomNumber, randomNumber + 1);
    }
    for (let i = 0; i <= chars1Length; i++) {
      const randomNumber = Math.floor(Math.random() * chars1.length);
      b += chars1.substring(randomNumber, randomNumber + 1);
    }
    for (let i = 0; i <= chars2Length; i++) {
      const randomNumber = Math.floor(Math.random() * chars2.length);
      c += chars2.substring(randomNumber, randomNumber + 1);
    }
    for (let i = 0; i <= chars3Length; i++) {
      const randomNumber = Math.floor(Math.random() * chars3.length);
      d += chars3.substring(randomNumber, randomNumber + 1);
    }
    setValue('password', a + b + c + d);
  };

  const coppied = () => {
    const regex = '^\\s+$';
    if (!getValues('password') || (getValues('password') && getValues('password').match(regex))) {
      notificationController.warning({ message: 'Password field is empty' });
    } else {
      navigator.clipboard.writeText(getValues('password'));
      notificationController.success({ message: 'Password copied' });
    }
  };

  return (
    <Row justify="center" align="middle">
      <div style={{ position: 'relative' }}>
        <FormGroup autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
          <>
            <Col span={24}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <Input
                    {...register('firstName')}
                    type="text"
                    name="First Name"
                    placeholder="Enter First Name"
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={errors.firstName && errors.firstName.message}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <Input
                    {...register('lastName')}
                    type="text"
                    name="Last Name"
                    placeholder="Enter Last Name"
                    value={field.value}
                    onChange={(value) => field.onChange(value)}
                    error={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...register('email')}
                    type="email"
                    name="email"
                    // onBlur={console.log('')}
                    placeholder="Enter Email"
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                      handleChangeEmail(value);
                    }}
                    error={errors.email && errors.email.message}
                  />
                )}
              />
            </Col>
            <Col span={24}>
              <>
                <Label htmlFor={'password'}>Password</Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <PasswordManagerInput
                        {...register('password')}
                        type="text"
                        name="password"
                        placeholder="Enter password"
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        error={errors.password && errors.password.message}
                      />
                      <Tooltip placement="top" title={'Generate password'}>
                        <Button
                          onClick={() => generatePassword()}
                          style={{ marginRight: '2px', marginLeft: '2px' }}
                          icon={<SyncOutlined />}
                        />
                      </Tooltip>
                      <Tooltip placement="top" title={'Copy password'}>
                        <Button onClick={() => coppied()} icon={<CopyOutlined />} />
                      </Tooltip>
                    </div>
                  )}
                />
                <InputError>{errors.password && errors.password.message}</InputError>
              </>
            </Col>
          </>

          <ButtonContainer>
            <Button type="primary" htmlType="submit" disabled={loader || mailFlag}>
              Submit {(mailFlag || loader) && <Spin />}
            </Button>
          </ButtonContainer>
        </FormGroup>
      </div>
    </Row>
  );
};

export default withTranslation()(Contact);
