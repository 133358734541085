import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import * as Common from '@app/components/layouts/Common/CommonLayout.styles';
import { Button, Checkbox, Col, Descriptions, Row, Spin, Typography } from 'antd';
import { ButtonContainer, FormGroup } from '@app/components/forms/AddBookingForm/styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditSailingDateSchema } from '@app/components/forms/EditRecordSchema';
import { CruiseSelect } from '@app/components/forms/AddBookingForm/cruiseSelect';
import moment, { Moment } from 'moment';
import DatePicker from '../../MyComponents/DatePicker';
import { doUpdateRecord, doUpdateSailingDate } from '@app/store/slices/bookingSlice';
import { httpApi } from '@app/api/http.api';
import axios from 'axios';
import _ from 'lodash';

type comProps = {
  submitted: () => void;
  record: any;
};

export const EditSailingDateFrom: React.FC<comProps> = (props) => {
  const { submitted, record } = props;

  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [dates, setDates] = useState<any>([]);
  const [ship, setShips] = useState<any>([]);
  const [shipsLoader, setShipsLoader] = useState(false);
  const [availableSlots, setAvailableSlots] = useState(0);
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);

  const [checkOutDate, setCheckOutDate] = useState<any>(null);
  const [paymentView, setPaymentView] = useState(false);
  const [data, setData] = useState<any>({});

  console.log('data', data);
  const [amount, setAmount] = useState<any>('');
  const [ltp, setLtp] = React.useState<any>(null);
  const [ci, setCi] = React.useState<any>(null);
  const [newTax, setNewTax] = React.useState<any>([]);
  const [newAmountDetail, setNewAmountDetail] = React.useState<any>({});

  const [clientPayment, setClientPayment] = React.useState<any>(false);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    minimumSignificantDigits: 20,
    maximumSignificantDigits: 21,
  });

  const newFunc = (val: any) => {
    val = formatter.format(val);

    if (val.includes('.')) {
      const number = val.toString().split('.')[0] + '.' + val.split('.')[1].slice(0, 2);
      return number;
    } else {
      return val;
    }
  };

  const Link =
    process.env.REACT_APP_ENVIROMENTS === 'production'
      ? 'https://api.parkgenix.com/api/'
      : 'https://uat.parkgenix.com/api/';

  const defaultValues = record
    ? {
        ship: {
          label: record.ship ? record.ship.charAt(0).toUpperCase() + record.ship.slice(1) : '',
          value: record.ship ? record.ship : '',
        },
        checkIn: record.checkIn ? moment(record.checkIn.split('T')[0]).local() : null,
      }
    : {};

  const handleShip = (x: any) => {
    const dates = ship.find((w: any) => w.name == x.label);
    setPaymentView(false);
    setDates(dates.sailing_dates);
  };

  useEffect(() => {
    if (!record) return;
    if (!record.ship) return;
    if (!ship) return;
    const dates = ship.find((w: any) => w.name == record.ship);
    if (!dates) return;
    setDates(dates.sailing_dates);
  }, [record, ship]);

  const handleBilling = () => {
    httpApi
      .get('reservation/get/all/insurance/rate')
      .then((res) => {
        setNewTax(res.data.data);
        // setLtp(_.find(res.data.data, (i) => i.type == 'LTP'));
        // const ciFind = _.find(res.data.data, (i) => i.type == 'CI');
        // if (!_.isEmpty(ciFind)) {
        //   console.log(ciFind);
        //   setCi(ciFind);
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const apiCall = () => {
    setShipsLoader(true);
    httpApi
      .get(Link + 'reservation/get/ship/active')
      .then((res) => {
        setShips(res.data.data);
        setShipsLoader(false);
      })
      .catch(() => {
        setShipsLoader(false);
        console.log('error');
      });
  };
  React.useEffect(() => {
    apiCall();
    handleBilling();
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(EditSailingDateSchema),
    defaultValues,
  });

  const { t } = useTranslation();

  const checkSpace = (value: Moment) => {
    setLoader(true);
    axios
      .post(Link + 'reservation/check/available/space', {
        checkIn: moment(value).local().format('YYYY-MM-DD'),
      })
      .then((e) => {
        if (e && e.data) {
          setAvailableSlots(e.data.data);
          setVisible(true);
          setError('checkIn', {
            type: 'string',
            message: '',
          });
        }
        // setCurrent(current + 1);
      })
      .catch((err) => {
        setError('checkIn', {
          type: 'string',
          message:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'Error completing your request. Please try again in a few minutes.',
        });
      })
      .finally(() => setLoader(false));
  };

  const getOffers = (Offers: any, duration: any) => {
    let dur = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Offers) {
      Offers.map((item: any) => {
        const a = moment(item.validDate, 'YYYY-MM-DD').local();
        const b = moment(new Date(), 'YYYY-MM-DD').local();

        if (item.days === duration && item.status && a.diff(b, 'days') >= 0) {
          dur = item;
        }
      });

      return dur;
    } else {
      return dur;
    }
  };
  const getNumberOfDays = (s: any, cIn: any) => {
    let duration = 0;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const wk = s.sort((a, b) => new Date(a) - new Date(b));
    const w = wk.indexOf(moment(cIn).local().format('YYYY-MM-DD'));
    let a = moment(wk[w]).local();
    const c = moment(wk[w]).local().format('YYYY-MM-DD');
    let b = moment(wk[w + 1]).local();
    if (!wk[w + 1]) {
      a = moment(wk[w - 1]).local();
      b = moment(wk[w]).local();
    }
    //
    duration = b.diff(a, 'days');
    const checkout = moment(c, 'YYYY-MM-DD').local().add(6, 'days');
    setCheckOutDate(moment(checkout).local().format('YYYY-MM-DD'));
    return duration;
  };
  const UpdateSailingDate = () => {
    Object.assign(newAmountDetail, { Total: data.total });
    const d = {
      id: record._id,
      newShip: data.ship,
      tranRequired: clientPayment,
      newSailingDate: moment(data.checkIn).local().format('YYYY-MM-DD'),
      newAmountDetail: newAmountDetail,
      newAmount: data.total * 100,
    };

    setLoading(true);
    dispatch(doUpdateSailingDate(d))
      .unwrap()
      .then((res) => {
        notificationController.success({ message: res.message });
        submitted();
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  };

  const OnSubmit = async (x: any) => {
    if (!paymentView) {
      const s = x.ship.label;
      const finalData = {
        ship: s,
      };

      const dur = ship.find((i: any) => i.name === s);

      const duration = await getNumberOfDays(dur.sailing_dates, x.checkIn);
      const ratesOffers: any = await getOffers(dur.rateSchedule ? dur.rateSchedule : false, duration);

      let total: any = 0;

      const amountDetail: any = {};

      Object.assign(amountDetail, {
        Subtotal: (ratesOffers ? ratesOffers.rate : duration * dur.rate).toString(),
      });
      total = total + Number(amountDetail.Subtotal);
      let newTotal =
        Number(record.amountDetail['CI']) > 0 ? Number(total) + Number(record.amountDetail['CI']) : Number(total);
      amountDetail['Tax'] = Number(newTotal * 0.0825).toFixed(2);
      newTotal = Number(total) + Number(amountDetail['Tax']);
      total = newTotal;

      for await (const x of Object.keys(record.amountDetail)) {
        const tax = newTax.find((i: any) => i.type == x);
        if (tax) {
          if (tax.isPerDay) {
            amountDetail[x] = Number(tax.rate) * duration;

            total = Number(total) + Number(amountDetail[x]);
            // total = newTotal
          } else {
            amountDetail[x] = record.amountDetail[x];
            // if(x !== "CI"){
            total = Number(total) + Number(amountDetail[x]);
            // }
          }
        } else {
          continue;
        }
      }

      setNewAmountDetail(amountDetail);
      const w = { ...x, ...finalData, ...amountDetail, total };
      setAmount(total);
      setData(w);
      setPaymentView(true);
    } else {
      UpdateSailingDate();
    }
  };

  const handleCheckPayment = (e: any) => {
    if (e) {
      setClientPayment(true);

      return;
    } else {
      setClientPayment(false);
    }
  };

  const Difference = () => {
    const x = Number(Number(data.total).toFixed(2)) - Number(Number(record.amountDetail.Total).toFixed(2));
    return x;
  };

  // const handleValue = (x:any) => {
  //   if (x){
  //     let d = x.toFixed(2)
  //     return `$${d}`
  //   }
  // }
  const ConvertDate = (date: any) => {
    const newD = date.split('T')[0];
    return newD;
  };

  return (
    <Row justify="center" align="middle">
      <div style={{ position: 'relative', width: '90%' }}>
        <FormGroup autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
          <Common.FormTitle>License: {record?.licensePlate.toUpperCase()}</Common.FormTitle>
          <div>
            {' '}
            {visible && (
              <Typography style={{ fontWeight: 'bold', float: 'right' }}>Available Slots: {availableSlots}</Typography>
            )}
          </div>
          <Col span={24}>
            <Controller
              name="ship"
              control={control}
              render={({ field }) => (
                <CruiseSelect
                  {...register('ship')}
                  label={'Cruise'}
                  onChange={(x: any) => {
                    field.onChange(x);
                    handleShip(x);
                    setValue('checkIn', null);
                  }}
                  value={field.value}
                  options={ship.map((i: any) => ({ label: i.name, value: i.name }))}
                  error={errors.ship && errors.ship.message}
                />
              )}
            />
          </Col>
          <Col span={24}>
            <Controller
              name="checkIn"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...register('checkIn')}
                  name="Check In Date"
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    console.log(value);
                    if (value) {
                      checkSpace(value);
                      setPaymentView(false);
                    }
                  }}
                  dates={dates}
                  error={errors.checkIn && errors.checkIn.message}
                />
              )}
            />
          </Col>

          {paymentView && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography style={{ fontWeight: 'bold' }}>Previous Rates</Typography>
              <div style={{ display: 'flex' }}>
                <Typography>Sailing Date:</Typography>
                <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                  {moment(ConvertDate(record.checkIn)).format('MM/DD/YYYY')}
                  {/* {moment(record.checkIn).format('MM/DD/YYYY')} */}
                </Typography>
              </div>
              {record &&
                record.amountDetail &&
                Object.entries(record.amountDetail).map(([key, value]) => (
                  <div style={{ display: 'flex' }} key={key}>
                    <Typography>{key}:</Typography>
                    <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                      {/* ${value && value.toFixed(2)} */}
                      {Number(value).toFixed(2)}
                    </Typography>
                  </div>
                ))}
            </div>
          )}

          {paymentView && (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
              <Typography style={{ fontWeight: 'bold' }}>New Rates</Typography>
              <div style={{ display: 'flex' }}>
                <Typography>New Sailing Date:</Typography>
                <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                  {moment(data.checkIn).local().format('MM/DD/YYYY')}
                </Typography>
              </div>
              {data &&
                Object.entries(data).map(
                  ([key, value]) =>
                    key !== 'checkIn' && (
                      <div style={{ display: 'flex' }} key={key}>
                        <Typography
                          style={{ textTransform: key === 'ship' || key === 'total' ? 'capitalize' : 'none' }}
                        >
                          {key}:
                        </Typography>
                        <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                          {key !== 'ship' ? '$' + Number(value).toFixed(2) : value}
                        </Typography>
                      </div>
                    ),
                )}
            </div>
          )}
          {paymentView && (
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
              <Typography style={{ fontWeight: 'bold' }}>Difference</Typography>

              <div style={{ display: 'flex' }}>
                <Typography>Old Amount:</Typography>
                <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                  {' '}
                  ${Number(record?.amountDetail.Total).toFixed(2)}
                </Typography>
              </div>
              <div style={{ display: 'flex' }}>
                <Typography>New Amount:</Typography>
                <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }}>
                  {' '}
                  ${Number(data?.total).toFixed(2)}
                </Typography>
              </div>
              <div style={{ display: 'flex' }}>
                <Typography>Difference:</Typography>
                <Typography style={{ fontWeight: 'bold', marginLeft: '10px' }}> ${Difference().toFixed(2)}</Typography>
              </div>
            </div>
          )}
          {paymentView && (Difference() > 0 || Difference() < 0) && (
            <Checkbox
              style={{ marginLeft: '0px', marginTop: '15px' }}
              onChange={(e) => handleCheckPayment(e.target.checked)}
            >
              <Typography.Paragraph>{`Apply financial transaction to this change`}</Typography.Paragraph>
            </Checkbox>
          )}

          <ButtonContainer>
            <Button type="primary" htmlType="submit" disabled={!isDirty || isLoading || loader}>
              {paymentView ? 'Update' : 'Continue'} {isLoading && <Spin />}
            </Button>
          </ButtonContainer>
        </FormGroup>
      </div>
    </Row>
  );
};
