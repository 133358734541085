import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';
import { Button, Typography } from 'antd';
interface overLay {
  setModal: () => void;
}
export const ProfileOverlay = (props: any) => {
  const { setModal } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Title } = Typography;
  return (
    <DropdownMenu selectable={false}>
      {/*<S.MenuItem key={0}>*/}
      {/*  <S.Text>*/}
      {/*    <Link to="/profile">{t('profile.title')}</Link>*/}
      {/*  </S.Text>*/}
      {/*</S.MenuItem>*/}
      {/*<S.ItemsDivider />*/}

      <Title level={5} style={{ marginBottom: '0px' }}>
        <Button
          style={{ border: 'none', paddingBottom: '0px', color: 'var(--primary-color)' }}
          onClick={() => setModal()}
        >
          Reset Password
        </Button>
      </Title>
      <Title level={5} style={{ marginTop: '0px' }}>
        <Button
          style={{ border: 'none', paddingTop: '0px', color: 'var(--primary-color)' }}
          onClick={() => navigate('/logout')}
        >
          Logout
        </Button>
      </Title>
    </DropdownMenu>
  );
};
