import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row, Space, Table, Switch, Card, Typography, Button, Spin } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import { Table as NewTable } from '@app/components/common/Table/Table';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import AddManagerForm from '@app/components/forms/addManagerForm';
import { ChangeManagerPasswordForm } from '@app/components/forms/ChangeManagerPasswordForm/ChangeManagerForm';
import _ from 'lodash';
import { doGetManagers, doUpdateStatus } from '@app/store/slices/managerSlice';
import { UserAddOutlined } from '@ant-design/icons';

const { Column } = Table;

const ManagersPage: React.FC = () => {
  interface DataType {
    key: string;
    email: number;
    firstName: string;
    lastName: string;
    status: boolean;
    type: string;
    createdAt: string;
    updatedAt: string;
    _id: string;
  }

  const { isDesktop } = useResponsive();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [cRowOperation, setCRowOperation] = useState<string | null>(null);
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<any>(null);
  const [passwordModal, setPasswordModal] = useState(false);
  const [filters, setFilters] = useState([]);
  const managers = useAppSelector((state) => state.manager);
  const User = useAppSelector((state) => state.auth);
  const managersData: any = managers.managersData;
  const UserData: any = User.UserData;
  const { isTablet } = useResponsive();
  const { Title } = Typography;

  useEffect(() => {
    if (_.isEmpty(managersData)) {
      setFilters([]);
      return;
    } else {
      const x: any = [];
      managersData.map((item: any) =>
        x.push({
          text: item.email,
          value: item.email,
        }),
      );

      setFilters(x);
    }
  }, [managersData]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showPasswordModalOpen = (x: any) => {
    setData(x);
    setPasswordModal(true);
  };

  const showPasswordModalClose = () => {
    setPasswordModal(false);
  };

  const getManagers = () => {
    setLoading(true);
    dispatch(doGetManagers())
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getManagers();
  }, []);

  const handleChangeStatus = (checked: boolean, x: DataType) => {
    setCRowOperation(x._id);
    dispatch(doUpdateStatus({ id: x._id, status: checked }))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setCRowOperation(null));
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmitted = () => {
    getManagers();
    setIsModalOpen(false);
  };

  const handlePasswordSubmitted = () => {
    getManagers();
    setPasswordModal(false);
  };

  const desktopLayout = (
    <Card
      title={
        <div
          style={
            isTablet
              ? { display: 'flex', justifyContent: 'space-between' }
              : { display: 'flex', flexDirection: 'column' }
          }
        >
          <Title level={5} style={{ fontWeight: 'bold' }}>
            Managers: {loading ? <Spin /> : managersData && managersData.length}
          </Title>
          {UserData && UserData.type !== 'manager' && (
            <div>
              <Button size={isTablet ? 'large' : 'small'} icon={<UserAddOutlined />} onClick={showModal}>
                Add Manager
              </Button>
            </div>
          )}
        </div>
      }
    >
      <Row gutter={[30, 30]}>
        <Col span={24}>
          <NewTable dataSource={managersData} pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}>
            <Column
              title="Name"
              responsive={['sm']}
              filters={filters}
              filterSearch={true}
              onFilter={(value: any, record: any) => record.email.includes(value)}
              render={(record: any) => (
                <React.Fragment>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {record && record.firstName && `${record.firstName}`}{' '}
                    {record && record.lastName && ` ${record.lastName}`}
                  </Typography>
                  {record && record.email && ` ${record.email}`}
                </React.Fragment>
              )}
            />
            <Column
              title="Created / Updated"
              responsive={['sm']}
              sorter={(a: any, b: any) => (a.updatedAt < b.updatedAt ? 1 : -1)}
              render={(record: any) => (
                <React.Fragment>
                  {record && record.createdAt && (
                    <div style={{ display: 'flex' }}>
                      <Typography style={{ fontWeight: 'bold' }}>Created At:</Typography>
                      <Typography style={{ marginLeft: '3px' }}>
                        {moment.utc(record.createdAt).local().format('MM/DD/YYYY hh:mm')}
                      </Typography>
                    </div>
                  )}
                  {record && record.updatedAt && (
                    <div style={{ display: 'flex' }}>
                      <Typography style={{ fontWeight: 'bold' }}>Updated At:</Typography>
                      <Typography style={{ marginLeft: '3px' }}>
                        {moment.utc(record.updatedAt).local().format('MM/DD/YYYY hh:mm')}
                      </Typography>
                    </div>
                  )}
                </React.Fragment>
              )}
            />
            <Column
              title="Name / Email"
              responsive={['xs']}
              sorter={(a: any, b: any) => (a.updatedAt < b.updatedAt ? 1 : -1)}
              render={(record: any) => (
                <React.Fragment>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {record && record.firstName && `${record.firstName}`}{' '}
                    {record && record.lastName && ` ${record.lastName}`}
                  </Typography>

                  <br />
                  {record && record.email && `${record.email}`}
                  <br />
                  {record && record.createdAt && (
                    <div style={{ display: 'flex' }}>
                      <Typography style={{ fontWeight: 'bold' }}>Created At:</Typography>
                      <Typography style={{ marginLeft: '3px' }}>
                        {moment.utc(record.createdAt).local().format('MM/DD/YYYY hh:mm')}
                      </Typography>
                    </div>
                  )}
                  {record && record.updatedAt && (
                    <div style={{ display: 'flex' }}>
                      <Typography style={{ fontWeight: 'bold' }}>Updated At:</Typography>
                      <Typography style={{ marginLeft: '3px' }}>
                        {moment.utc(record.updatedAt).local().format('MM/DD/YYYY hh:mm')}
                      </Typography>
                    </div>
                  )}
                  <Space size="large">
                    <Switch
                      disabled={UserData && UserData.type === 'manager'}
                      loading={cRowOperation === record._id}
                      onChange={(x) => handleChangeStatus(x, record)}
                      defaultChecked={record.status}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />
                  </Space>
                  <br />
                  <Space size="large" style={{ marginTop: '5px' }}>
                    <Button
                      size={'small'}
                      disabled={UserData && UserData.type === 'manager'}
                      loading={cRowOperation === record._id}
                      onClick={() => showPasswordModalOpen(record)}
                    >
                      Update
                    </Button>
                  </Space>
                </React.Fragment>
              )}
            />

            <Column
              title="Status"
              key="action"
              responsive={['sm']}
              render={(_: any, record: DataType) => (
                <Space size="large">
                  <Switch
                    disabled={UserData && UserData.type === 'manager'}
                    loading={cRowOperation === record._id}
                    onChange={(x) => handleChangeStatus(x, record)}
                    defaultChecked={record.status}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                  />
                </Space>
              )}
            />
            <Column
              title="Change Password"
              key="action2"
              responsive={['sm']}
              render={(_: any, record: DataType) => (
                <Space size="large">
                  <Button
                    size={'small'}
                    disabled={UserData && UserData.type === 'manager'}
                    loading={cRowOperation === record._id}
                    onClick={() => showPasswordModalOpen(record)}
                  >
                    Update
                  </Button>
                </Space>
              )}
            />
          </NewTable>
        </Col>
      </Row>
      <Modal
        footer={[]}
        title="Add Manager"
        open={isModalOpen}
        onOk={handleOk}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <AddManagerForm submitted={handleSubmitted} />
      </Modal>
      <Modal
        style={{ zIndex: '999' }}
        footer={[]}
        title="Change Password"
        open={passwordModal}
        destroyOnClose={true}
        maskClosable={false}
        onOk={showPasswordModalClose}
        onCancel={showPasswordModalClose}
      >
        <ChangeManagerPasswordForm record={data} submitted={handlePasswordSubmitted} />
      </Modal>
    </Card>
  );

  return (
    <>
      <PageTitle>Managers</PageTitle>
      {isDesktop ? desktopLayout : desktopLayout}
    </>
  );
};

export default ManagersPage;
