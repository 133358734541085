import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { doGetDashboardData } from '@app/store/slices/dashboardSlice';
import { DashCard } from '@app/pages/DashboardPages/card';
import parked from './parked.svg';
import reserved from './reserved_1.svg';
import parked2 from './parked_2.svg';
import exited from './exited_1.svg';
import manager from './manager_1.svg';
import checkout from './checked out_1.svg';
import activeManager from './active manager_1.svg';
import Cruise from './total cruise.svg';
import activeCruise from './total active cruise.svg';
import { DashCard2 } from '@app/pages/DashboardPages/card2';

const MedicalDashboardPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const dashboardData = useAppSelector((state) => state.dashboard);
  const dashboardsData: any = dashboardData.dashboardData;
  const { isDesktop } = useResponsive();
  const {
    totalActiveCruise,
    totalActiveManagers,
    totalCheckedIn,
    totalCheckedOut,
    totalCruise,
    totalManagers,
    totalReservations,
  } = dashboardsData;

  useEffect(() => {
    setLoading(true);
    dispatch(doGetDashboardData())
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  }, []);

  const desktopLayout = (
    <Row justify="center" style={{ margin: '20px' }}>
      <DashCard2 click={'/ships'} icon={Cruise} title={'Cruise'} count={totalCruise} />
      <DashCard2 click={'/ships'} icon={activeCruise} title={'Active Cruise'} count={totalActiveCruise} />
      <DashCard2 click={'/managers'} icon={manager} title={'Managers'} count={totalManagers} />
      <DashCard2 click={'/managers'} icon={activeManager} title={'Active Managers'} count={totalActiveManagers} />
      <DashCard2 click={'/reserved'} icon={reserved} title={'Reservations'} count={totalReservations} />
      <DashCard2 click={'/parked'} icon={parked2} title={'Parked'} count={totalCheckedIn} />
      <DashCard2 click={'/exited'} icon={checkout} title={'Exits'} count={totalCheckedOut} />
    </Row>
  );

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {isDesktop ? desktopLayout : desktopLayout}
    </>
  );
};

export default MedicalDashboardPage;
