import React, { useState } from 'react';
import { Avatar, Col, Row } from 'antd';
import { Modal } from '@app/components/common/Modal/Modal';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { H6 } from '@app/components/common/typography/H6/H6';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import User from '@app/assets/user.png';
import { ResetPasswordForm } from '@app/components/forms/ResetPasseordForm/ResetPasswordForm';

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();
  const [modalOpen, setModalOpen] = useState(false);
  const user = useAppSelector((state) => state.auth);
  const UserData: any = user.UserData;

  const setModal = () => {
    setModalOpen(!modalOpen);
  };

  return UserData ? (
    <>
      <Dropdown overlay={<ProfileOverlay setModal={setModal} />} trigger={['click']}>
        <S.ProfileDropdownHeader as={Row} align="middle">
          <Col style={{ paddingRight: '0px', paddingLeft: '0px' }}>
            <Avatar src={User} alt="User" shape="circle" size={40} />
          </Col>
          {isTablet && (
            <Col style={{ paddingLeft: '5px' }}>
              <H6 style={{ fontWeight: 'bold' }}>{`${UserData?.firstName} ${UserData?.lastName}`}</H6>
            </Col>
          )}
        </S.ProfileDropdownHeader>
      </Dropdown>
      <Modal
        destroyOnClose={true}
        footer={[]}
        title="Reset Password"
        open={modalOpen}
        onOk={setModal}
        onCancel={setModal}
      >
        <ResetPasswordForm record={UserData && UserData} submitted={setModal} />
      </Modal>
    </>
  ) : null;
};
