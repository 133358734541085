import * as yup from 'yup';
//Schema

export const EditParkedSchema = yup.object().shape({
  checkIn: yup.date().typeError(' Please enter parked date.').required(' Please enter parked date.'),
  // checkOut: yup
  //   .date()
  //   // .nullable()
  //   .typeError(' Please enter check out date.')
  //   .required(' Please enter check out date.')
  //   .when(
  //     'checkIn',
  //     (checkIn, schema) => checkIn && schema.min(checkIn, 'Checkout date must be greater than the checkin date.'),
  //   ),
  // checkOut: yup
  //     .date()
  //     .nullable()
  //     .test({
  //         test: function (value) {
  //             const { checkIn } = this.parent;
  //             if (!checkIn || !value) {
  //                 return true;
  //             }
  //             return value > checkIn;
  //         },
  //         message: 'Checkout date must be greater than checkin date.',
  //     }),
  // status: yup.object().typeError('Please select Status.').required('Please select Status.'),
});

export const EditRecordSchema = yup.object().shape({
  checkOut: yup.date().typeError(' Please enter exited date.').required(' Please enter exited date.'),
  // checkOut: yup
  //   .date()
  //   // .nullable()
  //   .typeError(' Please enter check out date.')
  //   .required(' Please enter check out date.')
  //   .when(
  //     'checkIn',
  //     (checkIn, schema) => checkIn && schema.min(checkIn, 'Checkout date must be greater than the checkin date.'),
  //   ),
  // checkOut: yup
  //     .date()
  //     .nullable()
  //     .test({
  //         test: function (value) {
  //             const { checkIn } = this.parent;
  //             if (!checkIn || !value) {
  //                 return true;
  //             }
  //             return value > checkIn;
  //         },
  //         message: 'Checkout date must be greater than checkin date.',
  //     }),
  // status: yup.object().typeError('Please select Status.').required('Please select Status.'),
});

export const EditSailingDateSchema = yup.object().shape({
  checkIn: yup.date().typeError(' Please enter sailing date.').required(' Please enter sailing date.'),
  ship: yup.object().typeError('Please select ship.').required('Please select ship.'),
});
