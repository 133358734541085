import { httpApi } from '@app/api/http.api';

export interface BillingResponse {
  data: any;
}

export interface ChangeStatusRequest {
  id: string;
  status: boolean;
}

export const getBilling = (): Promise<BillingResponse> =>
  httpApi.get<BillingResponse>('reservation/get/all/insurance/rate').then(({ data }) => data);

export const getBillingActive = (): Promise<BillingResponse> =>
  httpApi.get<BillingResponse>('reservation/get/active/insurance/rate').then(({ data }) => data);

export const changeStatus = (Req: any): Promise<BillingResponse> =>
  httpApi.post<BillingResponse>('reservation/update/insurance/rate', Req).then(({ data }) => data);

export const changeRate = (Req: any): Promise<BillingResponse> =>
  httpApi.post<BillingResponse>('reservation/update/insurance/rate', Req).then(({ data }) => data);

export const addNewBilling = (Req: any): Promise<BillingResponse> =>
  httpApi.post<BillingResponse>('reservation/add/active/insurance/rate', Req).then(({ data }) => data);

export const dltBilling = (Req: any): Promise<BillingResponse> =>
  httpApi.post<BillingResponse>('reservation/delete/active/insurance/rate', Req).then(({ data }) => data);
