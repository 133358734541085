import React, { useEffect, useState } from 'react';
import { Col, Row, Spin, Card, Typography, Button, Table, Checkbox, Space } from 'antd';
import { Table as NewTable } from '@app/components/common/Table/Table';
import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';
import { doBulkCheckout, doCheckOut, doGetParked, doRevertStatus } from '@app/store/slices/bookingSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import CameraApp from '@app/pages/Calendar/cameraApp';
import { QrReader } from 'react-qr-reader';
import axios from 'axios';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import _ from 'lodash';
import { CameraOutlined, ExpandOutlined, PlusOutlined, RedoOutlined } from '@ant-design/icons';
import { useResponsive } from '@app/hooks/useResponsive';
import { Badge, Ribbon } from '@app/components/common/Badge/Badge';
import { Modal } from '@app/components/common/Modal/Modal';
import { EditRecordForm } from '@app/components/forms/EditRecordForm/EditRecord';
import { EditParkedForm } from '@app/components/forms/EditParkedDate/EditParked';
import { Radio, Timeline } from 'antd';
import { TimelineProps } from 'antd/es';
const CryptoJS = require('crypto-js');

interface MyTimelineProps extends TimelineProps {
  items: {
    label?: string;
    children: string;
  }[];
}

const MyTimeline: React.FC<MyTimelineProps> = ({ items, ...rest }) => {
  return (
    <Timeline {...rest}>
      {items.map((item, index) => (
        <>
          <Timeline.Item key={index} label={item.label}>
            {item.children}
          </Timeline.Item>
          {/* <br /> */}
        </>
      ))}
    </Timeline>
  );
};

const { Column } = Table;
function App() {
  const bookings = useAppSelector((state) => state.booking);
  const todaysBookingData: any = bookings.todaysBookingData;
  const token = useAppSelector((state) => state.auth);
  const ParkedData: any = bookings.ParkedData;
  const tokenData: any = token.token;
  const UserData: any = token.UserData;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [revertStatusLoading, setRevertStatusLoading] = useState(false);
  const [bulkLoadingOther, setBulkLoadingOther] = useState(false);
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const dispatch = useAppDispatch();
  const [showImage, setShowImage] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loadingCheckinImage, setLoadingCheckinImage] = useState(false);
  const [filters, setFilters] = useState([]);
  const [filtersPlate, setFiltersPlate] = useState([]);
  const [newData, setNewData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTimeLineModal, setIsTimeLineModal] = useState(false);
  const [data, setData] = useState({});
  const [timeLineData, setTimeLineData] = useState([]);
  const [showQr, setShowQr] = useState(false);

  const { isTablet } = useResponsive();
  const { Title } = Typography;

  useEffect(() => {
    if (_.isEmpty(ParkedData)) {
      setFilters([]);
      setFiltersPlate([]);
      setNewData(ParkedData);
      return;
    } else {
      const x: any = [];
      const y: any = [];
      const z: any = [];
      ParkedData.map((item: any) =>
        x.push({
          text: item.fullName ? item.fullName : 'Quickbook',
          value: item.fullName ? item.fullName : 'Quickbook',
        }),
      );
      ParkedData.map((item: any) =>
        z.push({
          text: item.licensePlate.toUpperCase(),
          value: item.licensePlate,
        }),
      );

      ParkedData.map((item: any) => y.push({ ...item, key: item._id }));
      setFiltersPlate(z);
      setNewData(y);
      setFilters(x);
    }
  }, [ParkedData]);

  const getParked = () => {
    const d = {
      status: 'parked',
    };

    setLoading(true);
    dispatch(doGetParked(d))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  };

  const BulkCheckout = () => {
    const d = {
      id: selectedRowKeys,
    };
    setBulkLoading(true);
    dispatch(doBulkCheckout(d))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setBulkLoading(false);
        setSelectedRowKeys([]);
      });
  };

  const BulkCheckoutOther = () => {
    const x: any = [];
    ParkedData.map((item: any) => {
      x.push(item._id);
    });
    const dif = _.differenceWith(x, selectedRowKeys, _.isEqual);
    const d = {
      id: dif,
    };
    setBulkLoadingOther(true);
    dispatch(doBulkCheckout(d))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setBulkLoadingOther(false);
        setSelectedRowKeys([]);
      });
  };

  const BulkRevertStatus = () => {
    // const x: any = [];
    // ParkedData.map((item: any) => {
    //   x.push(item._id);
    // });
    // const dif = _.differenceWith(x, selectedRowKeys, _.isEqual);
    const d = {
      id: selectedRowKeys,
      status: 'reserved',
    };
    setRevertStatusLoading(true);
    dispatch(doRevertStatus(d))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
        getParked();
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setRevertStatusLoading(false);
        setSelectedRowKeys([]);
      });
  };

  useEffect(() => {
    getParked();
  }, []);

  // const getCellData = (x: any) => bookingsData.find((sI: any) => sI.checkIn.includes(moment(x).format('YYYY-MM-DD')));

  const handleNavigate = () => {
    navigate('/addNewBooking');
  };
  const handleNavigateQuickBook = () => {
    navigate('/addNewQuickBook');
  };

  const dataURLtoFile = (dataurl: any, filename: any) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  const Link =
    process.env.REACT_APP_ENVIROMENTS === 'production'
      ? 'https://api.parkgenix.com/api/'
      : 'https://uat.parkgenix.com/api/';
  const handleImage = (x: any) => {
    setLoadingCheckinImage(true);
    const file = dataURLtoFile(x, 'abc.jpg');
    const ImageData: any = new FormData();
    ImageData.append('plate', file);
    setShowImage(!showImage);

    axios
      .post(Link + 'reservation/check/out/plate', ImageData, {
        headers: {
          'Content-type': 'multipart/form-date',
          Authorization: `Bearer ${tokenData}`,
        },
      })
      .then((res) => {
        notificationController.success({ message: res.data.message });
        getParked();
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          navigate('/logout');
          notificationController.error({
            message: 'Your Session has been expired',
          });
        } else {
          notificationController.error({
            message: err.response?.data.message || 'Error completing your request. Please try again in a few minutes',
          });
        }
      })
      .finally(() => setLoadingCheckinImage(false));
  };

  const handleCheckQr = (x: any) => {
    if (!showQr) return;
    const val = CryptoJS.AES.decrypt(x, process.env.REACT_APP_KEY_SCAN);
    const originalText = val.toString(CryptoJS.enc.Utf8);
    if (!originalText) {
      notificationController.error({
        message: 'Invalid QR Code.',
      });
      return;
    }
    setShowQr(!showQr);
    const y = { id: originalText };
    setLoadingCheckout(!loadingCheckout);
    dispatch(doCheckOut(y))
      .unwrap()
      .then((res) => notificationController.success({ message: res.message }))
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoadingCheckout(false));
  };

  const columns: any = [
    {
      key: '1',
      title: 'Vehicle Details',
      responsive: ['sm'],
      sortDirections: ['descend', 'ascend'],
      sorter: (a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1),
      render: (record: any) => (
        <React.Fragment>
          {record && record.checkIn && (
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 'bold' }}>Parked:</Typography>
              <Typography style={{ marginLeft: '3px' }}>
                {moment.utc(record.checkIn).format('MM/DD/YYYY hh:mm')}
              </Typography>
            </div>
          )}
          {record && record.checkOut && (
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 'bold' }}>Exit:</Typography>
              <Typography style={{ marginLeft: '3px' }}>
                {moment.utc(record.checkOut).format('MM/DD/YYYY hh:mm')}
              </Typography>
            </div>
          )}
          {record && record.createdAt && (
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 'bold' }}>Booking:</Typography>
              <Typography style={{ marginLeft: '3px' }}>
                {moment.utc(record.createdAt).format('MM/DD/YYYY hh:mm')}
              </Typography>
            </div>
          )}
        </React.Fragment>
      ),
    },
    {
      key: '2',
      title: 'Vehicle Details',
      // sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
      filters: filters,
      filterSearch: true,
      onFilter: (value: any, record: any) => record.fullName.includes(value),
      render: (record: any) => (
        <React.Fragment>
          <span style={{ fontWeight: 'bold' }}>{record.fullName}</span>
          <br />
          {record.email}
          <br />
          {record && record.checkIn && (
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 'bold' }}>Parked:</Typography>
              <Typography style={{ marginLeft: '3px' }}>
                {moment.utc(record.checkIn).format('MM/DD/YYYY hh:mm')}
              </Typography>
            </div>
          )}
          {record && record.checkOut && (
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 'bold' }}>Exit:</Typography>
              <Typography style={{ marginLeft: '3px' }}>
                {moment.utc(record.checkOut).format('MM/DD/YYYY hh:mm')}
              </Typography>
            </div>
          )}
          <span style={{ fontWeight: 'bold' }}>{record.licensePlate}</span>
          <br />
          {record.color}
          {record && record.createdAt && (
            <div style={{ display: 'flex' }}>
              <Typography style={{ fontWeight: 'bold' }}>Booking:</Typography>
              <Typography style={{ marginLeft: '3px' }}>
                {moment.utc(record.createdAt).format('MM/DD/YYYY hh:mm')}
              </Typography>
            </div>
          )}
        </React.Fragment>
      ),
      responsive: ['xs'],
    },
    {
      className: 'ant-dropdown-menu',
      key: '3',
      title: 'Name / Email',
      responsive: ['sm'],
      filters: filters,
      filterSearch: true,
      onFilter: (value: any, record: any) => record.fullName.includes(value),
      render: (record: any) => (
        <React.Fragment>
          <span style={{ fontWeight: 'bold' }}>{record.fullName}</span>
          <br />
          {record.email}
        </React.Fragment>
      ),
    },
    {
      key: '4',
      responsive: ['sm'],
      title: 'License / Color',
      render: (record: any) => (
        <React.Fragment>
          {record.licensePlate}
          <br />
          {record.color}
        </React.Fragment>
      ),
    },
  ];

  console.log(process.env.REACT_APP_KEY_SCAN);
  console.log(process.env.REACT_APP_ENVIROMENTS);

  const MobileCheck = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a,
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4),
        )
      )
        check = true;
    })(navigator.userAgent);
    return check;
  };

  useEffect(() => {
    const a = MobileCheck();
    setIsMobile(a);
  }, []);

  const ConvertDate = (date: any) => {
    if (date.endsWith('T00:00:00.000Z')) {
      // If true, split by "T" and return the date part ([0] index)
      return date.split('T')[0];
    } else {
      // If false, return the entire date string unchanged
      return date;
    }
  };

  const handleOpenTimeLine = (d: any) => {
    const x: any = [];
    if (d.EventTimeline) {
      d.EventTimeline.map((item: any) => {
        x.push({ label: moment(item.date).format('MM-DD-YYYY hh:mm a'), children: item.event });
      });
    }

    setTimeLineData(x);
    setIsTimeLineModal(true);
  };

  const handleCancelTimeLine = () => {
    setIsTimeLineModal(false);
    setTimeLineData([]);
  };
  const handleOpen = (d: any) => {
    setData(d);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setData({});
  };
  const handleSubmitted = () => {
    setIsModalOpen(false);
    setData({});
    setSelectedRowKeys([]);
    getParked();
  };
  const handleRowSelectionChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: handleRowSelectionChange,
  };

  return (
    <div>
      <PageTitle>Parked</PageTitle>
      {showImage && (
        <Button icon={<CameraOutlined />} style={{ marginBottom: '20px' }} onClick={() => setShowImage(!showImage)}>
          Close Camera
        </Button>
      )}
      {showQr && (
        <Button icon={<ExpandOutlined />} style={{ marginBottom: '20px' }} onClick={() => setShowQr(!showQr)}>
          Close QR
        </Button>
      )}
      {isMobile && (
        <div
          style={
            isTablet
              ? { display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }
              : { display: 'flex', flexDirection: 'column', textAlign: 'center' }
          }
        >
          {!showImage && !showQr && <Title level={isTablet ? 4 : 5}>To Exit Any Car, Use Plate Scan / QR Scan</Title>}

          <div style={isTablet ? { display: 'flex' } : { display: 'flex', justifyContent: 'center' }}>
            {!showImage && !showQr && (
              <div style={isTablet ? { marginRight: '20px' } : { marginRight: '10px' }}>
                <Button
                  disabled={loadingCheckinImage}
                  size="small"
                  onClick={() => {
                    setShowImage(!showImage);
                  }}
                  icon={<CameraOutlined />}
                >
                  Plate Scan {loadingCheckinImage && <Spin />}
                </Button>
              </div>
            )}
            {!showQr && !showImage && (
              <div>
                <Button
                  size="small"
                  disabled={loadingCheckout}
                  onClick={() => {
                    setShowQr(!showQr);
                  }}
                  icon={<ExpandOutlined />}
                >
                  QR Scan {loadingCheckout && <Spin />}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      {showImage && (
        <Row gutter={{ xs: 32, sm: 32, md: 24, lg: 12, xl: 12 }}>
          <Col className="gutter-row" lg={12} sm={24} xs={24} xl={12} md={24}>
            <CameraApp handleUpload={(x: any) => handleImage(x)} />
          </Col>
        </Row>
      )}
      {showQr && (
        <Row gutter={{ xs: 32, sm: 32, md: 24, lg: 12, xl: 12 }}>
          <Col className="gutter-row" lg={12} sm={24} xs={24} xl={12} md={24}>
            <QrReader
              key="environment"
              constraints={{ facingMode: 'environment' }}
              onResult={(result: any, error) => {
                if (!!result) {
                  handleCheckQr(result?.text);
                }
                // if (!!error) {
                //   console.info(error);
                // }
              }}
            />
          </Col>
        </Row>
      )}
      {!showImage && !showQr && (
        <Card
          style={{ marginTop: '10px' }}
          title={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title level={5} style={{ fontWeight: 'bold' }}>
                  Parked: {loading ? <Spin /> : ParkedData && ParkedData.length}
                </Title>
                <div>
                  <Button
                    size={isTablet ? 'large' : 'small'}
                    disabled={loading}
                    icon={loading ? <Spin /> : <RedoOutlined />}
                    onClick={() => getParked()}
                  />
                </div>
              </div>
              <div style={isTablet ? { marginTop: '10px' } : { marginTop: '10px' }}>
                <div
                  style={
                    isTablet
                      ? { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }
                      : { display: 'flex', flexDirection: 'column', justifyContent: 'center' }
                  }
                >
                  <div style={{ textAlign: 'center' }}>
                    <Button size={isTablet ? 'large' : 'small'} icon={<PlusOutlined />} onClick={handleNavigate}>
                      Add Booking
                    </Button>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      style={isTablet ? { marginLeft: '5px' } : { marginTop: '5px' }}
                      size={isTablet ? 'large' : 'small'}
                      icon={<PlusOutlined />}
                      onClick={handleNavigateQuickBook}
                    >
                      Add Quick Booking
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          }
        >
          {!_.isEmpty(selectedRowKeys) && (
            <div style={isTablet ? { display: 'flex' } : { display: 'flex', flexDirection: 'column' }}>
              {UserData && UserData.type !== 'manager' && (
                <Button
                  size={'small'}
                  onClick={BulkCheckout}
                  disabled={revertStatusLoading || bulkLoading || bulkLoadingOther}
                >
                  Checkout selected items {bulkLoading && <Spin />}
                </Button>
              )}

              {/* <Button
                disabled={
                  revertStatusLoading || bulkLoading || bulkLoadingOther || ParkedData.length === selectedRowKeys.length
                }
                size={'small'}
                style={isTablet ? { marginLeft: '10px' } : { marginTop: '5px' }}
                onClick={BulkCheckoutOther}
              >
                Checkout unselected items {bulkLoadingOther && <Spin />}
              </Button> */}

              {UserData && UserData.type !== 'manager' && (
                <Button
                  disabled={revertStatusLoading || bulkLoadingOther || bulkLoading}
                  size={'small'}
                  style={isTablet ? { marginLeft: '10px' } : { marginTop: '5px' }}
                  onClick={BulkRevertStatus}
                >
                  Move to reserved {revertStatusLoading && <Spin />}
                </Button>
              )}
            </div>
          )}

          <Row gutter={[30, 30]} style={{ marginTop: '20px' }}>
            <Col span={24}>
              <NewTable
                loading={loading}
                // columns={columns}
                rowSelection={UserData && UserData.type !== 'manager' && rowSelection}
                dataSource={newData}
                pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
              >
                <Column
                  title={'Details'}
                  responsive={['sm']}
                  sortDirections={['descend', 'ascend']}
                  sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                  render={(record: any) => (
                    <React.Fragment key={record._id}>
                      {record && record.createdAt && (
                        <div style={{ display: 'flex', margin: '5px 0px 5px 0px' }}>
                          <Button size="small" onClick={() => handleOpenTimeLine(record)}>
                            View Timeline
                          </Button>
                        </div>
                      )}
                      {/* {record && record.createdAt && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Booking:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.createdAt)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}
                       {record && record.sailingDate && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Sailing:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.sailingDate)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )} */}
                      {record && record.checkIn && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Parked:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.checkIn)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}
                      {/* {record && record.checkOut && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Exit:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.checkOut)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )} */}
                    </React.Fragment>
                  )}
                />

                <Column
                  title={'Details'}
                  // sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                  filters={filtersPlate}
                  filterSearch={true}
                  onFilter={(value: any, record: any) => record.licensePlate.includes(value)}
                  render={(record: any) => (
                    <React.Fragment key={record._id}>
                      {record && record.fullName && record.fullName ? (
                        <>
                          {record && record.fullName && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px', fontWeight: 'bold' }}>
                                {record.fullName}
                              </Typography>
                            </div>
                          )}
                          {record && record.email && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.email}</Typography>
                            </div>
                          )}
                          {record && record.phone && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.phone}</Typography>
                            </div>
                          )}
                          {record && record.ship && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.ship}</Typography>
                            </div>
                          )}
                          {record && record.carMakeModel && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.carMakeModel}</Typography>
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Ribbon style={{ borderRadius: '3px 0px 0px 3px' }} text={'Quickbook'} color={'green'} />
                        </div>
                      )}

                      {/* {record && record.createdAt && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Booking:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.createdAt)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}  
                        {record && record.sailingDate && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Sailing:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.sailingDate)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )} */}
                      {record && record.checkIn && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Parked:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.checkIn)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )}
                      {/* {record && record.checkOut && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Exit:</Typography>
                          <Typography style={{ marginLeft: '3px' }}>
                            {moment(ConvertDate(record.checkOut)).format('MM/DD/YYYY')}
                          </Typography>
                        </div>
                      )} */}
                      <span style={{ fontWeight: 'bold' }}>{record.licensePlate.toUpperCase()}</span>
                      <br />

                      {record && record.color && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ marginLeft: '3px' }}>
                            {record.color.charAt(0).toUpperCase() + record.color.slice(1)}
                          </Typography>
                        </div>
                      )}
                      {record && record.createdAt && (
                        <div style={{ display: 'flex', margin: '5px 0px 5px 0px' }}>
                          <Button size="small" onClick={() => handleOpenTimeLine(record)}>
                            View Timeline
                          </Button>
                        </div>
                      )}

                      {UserData && UserData.type !== 'manager' && selectedRowKeys.includes(record._id) && (
                        <Button
                          size={'small'}
                          // disabled={record}
                          style={{ marginTop: '5px' }}
                          // loading={cRowOperation === record._id}
                          onClick={() => handleOpen(record)}
                        >
                          Update Date
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                  responsive={['xs']}
                />
                <Column
                  title="Ship"
                  responsive={['sm']}
                  sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.ship && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ marginLeft: '3px' }}>{record.ship}</Typography>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                />
                <Column
                  title="Make / Model"
                  responsive={['sm']}
                  sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                  render={(record: any) => (
                    <React.Fragment>
                      {record && record.carMakeModel && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ marginLeft: '3px' }}>{record.carMakeModel}</Typography>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                />
                <Column
                  title={'Name / Email / Phone'}
                  responsive={['sm']}
                  filters={filters}
                  filterSearch={true}
                  onFilter={(value: any, record: any) =>
                    record.fullName
                      ? record.fullName.toLowerCase().includes(value.toLowerCase())
                      : value === 'Quickbook' && !record.fullName
                  }
                  render={(record: any) => (
                    <React.Fragment key={record._id}>
                      {record && record.fullName && record.fullName ? (
                        <>
                          {record && record.fullName && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px', fontWeight: 'bold' }}>
                                {record.fullName}
                              </Typography>
                            </div>
                          )}
                          {record && record.email && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.email}</Typography>
                            </div>
                          )}
                          {record && record.phone && (
                            <div style={{ display: 'flex' }}>
                              <Typography style={{ marginLeft: '3px' }}>{record.phone}</Typography>
                            </div>
                          )}
                        </>
                      ) : (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Ribbon style={{ borderRadius: '3px 0px 0px 3px' }} text={'Quickbook'} color={'green'} />
                        </div>
                      )}
                    </React.Fragment>
                  )}
                />

                <Column
                  filters={filtersPlate}
                  filterSearch={true}
                  onFilter={(value: any, record: any) => record.licensePlate.includes(value)}
                  responsive={['sm']}
                  title={'License / Color'}
                  render={(record: any) => (
                    <React.Fragment key={record._id}>
                      {record.licensePlate.toUpperCase()}
                      <br />
                      {record && record.color && (
                        <div style={{ display: 'flex' }}>
                          <Typography style={{ marginLeft: '3px' }}>
                            {record.color.charAt(0).toUpperCase() + record.color.slice(1)}
                          </Typography>
                        </div>
                      )}
                    </React.Fragment>
                  )}
                />
                {UserData && UserData.type !== 'manager' && selectedRowKeys && !_.isEmpty(selectedRowKeys) && (
                  <Column
                    title="Action"
                    render={(record: any) => (
                      <Space size="large">
                        {selectedRowKeys.includes(record._id) && (
                          <Button
                            size={'small'}
                            // disabled={record}
                            // loading={cRowOperation === record._id}
                            onClick={() => handleOpen(record)}
                          >
                            Update Date
                          </Button>
                        )}
                      </Space>
                    )}
                    responsive={['sm']}
                  />
                )}
              </NewTable>
            </Col>
          </Row>
        </Card>
      )}
      <Modal
        footer={[]}
        title="Update Parked Date"
        open={isModalOpen}
        onOk={handleCancel}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleCancel}
      >
        <EditParkedForm record={data} submitted={handleSubmitted} />
      </Modal>
      <Modal
        footer={[]}
        title="Reservation Timeline"
        open={isTimeLineModal}
        onOk={handleCancelTimeLine}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleCancelTimeLine}
      >
        <MyTimeline items={timeLineData} />
      </Modal>
    </div>
  );
}

export default App;
