import * as React from 'react';
import { Select } from 'antd';
import { Label } from '@app/components/MyComponents/TextArea/styles';
import { InputError } from '@app/components/MyComponents/Input/styles';
export interface ICruiseSelectProps {
  options: any;
  onChange: (x: any) => void;
  label: any;
  error: any;
  value: any;
}

export function CruiseSelect(props: ICruiseSelectProps) {
  const { options, onChange, label, error, value } = props;

  return (
    <>
      <Label htmlFor={label}>{label}</Label>
      <Select
        value={value}
        placeholder={'Select Cruise'}
        labelInValue
        style={{ width: '100%' }}
        onChange={onChange}
        options={options}
      />
      <InputError>{error}</InputError>
    </>
  );
}
