import * as yup from 'yup';

function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
}

function calculateRGBDifference(color1, color2) {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);
  if (rgb1 && rgb2) {
    const difference = Math.sqrt(
      Math.pow(rgb1[0] - rgb2[0], 2) + Math.pow(rgb1[1] - rgb2[1], 2) + Math.pow(rgb1[2] - rgb2[2], 2),
    );
    return difference;
  }
  return 0;
}

export const AddShip = (y, z) =>
  yup.object().shape({
    name: yup
      .string()
      .required('Please enter ship name.')
      .matches(/^\S/, 'Please remove spaces from the start.')
      .matches(/^[A-Za-z ]+$/, 'Please enter only letters.')
      .test('check-name', 'This Name has already been selected.', function (value) {
        return !y.includes(value);
      }),

    rate: yup
      .string()
      .required('Please enter daily ship rate.')
      .matches(/^\d+(\.\d{1,2})?$/, 'Daily rate must be a positive value up to two decimal places greater than zero.')
      .test(
        'greater-than-zero',
        'Daily rate must be a positive value up to two decimal places greater than zero. ',
        (value) => {
          if (!value) return false;
          const floatValue = parseFloat(value);
          return floatValue > 0;
        },
      ),

    color: yup
      .string()
      .required('Please select color.')
      .test(
        'check-color',
        'Please try to select a color that is noticeably different from the colors already chosen to avoid confusion.',
        function (value) {
          for (const existingColor of z) {
            const difference = calculateRGBDifference(value, existingColor);
            if (difference < 50) {
              return false;
            }
          }
          return true;
        },
      ),
  });
