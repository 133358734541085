import { httpApi } from '@app/api/http.api';

export interface DashboardResponse {
  data: any;
  message: any;
  status: any;
}

export const getDashboardData = (): Promise<DashboardResponse> =>
  httpApi.get<DashboardResponse>('reservation/dashboard').then(({ data }) => data);
