import React, { useEffect, useState } from 'react';
import { Col, Row, Spin, Card, Typography, Button, Table } from 'antd';
import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';
import { doGetReservations } from '@app/store/slices/bookingSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';

const { Column } = Table;
function App() {
  const bookings = useAppSelector((state) => state.booking);
  const bookingsData: any = bookings.ReservationData;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const d = { status: '' };

    setLoading(true);
    dispatch(doGetReservations(d))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  }, []);

  const handleNavigate = () => {
    navigate('/addNewBooking');
  };

  return (
    <div>
      <PageTitle>All</PageTitle>
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography>All Reservations: {loading ? <Spin /> : bookingsData && bookingsData.length}</Typography>
            <div>
              <Button onClick={handleNavigate}>Add New</Button>
            </div>
          </div>
        }
      >
        <Row gutter={[30, 30]} style={{ marginTop: '20px' }}>
          <Col span={24}>
            <Table dataSource={bookingsData} pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}>
              <Column
                title="Check In"
                dataIndex="checkIn"
                key="checkIn"
                sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                render={(checkIn: string) => <>{checkIn && `${moment(checkIn).format('MM/DD/YYYY')}`}</>}
              />
              <Column
                title="Check Out"
                dataIndex="checkOut"
                key="checkOut"
                sorter={(a: any, b: any) => (a.checkOut < b.checkOut ? -1 : 1)}
                render={(checkOut: string) => <>{checkOut && `${moment(checkOut).format('MM/DD/YYYY')}`}</>}
              />
              <Column title="Color" dataIndex="color" key="color" />
              <Column title="Name" dataIndex="fullName" key="fullName" />
              <Column title="License Plate" dataIndex="licensePlate" key="licensePlate" />
              <Column title="Status" dataIndex="status" key="status" />
              <Column title="Email" dataIndex="email" key="email" />
              <Column
                title="Updated At"
                dataIndex="updatedAt"
                key="updatedAt"
                sorter={(a: any, b: any) => (a.updatedAt < b.updatedAt ? -1 : 1)}
                render={(updatedAt: string) => <>{updatedAt && `${moment(updatedAt).format('MM/DD/YYYY')}`}</>}
              />
              {/*<Column*/}
              {/*    title="Status"*/}
              {/*    key="action"*/}
              {/*    render={(_: any, record: any) => (*/}
              {/*        <Space size="large">*/}
              {/*          <Switch*/}
              {/*              // loading={cRowOperation === record._id}*/}
              {/*              // onChange={(x) => handleChangeStatus(x, record)}*/}
              {/*              defaultChecked={record.status}*/}
              {/*              checkedChildren="On"*/}
              {/*              unCheckedChildren="Off"*/}
              {/*          />*/}
              {/*        </Space>*/}
              {/*    )}*/}
              {/*/>*/}
            </Table>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default App;
