import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  getBookingRequest,
  CheckInRequest,
  getBookings,
  getReservationRequest,
  getReservations,
  checkIn,
  checkOut,
  getSpecificDateData,
  bulkCheckout,
  editSlots,
  bulkCheckIn,
  updateRecord,
  revertStatus,
  updateSailingDate,
  unused,
  filterBookingCall,
  getTodaysBooking,
  updateTodaysBooking,
} from '@app/api/booking.api';

export interface bookingSlice {
  bookingsData: [] | null;
  ReservationData: [] | null;
  ParkedData: [] | null;
  ExitedData: [] | null;
  SpecificDateData: '' | null;
  totalSlots: '' | null;
  SpecificDate: [] | null;
  filterBookingLoader: boolean;
  bookingFilterData: [];
  bookingFilterMsg: string;
  todaysBookingData: [] | null;
}

const initialState: bookingSlice = {
  bookingsData: [],
  ReservationData: [],
  ParkedData: [],
  ExitedData: [],
  SpecificDateData: '',
  totalSlots: '',
  SpecificDate: [],
  filterBookingLoader: false,
  bookingFilterData: [],
  bookingFilterMsg: 'Please select filter',
  todaysBookingData: [],
};

//On and Off today's booking
export const doGetTodaysBooking = createAsyncThunk('getTodaysBooking', async (payload: any) =>
  getTodaysBooking(payload).then((res) => res),
);

export const doUpdateTodaysBooking = createAsyncThunk('updateTodaysBooking', async (payload: any) =>
  updateTodaysBooking(payload).then((res) => res),
);

//On and Off today's booking//

export const doGetBookings = createAsyncThunk('getBookings', async (payload: getBookingRequest) =>
  getBookings(payload).then((res) => res),
);

export const doGetReservations = createAsyncThunk('getReservations', async (payload: getReservationRequest) =>
  getReservations(payload).then((res) => res),
);
export const doGetSpecificDateData = createAsyncThunk('doGetSpecificDateData', async (payload: any) =>
  getSpecificDateData(payload).then((res) => res),
);
export const doGetParked = createAsyncThunk('getParked', async (payload: getReservationRequest) =>
  getReservations(payload).then((res) => res),
);
export const doBulkCheckout = createAsyncThunk('bulkOut', async (payload: any) =>
  bulkCheckout(payload).then((res) => res),
);
export const doRevertStatus = createAsyncThunk('bulkRevert', async (payload: any) =>
  revertStatus(payload).then((res) => res),
);
export const doBulkCheckIn = createAsyncThunk('bulkIn', async (payload: any) =>
  bulkCheckIn(payload).then((res) => res),
);
export const doUnused = createAsyncThunk('unused', async (payload: any) => unused(payload).then((res) => res));
export const doGetExited = createAsyncThunk('getExited', async (payload: getReservationRequest) =>
  getReservations(payload).then((res) => res),
);

export const doCheckIn = createAsyncThunk('CheckedIn', async (Payload: CheckInRequest) =>
  checkIn(Payload).then((res) => res),
);

export const doCheckOut = createAsyncThunk('CheckedOut', async (Payload: CheckInRequest) =>
  checkOut(Payload).then((res) => res),
);
export const doUpdateRecord = createAsyncThunk('updateRecord', async (Payload: any) =>
  updateRecord(Payload).then((res) => res),
);

export const doUpdateSailingDate = createAsyncThunk('updateSailingdate', async (Payload: any) =>
  updateSailingDate(Payload).then((res) => res),
);
export const doEditSlots = createAsyncThunk('EditSlots', async (Payload: any) => editSlots(Payload).then((res) => res));

export const saveDate = createAction('saveDate', (data) => {
  return {
    payload: data,
  };
});

export const filterBookingData = createAsyncThunk('bookingData', async (Payload: any) =>
  filterBookingCall(Payload).then((res) => res),
);

const bookingsSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetBookings.fulfilled, (state, action) => {
      state.bookingsData = action.payload.data;
      state.totalSlots = !_.isEmpty(action.payload.data) ? action.payload.data.total : 0;
    });
    builder.addCase(doGetReservations.fulfilled, (state, action) => {
      state.ReservationData = action.payload.data;
    });
    builder.addCase(doGetParked.fulfilled, (state, action) => {
      state.ParkedData = action.payload.data;
    });
    builder.addCase(doGetExited.fulfilled, (state, action) => {
      state.ExitedData = action.payload.data;
    });
    builder.addCase(doCheckIn.fulfilled, (state, action) => {
      state.ReservationData = action.payload.data;
    });
    builder.addCase(doCheckOut.fulfilled, (state, action) => {
      state.ParkedData = action.payload.data;
    });
    builder.addCase(doGetSpecificDateData.fulfilled, (state, action) => {
      state.SpecificDateData = action.payload.data;
    });
    builder.addCase(saveDate, (state, action) => {
      state.SpecificDate = action.payload;
    });
    builder.addCase(doBulkCheckout.fulfilled, (state, action) => {
      state.ParkedData = action.payload.data;
    });
    builder.addCase(doBulkCheckIn.fulfilled, (state, action) => {
      state.ReservationData = action.payload.data;
    });
    builder.addCase(doUnused.fulfilled, (state, action) => {
      state.ReservationData = action.payload.data;
    });
    builder.addCase(doEditSlots.fulfilled, (state, action) => {
      state.totalSlots = !_.isEmpty(action.payload.data) ? action.payload.data.total : 0;
    });

    builder.addCase(filterBookingData.pending, (state, action) => {
      state.bookingFilterData = [];
      state.filterBookingLoader = true;
      state.bookingFilterMsg = 'Booking are loading...';
    });

    builder.addCase(filterBookingData.fulfilled, (state, action) => {
      state.filterBookingLoader = false;
      state.bookingFilterData = action.payload.data;
      state.bookingFilterMsg =
        action.payload.data && action.payload.data.length <= 0
          ? 'Sorry no booking available according your filter'
          : '';
    });

    builder.addCase(filterBookingData.rejected, (state, action) => {
      state.filterBookingLoader = false;
      state.bookingFilterMsg = 'Something is went wrong please search again';
    });
    builder.addCase(doGetTodaysBooking.fulfilled, (state, action) => {
      state.todaysBookingData = action.payload.data;
    });
  },
});

export default bookingsSlice.reducer;
