import * as yup from 'yup';
//Schema
export const ManagerSchema = yup.object().shape({
  firstName: yup
    .string()
    .required(' Please enter first name.')
    .matches(/^\S/, 'Please remove spaces from the start.')
    .matches(/^[A-Za-z ]+$/, 'Please enter only letters.'),
  lastName: yup
    .string()
    .required(' Please enter last name.')
    .matches(/^\S/, 'Please remove spaces from the start.')
    .matches(/^[A-Za-z ]+$/, 'Please enter only letters.'),
  password: yup
    .string()
    .matches(/^[^&]*$/, 'The "&" character is not allowed in passwords. Please choose a different password.')
    .min(8, 'Password must be grater than 8 characters.')
    .max(20, 'Password must be less than 20 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^*!?])[A-Za-z\d@#$%^*!?]{8,}$/,
      'Must contain 8 characters, one Uppercase, one Lowercase, one Number and one special case Character.',
    )
    .required(' Please enter password.'),
  email: yup.string().email('Please enter a valid email.').required(' Please enter an email.'),
});

export const UpdatePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(/^[^&]*$/, 'The "&" character is not allowed in passwords. Please choose a different password.')
    .min(8, 'Password must be grater than 8 characters.')
    .max(20, 'Password must be less than 20 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^*!?])[A-Za-z\d@#$%^*!?]{8,}$/,
      'Must contain 8 characters, one Uppercase, one Lowercase, one Number and one special case Character.',
    )
    .required(' Please enter password.'),
});

export const UpdateSlotsSchema = yup.object().shape({
  slot: yup
    .number()
    .integer('Please enter slots.')
    .typeError('Please enter slots.')
    .required('Please enter slots.')
    .test('Is positive?', 'The number must be greater than 0!', (value) => value > 0)
    .test('is-decimal', 'The number must be less than 6 Numbers', (value) => (value + '').match(/^[0-9]{1,6}$/)),
});
