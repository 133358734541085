import { httpApi } from '@app/api/http.api';

export interface managerResponse {
  data: any;
}

export interface AddManagerRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ChangeManagerRequest {
  _id: string;
  password: string;
}
export interface ResetPasswordRequest {
  currentPassword: string;
  newPassword: string;
}

export interface ChangeStatusRequest {
  id: string;
  status: boolean;
}

export const getManagers = (): Promise<managerResponse> =>
  httpApi.get<managerResponse>('auth/get/manager').then(({ data }) => data);

export const addManager = (registerData: AddManagerRequest): Promise<managerResponse> =>
  httpApi.post<managerResponse>('auth/register/manager', { ...registerData }).then(({ data }) => data);

export const changeManagerPassword = (changeManagerPassword: ChangeManagerRequest): Promise<managerResponse> =>
  httpApi.post<managerResponse>('auth/add/password', { ...changeManagerPassword }).then(({ data }) => data);

export const resetPassword = (resetPassword: ResetPasswordRequest): Promise<managerResponse> =>
  httpApi.post<managerResponse>('auth/change-password', { ...resetPassword }).then(({ data }) => data);

export const changeStatusManager = (ChangeStatusData: ChangeStatusRequest): Promise<managerResponse> =>
  httpApi.post<managerResponse>('auth/change/manager/status', { ...ChangeStatusData }).then(({ data }) => data);
