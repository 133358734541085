import React from 'react';
import { Card, Typography, Button } from 'antd';

import { useNavigate } from 'react-router-dom';
import AddQuickBook from '@app/components/forms/AddQuickBookForm/addQuickBook';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { BackIcon } from '@app/components/layouts/AuthLayout/AuthLayout.styles';

function App() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Button onClick={() => navigate(-1)} icon={<BackIcon />}>
          Back
        </Button>
      </div>
      <PageTitle>New QuickBook</PageTitle>
      {/*<Button icon={<ArrowLeftOutlined />} style={{ marginBottom: '10px' }} onClick={handleNavigate}>*/}
      {/*  Back*/}
      {/*</Button>*/}
      <Card title={<Typography>Add New Quick Booking</Typography>}>
        <AddQuickBook title={''} content={''} id={''} />
      </Card>
    </div>
  );
}

export default App;
