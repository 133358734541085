import { withTranslation } from 'react-i18next';
import { Container, InputError } from '../Input/styles';
import { Label } from '../TextArea/styles';
import { datePickeProps } from '../types';
import moment from 'moment';
import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

// disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}

const dateFormat = ['MM-DD-YYYY'];

const DatePickers = ({ name, onChange, error, t, dates, value, todaysBookingData }: datePickeProps) => {
  const checkForDates = (x: any, dates: any) => {
    if (moment(x).isSame(moment(), 'day') && todaysBookingData && todaysBookingData.inactive) {
      return true;
    }
    if (dates.includes(moment(x).local().format('YYYY-MM-DD'))) {
      if (x.isAfter(moment().local().subtract(1, 'day'))) {
        return false;
      }
      return true;
    }
    return true;
  };

  return (
    <Container>
      <Label htmlFor={name}>{t(name)}</Label>
      <DatePicker
        value={value}
        style={{ width: '100%' }}
        size="large"
        disabledDate={(current) => checkForDates(current, dates)}
        onChange={onChange}
        format={dateFormat}
      />
      <InputError>{error}</InputError>
    </Container>
  );
};

export default withTranslation()(DatePickers);
