import { httpApi } from '@app/api/http.api';

export interface InvoicesResponse {
  data: any;
}

export interface ChangeStatusRequest {
  id: string;
  status: boolean;
}

export const getInvoices = (Req: any): Promise<InvoicesResponse> =>
  httpApi.post<InvoicesResponse>('reservation/retrieve/data', Req).then(({ data }) => data);

export const changeStatus = (Req: any): Promise<InvoicesResponse> =>
  httpApi.post<InvoicesResponse>('reservation/update/insurance/rate', Req).then(({ data }) => data);
