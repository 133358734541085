import { httpApi } from '@app/api/http.api';

export interface refundResponse {
  data: any;
}

export interface ChangeStatusRequest {
  id: string;
  status: boolean;
}

export const getRefundRequests = (Req: any): Promise<refundResponse> =>
  httpApi.post<refundResponse>('reservation/data/refund/request', Req).then(({ data }) => data);

export const changeStatus = (Req: any): Promise<refundResponse> =>
  httpApi.post<refundResponse>('reservation/update/refund/status', Req).then(({ data }) => data);

/////
export const getRefundNow = (Req: any): Promise<refundResponse> =>
  httpApi.post<refundResponse>('reservation/initialize/refund/payment', Req).then(({ data }) => data);
