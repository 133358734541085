import * as React from 'react';
// import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { Descriptions, Typography } from 'antd';
import moment from 'moment';

export interface data2 {
  data: any;
  weekCount: any;
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  minimumSignificantDigits: 20,
  maximumSignificantDigits: 21,
});

const newFunc = (val: any) => {
  val = formatter.format(val);

  if (val.includes('.')) {
    const number = val.toString().split('.')[0] + '.' + val.split('.')[1].slice(0, 2);
    return number;
  } else {
    return val;
  }
};

const Summary = (props: data2) => {
  const { data, weekCount } = props;
  return (
    <>
      <Descriptions
        column={{ xxl: 4, xl: 3, lg: 4, md: 3, sm: 2, xs: 1 }}
        style={{ padding: '10px', paddingBottom: '0px' }}
        title="Reservation Info"
      >
        <Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Name</Typography>} span={4}>
          {data.fullName}
        </Descriptions.Item>
        {/*<Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Email</Typography>} span={4}>*/}
        {/*  {data.email}*/}
        {/*</Descriptions.Item>*/}
        <Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Phone</Typography>} span={4}>
          {data.phone}
        </Descriptions.Item>
        {/*<Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>State</Typography>}>*/}
        {/*  {data.state}*/}
        {/*</Descriptions.Item>*/}
        {/*<Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>ZipCode</Typography>}>*/}
        {/*  {data.zip}*/}
        {/*</Descriptions.Item>*/}
        {/*<Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>City</Typography>} span={2}>*/}
        {/*  {data.city}*/}
        {/*</Descriptions.Item>*/}
        {/*{data.addressLine1 && (*/}
        {/*  <Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Address</Typography>} span={4}>*/}
        {/*    {data.addressLine1}*/}
        {/*  </Descriptions.Item>*/}
        {/*)}*/}
        {/*{data.addressLine2 && (*/}
        {/*  <Descriptions.Item*/}
        {/*    label={<Typography style={{ fontWeight: 'bold' }}>Address Line 2 (Optional)</Typography>}*/}
        {/*    span={4}*/}
        {/*  >*/}
        {/*    {data.addressLine2}*/}
        {/*  </Descriptions.Item>*/}
        {/*)}*/}
        {/*<Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Modal</Typography>} span={4}>*/}
        {/*  {data.carBrand}, {data.carModel}*/}
        {/*</Descriptions.Item>*/}
        <Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>License Plate</Typography>} span={4}>
          {data.licensePlate.toUpperCase()}
        </Descriptions.Item>
        {/*<Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Color</Typography>} span={2}>*/}
        {/*  {data.color}*/}
        {/*</Descriptions.Item>*/}
        <Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Check In Date</Typography>} span={4}>
          {moment(data.checkIn).format('MM/DD/YYYY')}
        </Descriptions.Item>
        {data.ltp && (
          <Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>LTP</Typography>} span={4}>
            {' '}
            {newFunc(data.ltp)}
          </Descriptions.Item>
        )}
        {/*<Descriptions.Item  label="Check Out Date" span={2} >{moment(data.checkIn).add(weekCount, 'weeks').format('MM/DD/YYYY')}</Descriptions.Item>*/}
        <Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Total Amount</Typography>} span={4}>
          {' '}
          {newFunc(weekCount)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export interface data1 {
  data: any;
  weekCount: any;
}

const SquarePaymentForm = (props: data1) => {
  const { data, weekCount } = props;

  return (
    <>
      <Summary data={data} weekCount={weekCount} />

      {/*<PaymentForm*/}
      {/*    applicationId="sandbox-sq0idb-fT_fJNveeDkGfWt-j1kmeA"*/}
      {/*    cardTokenizeResponseReceived={cardTokenResponse}*/}
      {/*    locationId="LBG7BEBHX9J55"*/}
      {/*>*/}
      {/*    <CreditCard />*/}
      {/*</PaymentForm>*/}
    </>
  );
};

export default SquarePaymentForm;
