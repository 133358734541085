import React from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateOfferRateSchema } from './AddBookingForm/schema';
import Input from '../MyComponents/Input';
import { ContactContainer, FormGroup } from './AddBookingForm/styles';
import { Row, Col, Button, Spin, Switch } from 'antd';
import DatePickersEditRecord from '@app/components/MyComponents/DatePickerEditRecord';
import moment from 'moment';
//

const UpdateOffersRate = ({ updateRecord, loader, submit }: any) => {
  const defaultValues = updateRecord
    ? { rate: updateRecord.rate, days: updateRecord.days, validUntil: moment(updateRecord.validDate) }
    : { rate: '0', days: '0', validUntil: moment() };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(UpdateOfferRateSchema),
    defaultValues,
  });

  const OnSubmit = (x: any) => {
    console.log(x);
    const d = {
      // status: x.statusX,
      rate: x.rate,
      days: x.days,
      validDate: moment(x.validUntil).format('YYYY-MM-DD'),
    };
    submit(d);
  };

  return (
    <Row justify="center" align="middle">
      <Col lg={24} md={24} sm={24} xs={24}>
        <FormGroup autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
          <Col span={24}>
            <Controller
              name="days"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('days')}
                  type="number"
                  name={'Add Days'}
                  placeholder="Enter Days"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={errors.days && errors.days.message}
                />
              )}
            />
          </Col>
          <Col span={24}>
            <Controller
              name="rate"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('rate')}
                  type="number"
                  name={'Add Rate'}
                  placeholder="Enter Rate"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={errors.rate && errors.rate.message}
                />
              )}
            />
          </Col>
          <Col span={24}>
            <Controller
              name="validUntil"
              control={control}
              render={({ field }) => (
                <DatePickersEditRecord
                  {...register('validUntil')}
                  name="Valid Until"
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  disable={true}
                  error={errors.validUntil && errors.validUntil.message}
                />
              )}
            />
          </Col>
          <Button type="primary" htmlType="submit" disabled={!isDirty || !isValid || loader}>
            Update {loader && <Spin />}
          </Button>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default withTranslation()(UpdateOffersRate);
