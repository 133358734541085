import styled from 'styled-components';

export const Container = styled('div')`
  display: inline-block;
  width: 100%;
  padding: 10px 5px;
`;

export const StyledTextArea = styled('textarea')`
  resize: none;
  font-size: 16px;
  height: 185px;
  background: transparent;
  width: 100%;
  border-radius: 5px;
  padding: 10px 5px;
  color: var(--text-light-color);
`;

export const Label = styled('label')`
  display: block;
  padding-bottom: 10px;
  text-transform: capitalize;
`;

export const InputError = styled('p')`
  font-size: 0.875rem;
  color: red;
`;
