import styled from 'styled-components';

export const Container = styled('div')`
  display: inline-block;
  width: 100%;
  padding: 10px 5px;
`;

export const StyledInput = styled('input')`
  font-size: 0.875rem;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  color: var(--text-light-color);
  background: transparent;
`;

export const InputError = styled('p')`
  font-size: 0.875rem;
  color: red;
`;
