import React from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UpdateRateSchema } from './AddBookingForm/schema';
import Input from '../MyComponents/Input';
import { ContactContainer, FormGroup } from './AddBookingForm/styles';
import { Row, Col, Button, Spin } from 'antd';
//

const UpdateRate = ({ updateRecord, loader, submit, rate }: any) => {
  const defaultValues = rate ? { rate: rate } : { rate: 0 };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(UpdateRateSchema),
    defaultValues,
  });

  const OnSubmit = (x: any) => {
    const d = {
      id: updateRecord._id,
      rate: x.rate,
    };
    submit(d);
  };

  return (
    <Row justify="center" align="middle">
      <Col lg={24} md={24} sm={24} xs={24}>
        <FormGroup autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
          <Col span={24}>
            <Controller
              name="rate"
              control={control}
              render={({ field }) => (
                <Input
                  {...register('rate')}
                  type="number"
                  name={updateRecord.name}
                  placeholder="Enter Rate"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  error={errors.rate && errors.rate.message}
                />
              )}
            />
          </Col>
          <Button type="primary" htmlType="submit" disabled={!isDirty || !isValid || loader}>
            Update {loader && <Spin />}
          </Button>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default withTranslation()(UpdateRate);
