import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { NotificationsDropdown } from '../components/notificationsDropdown/NotificationsDropdown';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { SettingsDropdown } from '../components/settingsDropdown/SettingsDropdown';
import { HeaderFullscreen } from '../components/HeaderFullscreen/HeaderFullscreen';
import * as S from '../Header.styles';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  const [browser, setBrowser] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'chrome';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'firefox';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'safari';
    } else if (userAgent.match(/opr\//i)) {
      browserName = 'opera';
    } else if (userAgent.match(/edg/i)) {
      browserName = 'edge';
    } else {
      browserName = 'No browser detection';
    }
    setBrowser(browserName);
  }, []);

  const leftSide = isTwoColumnsLayout ? (
    <S.SearchColumn xl={14} xxl={14}>
      <Row justify="space-between">
        <Col xl={15} xxl={12}>
          {/*<HeaderSearch />*/}
        </Col>
        <Col>{/*<S.GHButton />*/}</Col>
      </Row>
    </S.SearchColumn>
  ) : (
    <>
      <Col lg={10} xxl={8}>
        {/*<HeaderSearch />*/}
      </Col>
      <Col>{/*<S.GHButton />*/}</Col>
    </>
  );

  return (
    <Row justify="space-between" align="middle">
      {leftSide}

      <S.ProfileColumn xl={10} xxl={10} $isTwoColumnsLayout={isTwoColumnsLayout}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row' }}>
          <Col>
            <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>
              {browser !== 'safari' && (
                <Col>
                  <HeaderFullscreen />
                </Col>
              )}

              {/*<Col>*/}
              {/*  <NotificationsDropdown />*/}
              {/*</Col>*/}

              <Col>
                <SettingsDropdown />
              </Col>
            </Row>
          </Col>

          <Col>
            <ProfileDropdown />
          </Col>
        </div>
      </S.ProfileColumn>
    </Row>
  );
};
