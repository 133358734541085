import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AddManagerRequest,
  addManager,
  getManagers,
  changeStatusManager,
  ChangeStatusRequest,
  ChangeManagerRequest,
  changeManagerPassword,
  ResetPasswordRequest,
  resetPassword,
} from '@app/api/manager.api';

export interface managerSlice {
  managersData: [] | null;
}

const initialState: managerSlice = {
  managersData: [],
};

export const doGetManagers = createAsyncThunk('getManagers', async () => getManagers().then((res) => res));

export const doAddManager = createAsyncThunk('AddManager', async (addManagerPayload: AddManagerRequest) =>
  addManager(addManagerPayload).then((res) => res),
);

export const doChangeManagerPassword = createAsyncThunk(
  'ChangeManagerPassword',
  async (changeManagerPayload: ChangeManagerRequest) => changeManagerPassword(changeManagerPayload).then((res) => res),
);

export const doResetPassword = createAsyncThunk('doResetPassword', async (resetPasswordPayload: ResetPasswordRequest) =>
  resetPassword(resetPasswordPayload).then((res) => res),
);

export const doUpdateStatus = createAsyncThunk('UpdateStatus', async (Payload: ChangeStatusRequest) =>
  changeStatusManager(Payload).then((res) => res),
);

const managersSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetManagers.fulfilled, (state, action) => {
      state.managersData = action.payload.data;
    });
    builder.addCase(doAddManager.fulfilled, (state, action) => {
      state.managersData = action.payload.data;
    });
    builder.addCase(doUpdateStatus.fulfilled, (state, action) => {
      let Index: any = 0;
      Index = state.managersData?.findIndex((bill: any) => bill?._id === action.payload.data?._id);
      const x: any = state.managersData;
      x[Index] = action.payload.data;
      state.managersData = x;
      // console.log(action.payload.data)
      // state.managersData = action.payload.data;
    });
  },
});

export default managersSlice.reducer;
