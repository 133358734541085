import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row, Space, Table, Switch, Card, Calendar, Typography, Button, Modal } from 'antd';
import { Table as NewTable } from '@app/components/common/Table/Table';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doGetShips, shipsDetails, updateShipRate, updateShipStatus } from '@app/store/slices/shipSlice';
import _ from 'lodash';
import A001 from '@app/pages/Ships/adventure.svg';
import A002 from '@app/pages/Ships/allure.svg';
import A003 from '@app/pages/Ships/radiance.svg';
import A005 from '@app/pages/Ships/voyager.svg';
import A004 from '@app/pages/Ships/harmonyv.svg';
import A006 from '@app/pages/Ships/jewel.svg';
import A007 from '@app/pages/Ships/mariner.svg';
import Default from '@app/pages/Ships/default.svg';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import UpdateRate from '@app/components/forms/UpdateRateForm';
import UpdateOffersRateForm from '@app/components/forms/UpdateOffersRateForm';
import AddManagerForm from '@app/components/forms/AddShipFrom';
import ShipComponent from './ShipComponent';

const { Column } = Table;
const imagesArray = (key: any) => {
  if (key == 'A001') return A001;
  if (key == 'A002') return A002;
  if (key == 'A003') return A003;
  if (key == 'A004') return A004;
  if (key == 'A005') return A005;
  if (key == 'A006') return A006;
  if (key == 'A007') return A007;
  return Default;
};
const ShipsPage: React.FC = () => {
  interface DataType {
    key: string;
    duration: number;
    name: string;
    sailing_dates: string[];
    rateSchedule: Array<any>[];
    status: boolean;
    color: string;
    _id: string;
    rate: string;
  }

  const { isDesktop, isTablet } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOffersOpen, setModalOffersOpen] = useState(false);
  const [updateRecord, setUpdateRecord] = useState({});
  const [rate, setRate] = useState('');
  const [updateOffersRecord, setUpdateOffersRecord] = useState<any>([]);
  const [loaderOffers, setLoaderOffers] = useState(false);
  const [cRowOperation, setCRowOperation] = useState<string | null>(null);
  const [addShipModal, setAddShipModal] = useState(false);

  const { t } = useTranslation();
  const { Title } = Typography;

  const ships = useAppSelector((state) => state.ship);
  const shipsData: any = ships.shipsData;
  const User = useAppSelector((state) => state.auth);
  const UserData: any = User.UserData;

  useEffect(() => {
    setLoading(true);
    dispatch(doGetShips())
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  }, []);

  const [checkStatus, setCheckStatus] = useState(false);

  const handleChangeStatus = (checked: boolean, x: DataType) => {
    if (x.sailing_dates && x.sailing_dates.length === 0) {
      notificationController.error({ message: 'Please Add Sailing Dates First' });
      setCheckStatus(false);
    } else {
      setCRowOperation(x._id);
      dispatch(updateShipStatus({ id: x._id, status: checked }))
        .unwrap()
        .then((res: any) => {
          notificationController.success({ message: res.message });
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
        })
        .finally(() => setCRowOperation(null));
    }
  };

  const getCellData = (x: any) =>
    shipsData.filter((sI: any) => sI.sailing_dates.includes(moment(x).format('YYYY-MM-DD')));
  const getMonthData = (x: any) => {
    let count = 0;
    shipsData.map((sI: any) => {
      sI.status &&
        sI.sailing_dates.map((t: string) => {
          moment(t).isSame(moment(x), 'month') ? count++ : null;
        });
    });
    return count;
  };

  const CellRenderer = (x: any) =>
    x.map((obj: any) =>
      obj.status ? (
        isTablet ? (
          <ul className="events">
            <ShipComponent width={50} height={20} style={{ marginBottom: '5px' }} color={obj.color} />
            {/* <img src={imagesArray(obj.key)} alt={obj.key} width={50} height={20} style={{ marginBottom: '5px' }} /> */}
            <li style={{ background: obj.color, color: 'white', fontSize: '0.8rem' }}>{obj.name}</li>
          </ul>
        ) : (
          <ShipComponent width={25} color={obj.color} />
          // <img src={imagesArray(obj.key)} alt={obj.key} width={25} />
          // <div style={{ background: obj.color, width: '20px', height: '20px', borderRadius: '50%' }} />
        )
      ) : null,
    );

  const handleCellRender = (x: any) => {
    const shipArray = getCellData(x);
    return _.isEmpty(shipArray) ? <div /> : CellRenderer(shipArray);
  };

  const handleMonthRender = (x: any) => {
    const month = getMonthData(x);
    return month ? (
      <div className="notes-month">
        <span>Total Ships {month}</span>
      </div>
    ) : null;
  };

  const setModal = () => {
    setModalOpen(!modalOpen);
  };
  const setModalClose = () => {
    setModalOpen(false);
    setUpdateRecord({});
    setRate('');
  };
  const handleUpdateRate = (r: any) => {
    setUpdateRecord(r);
    setRate(r?.rate);
    setModal();
  };

  const handleRateSubmit = (x: any) => {
    setLoader(true);
    dispatch(updateShipRate(x))
      .unwrap()
      .then((res: any) => {
        setModalClose();
        notificationController.success({ message: res.message });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoader(false));
  };

  const handleOpenDetails = (x: any) => {
    dispatch(shipsDetails(x));
    navigate('/shipDetails');
  };

  const setModalOffers = () => {
    setModalOffersOpen(!modalOffersOpen);
  };
  const setModalOffersClose = () => {
    setModalOffersOpen(false);
    setUpdateOffersRecord([]);
  };
  const handleUpdateOffersRate = (r: any) => {
    setUpdateOffersRecord(r);
    setModalOffers();
  };

  const showModal = () => {
    setAddShipModal(true);
  };

  const handleAddShipCancel = () => {
    setAddShipModal(false);
  };

  const handleSubmitted = () => {
    setAddShipModal(false);
  };

  const handleOffersRateSubmit = (x: any) => {
    const xx = {
      id: updateOffersRecord?._id,
      rateSchedule: {
        status: true,
        ...x,
      },
    };

    setLoaderOffers(true);

    dispatch(updateShipRate(xx))
      .unwrap()
      .then((res: any) => {
        setModalOffersClose();
        notificationController.success({ message: res.message });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoaderOffers(false));
  };

  const desktopLayout = (
    <>
      <Card
        title={
          <div
            style={
              isTablet
                ? { display: 'flex', justifyContent: 'space-between' }
                : { display: 'flex', flexDirection: 'column' }
            }
          >
            <Title level={5} style={{ fontWeight: 'bold' }}>
              Cruise Details
            </Title>
            <div>
              {UserData && UserData.type === 'admin' && (
                <Button size={isTablet ? 'large' : 'small'} onClick={showModal}>
                  Add Ship
                </Button>
              )}
            </div>
          </div>
        }
      >
        <Row gutter={[30, 30]}>
          <Col span={24}>
            <NewTable
              loading={loading}
              dataSource={shipsData}
              pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
            >
              <Column
                title="Name / Status"
                responsive={['xs']}
                sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                render={(record: any) => (
                  <React.Fragment>
                    {record && (
                      <>
                        {record.name}
                        <br />
                      </>
                    )}
                    {record && record.rate && (
                      <>
                        ${record.rate}{' '}
                        {UserData && UserData.type === 'admin' && (
                          <Button
                            style={{ border: 'none' }}
                            onClick={() => handleUpdateRate(record)}
                            icon={<EditOutlined style={{ color: `${record.color}` }} />}
                          />
                        )}
                        <br />
                      </>
                    )}
                    <Space size="large">
                      <Switch
                        disabled={UserData && UserData.type === 'manager'}
                        loading={cRowOperation === record._id}
                        onChange={(x) => handleChangeStatus(x, record)}
                        defaultChecked={record.status}
                        checked={
                          record.sailing_dates && record.sailing_dates.length === 0 ? checkStatus : record.status
                        }
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />
                    </Space>
                    <br />
                    {record && record?.sailing_dates && `${record?.sailing_dates.length} Times`}
                    <br />
                    <Space size="large" style={{ marginTop: '5px', marginBottom: '5px' }}>
                      <Button
                        disabled={record.sailing_dates && record.sailing_dates.length < 1}
                        size={'small'}
                        onClick={() => handleUpdateOffersRate(record)}
                      >
                        {' '}
                        Add New Offer{' '}
                      </Button>
                    </Space>
                    <Space size="large">
                      <Button size={'small'} onClick={() => handleOpenDetails(record)}>
                        {' '}
                        Details{' '}
                      </Button>
                    </Space>
                  </React.Fragment>
                )}
              />
              <Column responsive={['sm']} title="Name" dataIndex="name" key="name" />
              <Column
                title="Daily Rate"
                key="rate"
                responsive={['sm']}
                render={(_: any, record: DataType) => (
                  <React.Fragment>
                    {record && <>{record.rate && '$' + record.rate}</>}
                    {UserData && UserData.type === 'admin' && (
                      <Button
                        style={{ border: 'none' }}
                        onClick={() => handleUpdateRate(record)}
                        icon={<EditOutlined style={{ color: `${record.color}` }} />}
                      />
                    )}
                  </React.Fragment>
                )}
              />

              <Column
                responsive={['sm']}
                title="Sailing"
                dataIndex="sailing_dates"
                key="sailing_dates"
                render={(sailing_dates: string[]) => <>{sailing_dates && `${sailing_dates.length} Times`}</>}
              />
              <Column
                title="Color"
                key="color"
                render={(_: any, record: DataType) => (
                  <Space size="large">
                    <ShipComponent width={70} color={record.color} />
                  </Space>
                )}
              />
              <Column
                responsive={['sm']}
                title="Status"
                key="action"
                render={(_: any, record: DataType) => (
                  <Space size="large">
                    <Switch
                      disabled={UserData && UserData.type === 'manager'}
                      loading={cRowOperation === record._id}
                      onChange={(x) => handleChangeStatus(x, record)}
                      defaultChecked={record.status}
                      checked={record.sailing_dates && record.sailing_dates.length === 0 ? checkStatus : record.status}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />
                  </Space>
                )}
              />
              {UserData && UserData.type === 'admin' && (
                <Column
                  responsive={['sm']}
                  title="Offer"
                  key="Offer"
                  render={(_: any, record: DataType) => (
                    <Space size="large">
                      {record && <>{record?.rateSchedule ? record?.rateSchedule?.length : 0}</>}
                      {UserData && UserData.type === 'admin' && (
                        <Button
                          disabled={record.sailing_dates && record.sailing_dates.length < 1}
                          size={'small'}
                          icon={<PlusOutlined style={{ color: `#00509A` }} />}
                          onClick={() => handleUpdateOffersRate(record)}
                        />
                      )}
                    </Space>
                  )}
                />
              )}
              {UserData && UserData.type === 'admin' && (
                <Column
                  responsive={['sm']}
                  title="Details"
                  key="details"
                  render={(_: any, record: DataType) => (
                    <Space size="large">
                      <Button size={'small'} onClick={() => handleOpenDetails(record)}>
                        {' '}
                        Details{' '}
                      </Button>
                    </Space>
                  )}
                />
              )}
            </NewTable>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {!_.isEmpty(shipsData) && (
              <Calendar dateCellRender={handleCellRender} monthCellRender={handleMonthRender} />
            )}
          </Col>
        </Row>
      </Card>
      <Modal
        footer={[]}
        title="Add New Ship"
        open={addShipModal}
        // onOk={handleOk}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handleAddShipCancel}
      >
        <AddManagerForm submitted={handleSubmitted} />
      </Modal>
      <Modal
        footer={[]}
        title="Rate Update"
        open={modalOpen}
        onOk={setModalClose}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => setModalClose()}
      >
        <UpdateRate
          key={rate ? rate.toString() : '123'}
          updateRecord={updateRecord}
          rate={rate}
          loader={loader}
          submit={(x: any) => handleRateSubmit(x)}
        />
      </Modal>
      <Modal
        footer={[]}
        title="Add New Offer"
        open={modalOffersOpen}
        onOk={setModalOffersClose}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => setModalOffersClose()}
      >
        <UpdateOffersRateForm
          key={updateOffersRecord ? updateOffersRecord.toString() : '123'}
          updateRecord={{}}
          loader={loaderOffers}
          submit={(x: any) => handleOffersRateSubmit(x)}
        />
      </Modal>
    </>
  );

  return (
    <>
      <PageTitle>Ships</PageTitle>
      {isDesktop ? desktopLayout : desktopLayout}
    </>
  );
};

export default ShipsPage;
