import * as React from 'react';
// import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { Button, Descriptions, Spin, Typography } from 'antd';
import moment from 'moment';
import { ButtonContainer } from '@app/components/forms/AddBookingForm/styles';

export interface data2 {
  data: any;
  weekCount: any;
}

const Summary = (props: data2) => {
  const { data, weekCount } = props;
  return (
    <>
      <Descriptions
        column={{ xxl: 4, xl: 3, lg: 4, md: 3, sm: 2, xs: 1 }}
        style={{ padding: '10px', paddingBottom: '0px' }}
        title="Reservation Info"
      >
        <Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>License Plate</Typography>} span={4}>
          {data.licensePlate.toUpperCase()}
        </Descriptions.Item>

        <Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Check In Date</Typography>} span={4}>
          {moment(data.checkIn).format('MM/DD/YYYY')}
        </Descriptions.Item>

        {/*<Descriptions.Item label={<Typography style={{ fontWeight: 'bold' }}>Amount</Typography>} span={4}>*/}
        {/*  {' '}*/}
        {/*  ${weekCount}*/}
        {/*</Descriptions.Item>*/}
      </Descriptions>
    </>
  );
};

export interface data1 {
  data: any;
  weekCount: any;
  handleSubmitAllData: any;
  loader: any;
}

const SquarePaymentForm = (props: data1) => {
  const { data, weekCount, handleSubmitAllData, loader } = props;

  return (
    <>
      <Summary data={data} weekCount={weekCount} />
      <ButtonContainer>
        <Button type="primary" onClick={() => handleSubmitAllData()} disabled={loader}>
          Submit {loader && <Spin />}
        </Button>
      </ButtonContainer>
    </>
  );
};

export default SquarePaymentForm;
