import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getDashboardData } from '@app/api/dashboard.api';

export interface bookingSlice {
  dashboardData: [] | null;
}

const initialState: bookingSlice = {
  dashboardData: [],
};

export const doGetDashboardData = createAsyncThunk('getDashBoardData', async () =>
  getDashboardData().then((res) => res),
);

const bookingsSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetDashboardData.fulfilled, (state, action) => {
      state.dashboardData = action.payload.data;
    });
  },
});

export default bookingsSlice.reducer;
