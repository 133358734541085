import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doChangeManagerPassword } from '@app/store/slices/managerSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Common from '@app/components/layouts/Common/CommonLayout.styles';
import { Button, Col, Row, Spin, Tooltip } from 'antd';
import { ButtonContainer, FormGroup } from '@app/components/forms/AddBookingForm/styles';
import { Controller, useForm } from 'react-hook-form';
import { Label } from '@app/components/MyComponents/TextArea/styles';
import PasswordManagerInput from '@app/components/MyComponents/PasswordManagerInput';
import { CopyOutlined, SyncOutlined } from '@ant-design/icons';
import { InputError } from '@app/components/MyComponents/PasswordManagerInput/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { ManagerSchema, UpdatePasswordSchema } from '@app/components/forms/schemaAddManager';

type comProps = {
  submitted: () => void;
  record: any;
};

export const ChangeManagerPasswordForm: React.FC<comProps> = (props) => {
  const { submitted, record } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UpdatePasswordSchema),
  });

  const { t } = useTranslation();

  const Submit = (values: any) => {
    setLoading(true);
    const newValues = { ...values, _id: record?._id };
    dispatch(doChangeManagerPassword(newValues))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
        submitted();
        setValue('password', '');
        setLoading(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };
  const chars = 'abcdefghijklmnopqrstuvwxyz';
  const chars1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const chars2 = '0123456789';
  const chars3 = '@#$%^*!?';
  const charsLength = 3;
  const chars1Length = 3;
  const chars2Length = 2;
  const chars3Length = 2;

  const generatePassword = () => {
    let a = '';
    let b = '';
    let c = '';
    let d = '';
    for (let i = 0; i <= charsLength; i++) {
      const randomNumber = Math.floor(Math.random() * chars.length);
      a += chars.substring(randomNumber, randomNumber + 1);
    }
    for (let i = 0; i <= chars1Length; i++) {
      const randomNumber = Math.floor(Math.random() * chars1.length);
      b += chars1.substring(randomNumber, randomNumber + 1);
    }
    for (let i = 0; i <= chars2Length; i++) {
      const randomNumber = Math.floor(Math.random() * chars2.length);
      c += chars2.substring(randomNumber, randomNumber + 1);
    }
    for (let i = 0; i <= chars3Length; i++) {
      const randomNumber = Math.floor(Math.random() * chars3.length);
      d += chars3.substring(randomNumber, randomNumber + 1);
    }
    setValue('password', a + b + c + d);
  };
  const coppied = () => {
    const regex = '^\\s+$';
    if (!getValues('password') || (getValues('password') && getValues('password').match(regex))) {
      notificationController.warning({ message: 'Password field is empty' });
    } else {
      navigator.clipboard.writeText(getValues('password'));
      notificationController.success({ message: 'Password copied' });
    }
  };

  return (
    <Row justify="center" align="middle">
      <div style={{ position: 'relative' }}>
        <FormGroup autoComplete="off" onSubmit={handleSubmit(Submit)}>
          <Common.FormTitle>
            {record?.firstName} {record?.lastName}
          </Common.FormTitle>
          <Col span={24}>
            <>
              <Label htmlFor={'password'}>Password</Label>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <PasswordManagerInput
                      {...register('password')}
                      type="text"
                      name="password"
                      placeholder="Enter New password"
                      value={field.value}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      error={errors.password && errors.password.message}
                    />
                    <Tooltip placement="top" title={'Generate password'}>
                      <Button
                        onClick={() => generatePassword()}
                        style={{ marginRight: '2px', marginLeft: '2px' }}
                        icon={<SyncOutlined />}
                      />
                    </Tooltip>
                    <Tooltip placement="top" title={'Copy password'}>
                      <Button onClick={() => coppied()} icon={<CopyOutlined />} />
                    </Tooltip>
                  </div>
                )}
              />
              <InputError>{errors.password && errors.password.message}</InputError>
            </>
          </Col>

          <ButtonContainer>
            <Button type="primary" htmlType="submit" disabled={isLoading}>
              Update {isLoading && <Spin />}
            </Button>
          </ButtonContainer>
        </FormGroup>
      </div>
    </Row>
  );
};
