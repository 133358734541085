import { withTranslation } from 'react-i18next';
import { Row, Col, Button, Spin } from 'antd';

const EmailNotificationForm = ({ loader, cancel, status, ok }: any) => {
  const handleOk = (x: any) => {
    ok(x);
  };

  return (
    <Row justify="center" align="middle">
      <Col lg={24} md={24} sm={24} xs={24}>
        {/* <Button type="primary" htmlType="submit" disabled={!isDirty || !isValid || loader}>
            Update {loader && <Spin />}
          </Button> */}

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={cancel}>Cancel</Button>

          <Button type="primary" disabled={loader} onClick={() => handleOk(status)}>
            Ok {loader && <Spin />}
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default withTranslation()(EmailNotificationForm);
