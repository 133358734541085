import { httpApi } from '@app/api/http.api';

export interface ShipsResponse {
  data: any;
}

export interface ChangeStatusRequest {
  id: string;
  status: boolean;
}

export interface AddShipRequest {
  name: string;
  rate: number;
  color: string;
}

export const addShip = (registerData: AddShipRequest): Promise<ShipsResponse> =>
  httpApi.post<ShipsResponse>('reservation/add/ship', { ...registerData }).then(({ data }) => data);

export const getShips = (): Promise<ShipsResponse> =>
  httpApi.get<ShipsResponse>('reservation/get/ship/all').then(({ data }) => data);

export const changeStatus = (Req: ChangeStatusRequest): Promise<ShipsResponse> =>
  httpApi.post<ShipsResponse>('reservation/change/ship/status', Req).then(({ data }) => data);

export const changeOfferStatus = (Req: any): Promise<any> =>
  httpApi.post<any>('reservation/update/status/rateSchedule', Req).then(({ data }) => data);

export const changeRate = (Req: any): Promise<ShipsResponse> =>
  httpApi.post<ShipsResponse>('reservation/update/ship/rate', Req).then(({ data }) => data);

export const changeDates = (Req: any): Promise<ShipsResponse> =>
  httpApi.post<ShipsResponse>('reservation/update/ship/date', Req).then(({ data }) => data);
