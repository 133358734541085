import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeStatus, getRefundRequests, getRefundNow } from '@app/api/refund.api';

export interface RefundRequestSlice {
  refundRequestData: [] | null;
  refundApprovedData: [] | null;
  refundVerifiedData: [] | null;
}

const initialState: RefundRequestSlice = {
  refundRequestData: [],
  refundApprovedData: [],
  refundVerifiedData: [],
};

export const doGetRefundRequests = createAsyncThunk('getRefundRequested', async (data: any) =>
  getRefundRequests(data).then((res) => res),
);
export const doGetVerifiedRequests = createAsyncThunk('getRefundVerified', async (data: any) =>
  getRefundRequests(data).then((res) => res),
);
export const doGetApprovedRequests = createAsyncThunk('getRefundapproved', async (data: any) =>
  getRefundRequests(data).then((res) => res),
);

export const updateRequestStatus = createAsyncThunk('changeRefundsStatusRequested', async (changeStatusPayload: any) =>
  changeStatus(changeStatusPayload).then((res) => res),
);
export const updateVerifiedStatus = createAsyncThunk('changeRefundsStatusVerified', async (changeStatusPayload: any) =>
  changeStatus(changeStatusPayload).then((res) => res),
);
export const updatePaidStatus = createAsyncThunk('changeRefundsStatusPaid', async (changeStatusPayload: any) =>
  changeStatus(changeStatusPayload).then((res) => res),
);
/////
export const doRefundNow = createAsyncThunk('RefundNowStatusVerified', async (data: any) =>
  getRefundNow(data).then((res) => res),
);

const refundRequestSlice = createSlice({
  name: 'refundRequestes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetRefundRequests.fulfilled, (state, action) => {
      state.refundRequestData = action.payload.data;
    });
    builder.addCase(doGetVerifiedRequests.fulfilled, (state, action) => {
      state.refundVerifiedData = action.payload.data;
    });
    builder.addCase(doGetApprovedRequests.fulfilled, (state, action) => {
      state.refundApprovedData = action.payload.data;
    });
    builder.addCase(updateRequestStatus.fulfilled, (state, action) => {
      let Index: any = 0;
      Index = state.refundRequestData?.findIndex((data: any) => data?._id === action.payload.data?._id);
      const x: any = state.refundRequestData;
      x.splice(Index, 1);
      state.refundRequestData = x;
    });
    builder.addCase(updateVerifiedStatus.fulfilled, (state, action) => {
      let Index: any = 0;
      Index = state.refundVerifiedData?.findIndex((data: any) => data?._id === action.payload.data?._id);
      const x: any = state.refundVerifiedData;
      x.splice(Index, 1);
      state.refundVerifiedData = x;
    });
    builder.addCase(updatePaidStatus.fulfilled, (state, action) => {
      let Index: any = 0;
      Index = state.refundApprovedData?.findIndex((data: any) => data?._id === action.payload.data?._id);
      const x: any = state.refundApprovedData;
      x.splice(Index, 1);
      state.refundApprovedData = x;
    });

    ////
    builder.addCase(doRefundNow.fulfilled, (state, action) => {
      let Index: any = 0;
      Index = state.refundVerifiedData?.findIndex((data: any) => data?._id === action.payload.data?._id);
      const x: any = state.refundVerifiedData;
      x.splice(Index, 1);
      state.refundVerifiedData = x;
    });
  },
});

export default refundRequestSlice.reducer;
