import React, { useEffect, useState } from 'react';
import { Col, Row, Spin, Card, Typography, Button, Table } from 'antd';
import { Table as NewTable } from '@app/components/common/Table/Table';
import moment from 'moment';
import { notificationController } from '@app/controllers/notificationController';
import { doBulkCheckIn, doCheckIn, doGetReservations, doGetSpecificDateData } from '@app/store/slices/bookingSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import CameraApp from '@app/pages/Calendar/cameraApp';
import { QrReader } from 'react-qr-reader';
import axios from 'axios';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import _ from 'lodash';
import { CameraOutlined, ExpandOutlined, PlusOutlined } from '@ant-design/icons';
import { useResponsive } from '@app/hooks/useResponsive';
import { Ribbon } from '@app/components/common/Badge/Badge';
const CryptoJS = require('crypto-js');

const { Column } = Table;
function SpecificDateData() {
  const bookings = useAppSelector((state) => state.booking);
  const token = useAppSelector((state) => state.auth);
  const bookingsData: any = bookings.SpecificDateData;
  const SpecificDate: any = bookings.SpecificDate;
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState([]);
  const [newData, setNewData] = useState([]);
  const [filtersPlate, setFiltersPlate] = useState([]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [bulkLoading, setBulkLoading] = useState(false);
  const { isTablet } = useResponsive();
  const { Title } = Typography;
  useEffect(() => {
    // console.log(bookingsData);
    if (_.isEmpty(bookingsData)) {
      setFilters([]);
      setFiltersPlate([]);
      setNewData([]);
      return;
    } else {
      const x: any = [];
      const y: any = [];
      const z: any = [];
      bookingsData.map((item: any) =>
        x.push({
          text: item.fullName ? item.fullName : 'Quickbook',
          value: item.fullName ? item.fullName : 'Quickbook',
        }),
      );
      bookingsData.map((item: any) =>
        y.push({
          text: item.licensePlate.toUpperCase(),
          value: item.licensePlate,
        }),
      );
      bookingsData.map((item: any) => z.push({ ...item, key: item._id }));
      setNewData(z);
      setFiltersPlate(y);
      setFilters(x);
    }
  }, [bookingsData]);

  const getReserved = () => {
    const d = {
      date: SpecificDate,
    };

    setLoading(true);
    dispatch(doGetSpecificDateData(d))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!SpecificDate) return;
    getReserved();
  }, [SpecificDate]);

  const ConvertDate = (date: any) => {
    const newD = date.split('T')[0];
    return newD;
  };

  const BulkCheckIn = () => {
    const d = {
      id: selectedData,
    };
    setBulkLoading(true);
    dispatch(doBulkCheckIn(d))
      .unwrap()
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setBulkLoading(false);
        getReserved();
        setSelectedData([]);
      });
  };

  return (
    <div>
      <PageTitle>Schedule</PageTitle>

      <Card
        style={{ marginTop: '10px' }}
        title={
          <div
            style={
              isTablet
                ? { display: 'flex', justifyContent: 'space-between' }
                : { display: 'flex', flexDirection: 'column' }
            }
          >
            <Title level={5} style={{ fontWeight: 'bold' }}>
              Scheduled: {loading ? <Spin /> : bookingsData && bookingsData.length}
            </Title>
            {/*<div style={isTablet ? { marginTop: '0px' } : { marginTop: '10px' }}>*/}
            {/*  <Button size={isTablet ? 'large' : 'small'} icon={<PlusOutlined />} onClick={handleNavigate}>*/}
            {/*    Add New*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
        }
      >
        {!_.isEmpty(selectedData) && (
          <div style={isTablet ? { display: 'flex' } : { display: 'flex', flexDirection: 'column' }}>
            <Button size={'small'} onClick={BulkCheckIn} disabled={bulkLoading}>
              Checkin selected items {bulkLoading && <Spin />}
            </Button>
          </div>
        )}
        <Row gutter={[30, 30]} style={{ marginTop: '20px' }}>
          <Col span={24}>
            <NewTable
              rowSelection={{
                onChange: (selectedRows: any[]) => setSelectedData(selectedRows),
              }}
              style={{ background: 'transparent' }}
              dataSource={newData}
              pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
              loading={loading}
            >
              <Column
                title="Details"
                responsive={['sm']}
                sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                render={(record: any) => (
                  <React.Fragment>
                    {record && record.createdAt && (
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Booking:</Typography>
                        <Typography style={{ marginLeft: '3px' }}>
                          {moment(ConvertDate(record.createdAt)).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                    )}
                    {record && record.sailingDate && (
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Sailing:</Typography>
                        <Typography style={{ marginLeft: '3px' }}>
                          {moment(ConvertDate(record.sailingDate)).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                    )}
                    {record && record.checkOut && (
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Exit:</Typography>
                        <Typography style={{ marginLeft: '3px' }}>
                          {moment(ConvertDate(record.checkOut)).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                    )}
                    {record && record.status && (
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Status:</Typography>
                        <Typography style={{ marginLeft: '3px' }}>{record.status}</Typography>
                      </div>
                    )}
                  </React.Fragment>
                )}
              />
              <Column
                title="Details"
                filters={filtersPlate}
                filterSearch={true}
                onFilter={(value: any, record: any) => record.licensePlate.includes(value)}
                // sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                render={(record: any) => (
                  <React.Fragment>
                    {record && record.fullName && record.fullName ? (
                      <>
                        {record && record.fullName && (
                          <div style={{ display: 'flex' }}>
                            <Typography style={{ marginLeft: '3px', fontWeight: 'bold' }}>{record.fullName}</Typography>
                          </div>
                        )}
                        {record && record.email && (
                          <div style={{ display: 'flex' }}>
                            <Typography style={{ marginLeft: '3px' }}>{record.email}</Typography>
                          </div>
                        )}
                        {record && record.phone && (
                          <div style={{ display: 'flex' }}>
                            <Typography style={{ marginLeft: '3px' }}>{record.phone}</Typography>
                          </div>
                        )}
                      </>
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Ribbon style={{ borderRadius: '3px 0px 0px 3px' }} text={'Quickbook'} color={'green'} />
                      </div>
                    )}

                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {record.licensePlate && record.licensePlate.toUpperCase()}
                    </span>
                    <br />
                    {record && record.color && (
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ marginLeft: '3px' }}>
                          {record.color.charAt(0).toUpperCase() + record.color.slice(1)}
                        </Typography>
                      </div>
                    )}
                    {record && record.createdAt && (
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Booking:</Typography>
                        <Typography style={{ marginLeft: '3px' }}>
                          {moment(ConvertDate(record.createdAt)).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                    )}
                    {record && record.sailingDate && (
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Sailing:</Typography>
                        <Typography style={{ marginLeft: '3px' }}>
                          {moment(ConvertDate(record.sailingDate)).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                    )}
                    {record && record.checkOut && (
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Exit:</Typography>
                        <Typography style={{ marginLeft: '3px' }}>
                          {moment(ConvertDate(record.checkOut)).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                    )}
                  </React.Fragment>
                )}
                responsive={['xs']}
              />
              {/*<Column*/}
              {/*  title="Check Out"*/}
              {/*  dataIndex="checkOut"*/}
              {/*  key="checkOut"*/}
              {/*  responsive={['sm']}*/}
              {/*  sorter={(a: any, b: any) => (a.checkOut < b.checkOut ? -1 : 1)}*/}
              {/*  render={(checkOut: string) => <>{checkOut && `${moment(checkOut).format('MM/DD/YYYY')}`}</>}*/}
              {/*/>*/}
              {/*<Column responsive={['sm']} title="Color" dataIndex="color" key="color" />*/}
              <Column
                filters={filters}
                filterSearch={true}
                onFilter={(value: any, record: any) =>
                  record.fullName
                    ? record.fullName.toLowerCase().includes(value.toLowerCase())
                    : value === 'Quickbook' && !record.fullName
                }
                render={(record: any) => (
                  <React.Fragment>
                    {record && record.fullName && record.fullName ? (
                      <>
                        {record && record.fullName && (
                          <div style={{ display: 'flex' }}>
                            <Typography style={{ marginLeft: '3px', fontWeight: 'bold' }}>{record.fullName}</Typography>
                          </div>
                        )}
                        {record && record.email && (
                          <div style={{ display: 'flex' }}>
                            <Typography style={{ marginLeft: '3px' }}>{record.email}</Typography>
                          </div>
                        )}
                        {record && record.phone && (
                          <div style={{ display: 'flex' }}>
                            <Typography style={{ marginLeft: '3px' }}>{record.phone}</Typography>
                          </div>
                        )}
                      </>
                    ) : (
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Ribbon style={{ borderRadius: '3px 0px 0px 3px' }} text={'Quickbook'} color={'green'} />
                      </div>
                    )}
                  </React.Fragment>
                )}
                responsive={['sm']}
                title="Name / Email / Phone"
              />
              <Column
                filters={filtersPlate}
                filterSearch={true}
                onFilter={(value: any, record: any) => record.licensePlate.includes(value)}
                render={(record: any) => (
                  <React.Fragment>
                    {record.licensePlate && record.licensePlate.toUpperCase()}
                    <br />
                    {record && record.color && (
                      <div style={{ display: 'flex' }}>
                        <Typography style={{ marginLeft: '3px' }}>
                          {record.color.charAt(0).toUpperCase() + record.color.slice(1)}
                        </Typography>
                      </div>
                    )}
                  </React.Fragment>
                )}
                responsive={['sm']}
                title="License / Color"
              />
              {/*<Column*/}
              {/*  title="Updated At"*/}
              {/*  dataIndex="updatedAt"*/}
              {/*  key="updatedAt"*/}
              {/*  responsive={['sm']}*/}
              {/*  sorter={(a: any, b: any) => (a.updatedAt < b.updatedAt ? -1 : 1)}*/}
              {/*  render={(updatedAt: string) => <>{updatedAt && `${moment(updatedAt).format('MM/DD/YYYY')}`}</>}*/}
              {/*/>*/}
              {/*<Column*/}
              {/*  title="Change Status"*/}
              {/*  key="action"*/}
              {/*  render={(_: any, record: any) => (*/}
              {/*    <div style={{ display: 'flex', justifyContent: 'space-between' }}>*/}
              {/*      <Button*/}
              {/*        disabled={loadingCheckinImage && record?._id === id}*/}
              {/*        size="small"*/}
              {/*        onClick={() => {*/}
              {/*          setId(record?._id);*/}
              {/*          setShowImage(!showImage);*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        PLate {loadingCheckinImage && record?._id === id && <Spin />}*/}
              {/*      </Button>*/}
              {/*      <Button*/}
              {/*        size="small"*/}
              {/*        disabled={loadingCheckin && record?._id === id}*/}
              {/*        onClick={() => {*/}
              {/*          setShowQr(!showQr);*/}
              {/*          setId(record?._id);*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        QR {loadingCheckin && record?._id === id && <Spin />}*/}
              {/*      </Button>*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*/>*/}
            </NewTable>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default SpecificDateData;
