import * as yup from 'yup';
const mask = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/gim;
//Schema
export const ContactSchema = (x) =>
  yup.object().shape({
    ship: yup.object().typeError('Please select Cruise.').required('Please select Cruise.'),
    checkIn: x === 0 ? yup.string().required(' Please enter check in date.') : null,
    // checkOut:
    //   x === 0 ? yup.string().required(" Please enter an out date.") : null,
    fullName: x === 1 ? yup.string().required(' Please enter name.') : null,
    // email: x === 1 ? yup.string().email('Please enter a valid email.').required(' Please enter an email.') : null,
    licensePlate: x === 1 ? yup.string().required(' Please enter license plate.') : null,
    phone:
      x === 1 ? yup.string().required('Please enter phone number.').matches(mask, 'Phone number is not valid.') : null,
    carBrand: x === 2 ? yup.object().typeError('Please select car brand.').required('Please select car brand.') : null,
    carModel: x === 2 ? yup.object().typeError('Please select car model.').required('Please select car model.') : null,
    color: x === 2 ? yup.string().required(' Please enter color.') : null,

    addressLine1: x === 3 ? yup.string().required(' Please enter an addressLine 1.') : null,

    city: x === 3 ? yup.string().required(' Please enter city.') : null,
    zip:
      x === 3
        ? yup
            .string()
            .matches(/^[0-9]{5}$/, 'Please enter only 5 digits.')
            .typeError('Please enter only digits.')
            .required(' Please enter zip.')
        : null,
    state: x == 3 ? yup.object().typeError('Please select state.').required('Please select state.') : null,
  });

export const TransactionSchema = (x) =>
  yup.object().shape({
    TransactionId: x === 'card' ? yup.string().required(' Please enter Transaction ID.') : null,
  });

export const UpdateRateSchema = yup.object().shape({
  rate: yup.number().typeError('Please enter rate.').required('Please enter rate.'),
});

export const AddQuickBook = yup.object().shape({
  licensePlate: yup.string().required(' Please enter license plate.'),
});
