import { Card, Col, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

type cardInterFace = {
  count: any;
  icon: any;
  title: any;
  click: string;
};

export const DashCard2 = ({ count, icon, title, click }: cardInterFace) => {
  return (
    <Col xs={24} sm={12} md={8} lg={6} xl={6} style={{ padding: '10px' }}>
      <Link to={`${click}`}>
        <Card
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'column',
            height: '250px',
            // width: '225px',
            boxShadow: '5px 8px 24px 5px rgba(100, 100, 100, 0.6)',
            borderRadius: '10px',
          }}
        >
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                style={{
                  height: '100px',
                  width: '100px',
                  borderRadius: '50%',
                  backgroundColor: 'lightblue',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <img
                  height={'50px'}
                  width={'50px'}
                  color={'#1D1F53'}
                  style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                  src={icon}
                  alt={title}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', height: '50px' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: '15px', textAlign: 'center' }}>{title}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography style={{ fontWeight: 'bold', fontSize: '15px' }}>{count && count}</Typography>
            </div>
          </div>
        </Card>
      </Link>
    </Col>
  );
};
