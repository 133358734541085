import { httpApi } from '@app/api/http.api';

export interface bookingResponse {
  data: any;
  message: any;
}

export interface getBookingRequest {
  to: string;
  from: string;
}

export interface getReservationRequest {
  status: string | '';
}

export interface CheckInRequest {
  id: string;
}

//On and Off today's booking
export const getTodaysBooking = (payload: any): Promise<bookingResponse> =>
  httpApi.post<bookingResponse>('reservation/availability/get', { ...payload }).then(({ data }) => data);

export const updateTodaysBooking = (payload: any): Promise<bookingResponse> =>
  httpApi.post<bookingResponse>('reservation/availability/update', { ...payload }).then(({ data }) => data);

//On and Off today's booking//

export const getBookings = (payload: getBookingRequest): Promise<bookingResponse> =>
  httpApi.post<bookingResponse>('reservation/filter/reservations', { ...payload }).then(({ data }) => data);

export const getReservations = (payload: getReservationRequest): Promise<bookingResponse> =>
  httpApi.post<bookingResponse>('reservation/filter/reservations', { ...payload }).then(({ data }) => data);

export const bulkCheckout = (payload: any): Promise<bookingResponse> =>
  httpApi.post<any>('reservation/check/out/multiple', { ...payload }).then(({ data }) => data);

export const revertStatus = (payload: any): Promise<bookingResponse> =>
  httpApi.post<any>('reservation/update/status/multiple', { ...payload }).then(({ data }) => data);

export const bulkCheckIn = (payload: any): Promise<bookingResponse> =>
  httpApi.post<any>('reservation/check/in/multiple', { ...payload }).then(({ data }) => data);

export const unused = (payload: any): Promise<bookingResponse> =>
  httpApi.post<any>('reservation/update/status/multiple', { ...payload }).then(({ data }) => data);

export const getSpecificDateData = (payload: any): Promise<bookingResponse> =>
  httpApi.post<bookingResponse>('reservation/filter/reservations/data', { ...payload }).then(({ data }) => data);

export const checkIn = (CheckInData: CheckInRequest): Promise<bookingResponse> =>
  httpApi.post<bookingResponse>('reservation/check/in/qr', { ...CheckInData }).then(({ data }) => data);

export const checkOut = (CheckInData: CheckInRequest): Promise<bookingResponse> =>
  httpApi.post<bookingResponse>('reservation/check/out/qr', { ...CheckInData }).then(({ data }) => data);

export const updateRecord = (data: any): Promise<bookingResponse> =>
  httpApi.post<bookingResponse>('reservation/update', { ...data }).then(({ data }) => data);

export const updateSailingDate = (data: any): Promise<bookingResponse> =>
  httpApi.post<bookingResponse>('reservation/update/sailing/date', { ...data }).then(({ data }) => data);

export const editSlots = (data: any): Promise<any> =>
  httpApi.post<any>('reservation/update/slot/space', { ...data }).then(({ data }) => data);

export const filterBookingCall = (data: any): Promise<any> =>
  httpApi.post<any>('reservation/search/exit/data', { ...data }).then(({ data }) => data);
