import * as yup from 'yup';
const mask = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/gim;
//Schema
export const ContactSchema = (x) =>
  yup.object().shape({
    ship: yup.object().typeError('Please select Cruise.').required('Please select Cruise.'),
    checkIn: x === 0 ? yup.string().required(' Please enter check in date.') : null,
    // checkOut:
    //   x === 0 ? yup.string().required(" Please enter an out date.") : null,
    fullName:
      x === 1
        ? yup
            .string()
            .required(' Please enter name.')
            .matches(/^\S/, 'Please remove spaces from the start.')
            .matches(/^[A-Za-z ]+$/, 'Please enter only letters.')
        : null,
    // email: x === 1 ? yup.string().email('Please enter a valid email.').required(' Please enter an email.') : null,
    licensePlate:
      x === 1
        ? yup
            .string()
            .required(' Please enter license plate.')
            .test('no-spaces', 'Please enter license plate number without spaces.', (value) => {
              return value === null || value === undefined || !/\s/.test(value);
            })
        : null,
    phone:
      x === 1 ? yup.string().required('Please enter phone number.').matches(mask, 'Phone number is not valid.') : null,
    carBrand: x === 2 ? yup.object().typeError('Please select car brand.').required('Please select car brand.') : null,
    carModel: x === 2 ? yup.object().typeError('Please select car model.').required('Please select car model.') : null,
    color: x === 2 ? yup.string().required(' Please enter color.') : null,

    addressLine1: x === 3 ? yup.string().required(' Please enter an addressLine 1.') : null,

    city: x === 3 ? yup.string().required(' Please enter city.') : null,
    zip:
      x === 3
        ? yup
            .string()
            .matches(/^[0-9]{5}$/, 'Please enter only 5 digits.')
            .typeError('Please enter only digits.')
            .required(' Please enter zip.')
        : null,
    state: x == 3 ? yup.object().typeError('Please select state.').required('Please select state.') : null,
  });

export const TransactionSchema = (x) =>
  yup.object().shape({
    TransactionId: x === 'card' ? yup.string().required(' Please enter Transaction ID.') : null,
  });

export const UpdateRateSchema = yup.object().shape({
  rate: yup
    .number()
    .typeError('Please enter rate.')
    .required('Please enter rate.')
    .test('Is positive?', 'The rate must be greater than 0!', (value) => value > 0),
});

export const SelectRefundStatus = yup.object().shape({
  refundStatus: yup.object().typeError('Please Select Status.').required('Please Select Status.'),
});
yup.addMethod(yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: ' Please fill at least one field  before search.',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value) => value == null || list.some((f) => !!value[f]),
  });
});
export const InvoicesSearchSchema = yup
  .object()
  .shape({
    licensePlate: yup.string(),
    email: yup.string().email('Please enter a valid email.'),
    sailingDate: yup.date().typeError(' Please enter date.').required(' Please enter date.'),
  })
  .atLeastOneOf(['licensePlate', 'email']);

export const UpdateOfferRateSchema = yup.object().shape({
  days: yup
    .number()
    .typeError('Please enter days.')
    .required('Please enter days.')
    .test('Is positive?', 'The days must be greater than 0!', (value) => value > 0),
  // statusX: yup.bool().required(' Please enter date.'),
  rate: yup
    .number()
    .typeError('Please enter rate.')
    .required('Please enter rate.')
    .test('Is positive?', 'The rate must be greater than 0!', (value) => value > 0),
  validUntil: yup.date().typeError(' Please enter date.').required(' Please enter date.'),
});

export const newBillingSchema = (x, typeName, billingName) =>
  yup.object().shape({
    type: yup
      .string()
      .required(' Please enter type.')
      .matches(/^\S/, 'Please remove spaces from the start.')
      .matches(/^[A-Za-z ]+$/, 'Please enter only letters.')
      .max(5, 'Type must be at most 5 characters long.')
      .test('check-type', 'This type has already been selected.', function (value) {
        return !typeName.includes(value);
      }),
    name: yup
      .string()
      .required(' Please enter name.')
      .matches(/^\S/, 'Please remove spaces from the start.')
      .matches(/^[A-Za-z ]+$/, 'Please enter only letters.')
      .test('check-type', 'This name has already been selected.', function (value) {
        return !billingName.includes(value);
      }),
    // name: yup.string().required(' Please enter name.').matches(/^\S/, 'Please remove spaces from the start.').matches(/^[A-Za-z ]+$/, 'Please enter only letters.'),
    rate: yup
      .string()
      .required('Please enter rate.')
      .matches(/^\d+(\.\d{1,2})?$/, 'Daily rate must be a positive value up to two decimal places greater than zero.')
      .test(
        'greater-than-zero',
        'Daily rate must be a positive value up to two decimal places greater than zero. ',
        (value) => {
          if (!value) return false;
          const floatValue = parseFloat(value);
          return floatValue > 0;
        },
      ),
    step: !x ? yup.string().required(' Please enter step.') : null,
    question: !x ? yup.string().required(' Please enter question.') : null,
    description: !x ? yup.string().required(' Please enter description.') : null,
    checkBox: !x ? yup.string().required(' Please enter checkBox.') : null,
  });

export const ExitsSearchFormSchema = yup
  .object()
  .shape({
    licensePlate: yup.string(),
    email: yup.string().email('Please enter a valid email.'),
    name: yup.string(),
    phone: yup.string().test({
      name: 'phone',
      message: 'Phone number is not valid.',
      test: (value) => {
        // Check if phone is not empty and does not match the mask
        return !(value && value.trim() !== '' && !mask.test(value));
      },
    }),
  })
  .atLeastOneOf(['licensePlate', 'email', 'name', 'phone']);
