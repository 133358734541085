import axios from 'axios';
import { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readToken } from '@app/services/localStorage.service';

export const httpApi = axios.create(
  process.env.REACT_APP_ENVIROMENTS === 'production'
    ? {
        baseURL: 'https://api.parkgenix.com/api/',
      }
    : {
        baseURL: 'https://uat.parkgenix.com/api/',
      },
);

console.log(process.env.REACT_APP_ENVIROMENTS);

httpApi.interceptors.request.use((config) => {
  config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}` };
  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.response?.status === 401) {
    if (window.location.href.includes('auth')) {
      throw new ApiError<ApiErrorData>(
        error.response?.data.message || 'Error completing your request. Please try again in a few minutes.',
      );
    } else if (error.response && error.response?.data && error.response?.data.message) {
      throw new ApiError<ApiErrorData>(
        error.response?.data.message || 'Error completing your request. Please try again in a few minutes.',
      );
    } else {
      localStorage.removeItem('accessToken');
      window.history.pushState('', '', '/auth/login');
      window.location.reload();
      throw new ApiError<ApiErrorData>(error.response?.data.message || 'Your Session has been expired');
    }
  } else {
    throw new ApiError<ApiErrorData>(
      error.response?.data.message || 'Error completing your request. Please try again in a few minutes.',
    );
  }
});

export interface ApiErrorData {
  message: string;
}
