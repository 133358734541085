import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { changeDates, changeOfferStatus, changeRate, getShips } from '@app/api/ships.api';
import { ChangeStatusRequest, changeStatus, AddShipRequest, addShip } from '@app/api/ships.api';

export interface AuthSlice {
  shipsData: string | null;
  ShipDetails: any | null;
  addNewShipLoader: boolean;
}

const initialState: AuthSlice = {
  shipsData: '',
  ShipDetails: {},
  addNewShipLoader: false,
};

export const doGetShips = createAsyncThunk('getShips', async () => getShips().then((res) => res));

export const doAddShip = createAsyncThunk('AddManager', async (addShipPayload: AddShipRequest) =>
  addShip(addShipPayload).then((res) => res),
);

export const updateShipStatus = createAsyncThunk('changeStatus', async (changeStatusPayload: ChangeStatusRequest) =>
  changeStatus(changeStatusPayload).then((res) => res),
);
export const updateShipOfferStatus = createAsyncThunk('changeOfferStatus', async (changeStatusPayload: any) =>
  changeOfferStatus(changeStatusPayload).then((res) => res),
);
export const updateShipRate = createAsyncThunk('UpdateRate', async (changeStatusPayload: any) =>
  changeRate(changeStatusPayload).then((res) => res),
);

export const updateShipDates = createAsyncThunk('UpdateDates', async (changeStatusPayload: any) =>
  changeDates(changeStatusPayload).then((res) => res),
);

export const shipsDetails = createAction('shipsDetails', (data) => {
  return {
    payload: data,
  };
});

const shipSlice = createSlice({
  name: 'ship',
  initialState,
  reducers: {
    ShipDetails: (state, action) => {
      state.ShipDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doAddShip.fulfilled, (state, action) => {
      // console.log("state data", state)
      state.addNewShipLoader = false;
    });
    builder.addCase(doGetShips.fulfilled, (state, action) => {
      state.shipsData = action.payload.data;
    });
    builder.addCase(updateShipStatus.fulfilled, (state, action) => {
      state.shipsData = action.payload.data;
      if (state.ShipDetails && state.ShipDetails._id) {
        state.ShipDetails = action.payload.data.find((item: any) => item._id === state.ShipDetails?._id);
      }
    });
    builder.addCase(updateShipOfferStatus.fulfilled, (state, action) => {
      if (state.ShipDetails && state.ShipDetails._id) {
        state.ShipDetails = action.payload.data;
      }
    });
    builder.addCase(updateShipRate.fulfilled, (state, action) => {
      state.shipsData = action.payload.data;
      if (state.ShipDetails && state.ShipDetails._id) {
        state.ShipDetails = action.payload.data.find((item: any) => item._id === state.ShipDetails?._id);
      }
    });
    builder.addCase(shipsDetails, (state, action) => {
      state.ShipDetails = action.payload;
    });
    builder.addCase(updateShipDates.fulfilled, (state, action) => {
      state.ShipDetails = action.payload.data;
    });
  },
});

export default shipSlice.reducer;
