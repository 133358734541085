import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import * as Common from '@app/components/layouts/Common/CommonLayout.styles';
import { Button, Col, Row, Spin } from 'antd';
import { ButtonContainer, FormGroup } from '@app/components/forms/AddBookingForm/styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditParkedSchema } from '@app/components/forms/EditRecordSchema';
import { CruiseSelect } from '@app/components/forms/AddBookingForm/cruiseSelect';
import moment from 'moment';
import DatePickersEditRecord from '@app/components/MyComponents/DatePickerEditRecord';
import { doUpdateRecord } from '@app/store/slices/bookingSlice';

type comProps = {
  submitted: () => void;
  record: any;
};

export const EditParkedForm: React.FC<comProps> = (props) => {
  const { submitted, record } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const defaultValues = record
    ? {
        // status: {
        //   label: record.status ? record.status.charAt(0).toUpperCase() + record.status.slice(1) : '',
        //   value: record.status ? record.status : '',
        // },
        checkIn: moment(record.checkIn),
        // checkOut: record.checkOut ? moment(record.checkOut) : null,
      }
    : {};
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(EditParkedSchema),
    defaultValues,
  });

  const { t } = useTranslation();

  const Submit = (values: any) => {
    setLoading(true);
    const newValues = {
      ...values,
      checkIn: moment(values.checkIn).format('YYYY-MM-DD'),
      // checkOut: values.checkOut ? moment(values.checkOut).format('YYYY-MM-DD') : '',
      // status: values.status.value,
      id: record?._id,
    };
    dispatch(doUpdateRecord(newValues))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
        submitted();
        setLoading(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const Options = [
    {
      label: 'Reserved',
      value: 'reserved',
    },
    {
      label: 'Parked',
      value: 'parked',
    },
    {
      label: 'Exited',
      value: 'exited',
    },
  ];

  return (
    <Row justify="center" align="middle">
      <div style={{ position: 'relative', width: '90%' }}>
        <FormGroup autoComplete="off" onSubmit={handleSubmit(Submit)}>
          <Common.FormTitle>License: {record?.licensePlate.toUpperCase()}</Common.FormTitle>
          {/* <Col span={24}>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <CruiseSelect
                  {...register('status')}
                  label={'Current Status'}
                  onChange={(x: any) => {
                    field.onChange(x);
                  }}
                  value={field.value}
                  options={Options}
                  error={errors.status && errors.status.message}
                />
              )}
            />
          </Col> */}
          <Col span={24}>
            <Controller
              name="checkIn"
              control={control}
              render={({ field }) => (
                <DatePickersEditRecord
                  {...register('checkIn')}
                  name="Parked Date"
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  error={errors.checkIn && errors.checkIn.message}
                />
              )}
            />
          </Col>
          {/* <Col span={24}>
            <Controller
              name="checkOut"
              control={control}
              render={({ field }) => (
                <DatePickersEditRecord
                  {...register('checkOut')}
                  name="Check Out Date"
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  error={errors.checkOut && errors.checkOut.message}
                />
              )}
            />
          </Col> */}

          <ButtonContainer>
            <Button type="primary" htmlType="submit" disabled={!isDirty || isLoading}>
              Update {isLoading && <Spin />}
            </Button>
          </ButtonContainer>
        </FormGroup>
      </div>
    </Row>
  );
};
