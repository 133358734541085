import { Row, Col, Button, Spin, Input as Input2, Radio, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TransactionSchema } from './schema';
import type { RadioChangeEvent } from 'antd';

import Input from '../../MyComponents/Input';

import { ContactContainer2, FormGroup, ButtonContainer } from './styles';

type transactionForm = {
  handleSubmitAllData: any;
  loader: any;
};

const Contact = ({ handleSubmitAllData, loader }: transactionForm) => {
  const [value, setValue] = useState('cash');

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(TransactionSchema(value)),
  });

  const OnSubmit = (x: any) => {
    const y = {
      ...x,
      paymentMethod: value,
    };
    handleSubmitAllData(y);
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <ContactContainer2>
      <Row justify="space-between" align="middle">
        <Col lg={12} md={12} sm={24} xs={24}>
          <div style={{ position: 'relative' }}>
            <FormGroup autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
              <Col span={24}>
                <Typography style={{ fontWeight: 'bold' }}> Payment Method</Typography>
                <Radio.Group onChange={onChange} value={value}>
                  <Space direction="vertical">
                    <Radio value={'cash'}>Cash</Radio>
                    <Radio value={'card'}>Credit Card</Radio>
                  </Space>
                </Radio.Group>
              </Col>
              {value === 'card' && (
                <Col span={24}>
                  <Controller
                    name="TransactionId"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...register('TransactionId')}
                        type="text"
                        name="Transaction ID"
                        placeholder="Enter Transaction ID"
                        value={field.value}
                        onChange={(value) => field.onChange(value)}
                        error={errors.TransactionId && errors.TransactionId.message}
                      />
                    )}
                  />
                </Col>
              )}
              <ButtonContainer>
                <Button type="primary" htmlType="submit" disabled={loader}>
                  Submit {loader && <Spin />}
                </Button>
              </ButtonContainer>
            </FormGroup>
          </div>
        </Col>
      </Row>
    </ContactContainer2>
  );
};

export default withTranslation()(Contact);
