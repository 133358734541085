import React from 'react';
import { Card, Typography, Button } from 'antd';

import { useNavigate } from 'react-router-dom';
import AddBooking from '@app/components/forms/AddBookingForm/addBooking';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { BackIcon } from '@app/components/layouts/AuthLayout/AuthLayout.styles';

function App() {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Button onClick={() => navigate(-1)} icon={<BackIcon />}>
          Back
        </Button>
      </div>
      <PageTitle>New Parking</PageTitle>
      {/*<Button icon={<ArrowLeftOutlined />} style={{ marginBottom: '10px' }} onClick={handleNavigate}>*/}
      {/*  Back*/}
      {/*</Button>*/}
      <Card title={<Typography>Add New Booking</Typography>}>
        <AddBooking title={''} content={''} id={''} />
      </Card>
    </div>
  );
}

export default App;
