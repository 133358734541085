import { withTranslation } from 'react-i18next';
import { Container, InputError } from './styles';
import { Input } from 'antd';
import { Label } from '../TextArea/styles';
import { InputProps } from '../types';

const Input1 = ({ name, placeholder, onChange, type, error, t, value, phone1 }: InputProps) => (
  <Container style={{ width: '100%' }}>
    <Input
      placeholder={t(placeholder)}
      name={name}
      type={type}
      value={value}
      // id={name}
      // onBlur={onBlur}
      onChange={onChange}
    />
  </Container>
);

export default withTranslation()(Input1);
