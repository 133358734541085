import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row, Space, Table, Switch, Card, Calendar, Typography, Button, Modal } from 'antd';
import { Table as NewTable } from '@app/components/common/Table/Table';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useResponsive } from '@app/hooks/useResponsive';
import { notificationController } from '@app/controllers/notificationController';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import {
  doGetShips,
  updateShipDates,
  updateShipOfferStatus,
  updateShipRate,
  updateShipStatus,
} from '@app/store/slices/shipSlice';
import _ from 'lodash';
import A001 from '@app/pages/Ships/adventure.svg';
import A002 from '@app/pages/Ships/allure.svg';
import A003 from '@app/pages/Ships/radiance.svg';
import A005 from '@app/pages/Ships/voyager.svg';
import A004 from '@app/pages/Ships/harmonyv.svg';
import A006 from '@app/pages/Ships/jewel.svg';
import A007 from '@app/pages/Ships/mariner.svg';
import Default from '@app/pages/Ships/default.svg';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import UpdateRate from '@app/components/forms/UpdateRateForm';
import shipsPage from '@app/pages/Ships/shipsPage';
import { Spin } from 'antd/es';
import { array } from 'yup';
import UpdateOffersRateForm from '@app/components/forms/UpdateOffersRateForm';
import { BackIcon } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import ShipComponent from './ShipComponent';

const { Column } = Table;
const imagesArray = (key: any) => {
  if (key == 'A001') return A001;
  if (key == 'A002') return A002;
  if (key == 'A003') return A003;
  if (key == 'A004') return A004;
  if (key == 'A005') return A005;
  if (key == 'A006') return A006;
  if (key == 'A007') return A007;
  return Default;
};

const ShipsDetailsPage: React.FC = () => {
  interface DataType {
    key: string;
    duration: number;
    name: string;
    sailing_dates: string[];
    rateSchedule: string[];
    status: boolean;
    color: string;
    _id: string;
    rate: string;
  }

  const { isDesktop, isTablet } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderOffers, setLoaderOffers] = useState(false);
  const [deleteLoaderOffers, setDeleteLoaderOffers] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOffersOpen, setModalOffersOpen] = useState(false);
  const [updateRecord, setUpdateRecord] = useState({});
  const [updateOffersRecord, setUpdateOffersRecord] = useState<any>({});
  const [deletedDate, setDeletedDate] = useState('');
  const [rate, setRate] = useState('');
  const [addedDate, setAddedDate] = useState('');
  const [cRowOperation, setCRowOperation] = useState<string | null>(null);
  const [deletedRecord, setDeletedRecord] = useState<any | null>(null);
  const [cRowOfferOperation, setCRowOfferOperation] = useState<string | null>(null);
  const { t } = useTranslation();
  const { Title } = Typography;

  const ships = useAppSelector((state) => state.ship);
  const shipsData: any = ships.shipsData;
  const shipsDetails: any = ships.ShipDetails;
  const rateSchedule: any = shipsDetails.rateSchedule;
  const User = useAppSelector((state) => state.auth);
  const UserData: any = User.UserData;

  const [checkStatus, setCheckStatus] = useState(false);

  const handleChangeStatus = (checked: boolean, x: DataType) => {
    if (x.sailing_dates && x.sailing_dates.length === 0) {
      notificationController.error({ message: 'Please Add Sailing Dates First' });
      setCheckStatus(false);
    } else {
      setCRowOperation(x._id);
      dispatch(updateShipStatus({ id: x._id, status: checked }))
        .unwrap()
        .then((res: any) => {
          notificationController.success({ message: res.message });
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
        })
        .finally(() => setCRowOperation(null));
    }
  };

  const handleChangeOfferStatus = (checked: boolean, x: any) => {
    const xx = {
      shipid: shipsDetails._id,
      rateSchedule: {
        _id: x._id,
        status: checked,
      },
    };
    setCRowOfferOperation(x._id);
    dispatch(updateShipOfferStatus(xx))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setCRowOfferOperation(null));
  };

  const getCellData = (x: any) => shipsDetails.sailing_dates.includes(moment(x).format('YYYY-MM-DD'));

  const getMonthData = (x: any) => {
    let count = 0;

    shipsDetails.status &&
      shipsDetails.sailing_dates.map((t: string) => {
        moment(t).isSame(moment(x), 'month') ? count++ : null;
      });

    return count;
  };

  const openDelete = (x: any) => {
    setDeletedDate(x);
    setDeleteModalOpen(!deleteModalOpen);
  };
  const openAddDate = (x: any) => {
    setAddedDate(x);
    setAddModalOpen(!addModalOpen);
  };

  const handleCellRender = (x: any) => {
    const obj = getCellData(x);

    return obj ? (
      isTablet ? (
        <div>
          <div style={{ display: 'flex', justifyContent: ' center' }}>
            {/* <img
              src={imagesArray(shipsDetails.key)}
              alt={shipsDetails.key}
              width={50}
              height={20}
              style={{ marginBottom: '5px' }}
            /> */}
            <ShipComponent style={{ marginBottom: '5px' }} width={50} height={20} color={shipsDetails.color} />
          </div>
          {moment(x).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') && (
            <div style={{ display: 'flex', justifyContent: ' center' }}>
              <Button
                style={{ border: 'none', width: '20px', height: '20px', color: 'red' }}
                onClick={() => openDelete(moment(x).format('YYYY-MM-DD'))}
                icon={<DeleteOutlined />}
              />
            </div>
          )}
        </div>
      ) : (
        <ul className="events" style={{ overflow: 'hidden' }}>
          {/* <img src={imagesArray(shipsDetails.key)} alt={shipsDetails.key} width={25} /> */}
          <ShipComponent width={25} color={shipsDetails.color} />
          {moment(x).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') && (
            <Button
              onClick={() => openDelete(moment(x).format('YYYY-MM-DD'))}
              style={{ border: 'none', width: '20px', height: '20px', color: 'red' }}
              icon={<DeleteOutlined />}
            />
          )}
        </ul>
        // <div style={{ background: obj.color, width: '20px', height: '20px', borderRadius: '50%' }} />
      )
    ) : (
      <div>
        {moment(x).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => openAddDate(moment(x).format('YYYY-MM-DD'))}
              style={{ color: 'green' }}
              icon={<PlusOutlined />}
            />
          </div>
        )}
      </div>
    );
  };

  const handleMonthRender = (x: any) => {
    const month = getMonthData(x);
    return month ? (
      <div className="notes-month">
        <span>Total Ships {month}</span>
      </div>
    ) : null;
  };

  const setDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeletedDate('');
  };
  const setAddDateModalClose = () => {
    setAddModalOpen(false);
    setAddedDate('');
  };

  const handleSubmit = (x: any) => {
    setLoader(true);
    dispatch(updateShipDates(x))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
        setAddModalOpen(false);
        setDeleteModalOpen(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoader(false));
  };

  const handleAddDate = async () => {
    const d = [...shipsDetails.sailing_dates] || [];
    d.push(addedDate);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const wk = d.sort((a, b) => new Date(a) - new Date(b));

    const newData = {
      id: shipsDetails._id,
      sailing_dates: wk,
    };
    handleSubmit(newData);
  };

  const handleDeleteDate = () => {
    const d = [...shipsDetails.sailing_dates];
    const a = d.indexOf(deletedDate);

    d.splice(a, 1);
    const newData = {
      id: shipsDetails._id,
      sailing_dates: d,
    };
    handleSubmit(newData);
  };

  const setModal = () => {
    setModalOpen(!modalOpen);
  };
  const setModalClose = () => {
    setModalOpen(false);
    setUpdateRecord({});
    setRate('');
  };
  const handleUpdateRate = (r: any) => {
    setUpdateRecord(r);
    setRate(r?.rate);
    setModal();
  };

  const setModalOffers = () => {
    setModalOffersOpen(!modalOffersOpen);
  };
  const setModalOffersClose = () => {
    setModalOffersOpen(false);
    setUpdateOffersRecord({});
  };
  const handleUpdateOffersRate = (r: any) => {
    setUpdateOffersRecord(r);
    setModalOffers();
  };

  const handleRateSubmit = (x: any) => {
    setLoader(true);
    dispatch(updateShipRate(x))
      .unwrap()
      .then((res: any) => {
        setModalClose();
        notificationController.success({ message: res.message });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoader(false));
  };

  const handleOffersRateSubmit = (x: any) => {
    const xx = {
      shipid: shipsDetails._id,
      rateSchedule: {
        _id: updateOffersRecord?._id,
        ...x,
      },
    };
    setLoaderOffers(true);

    dispatch(updateShipOfferStatus(xx))
      .unwrap()
      .then((res: any) => {
        setModalOffersClose();
        notificationController.success({ message: res.message });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => setLoaderOffers(false));
  };

  const handleDeleteOffersRate = (x: any) => {
    setDeletedRecord(x);
    const xx = {
      shipid: shipsDetails._id,
      delete: true,
      rateSchedule: {
        _id: x?._id,
      },
    };
    setDeleteLoaderOffers(true);

    dispatch(updateShipOfferStatus(xx))
      .unwrap()
      .then((res: any) => {
        notificationController.success({ message: res.message });
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
      })
      .finally(() => {
        setDeleteLoaderOffers(false);
        setDeletedRecord(null);
      });
  };

  const desktopLayout = (
    <>
      <div style={{ marginBottom: '10px' }}>
        <Button onClick={() => navigate(-1)} icon={<BackIcon />}>
          Back
        </Button>
      </div>
      <Card
        title={
          <Title level={5} style={{ fontWeight: 'bold' }}>
            {shipsDetails ? shipsDetails.name : ''}
          </Title>
        }
      >
        <Row gutter={[30, 30]}>
          <Col span={24}>
            <NewTable dataSource={[shipsDetails]} pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}>
              <Column
                title="Name / Status"
                responsive={['xs']}
                sorter={(a: any, b: any) => (a.checkIn < b.checkIn ? 1 : -1)}
                render={(record: any) => (
                  <React.Fragment>
                    {record && (
                      <>
                        {record.name}
                        <br />
                      </>
                    )}
                    {record && record.rate && (
                      <>
                        ${record.rate}{' '}
                        <Button
                          style={{ border: 'none' }}
                          onClick={() => handleUpdateRate(record)}
                          icon={<EditOutlined style={{ color: `${record.color}` }} />}
                        />
                        <br />
                      </>
                    )}
                    <Space size="large">
                      <Switch
                        disabled={UserData && UserData.type === 'manager'}
                        loading={cRowOperation === record._id}
                        onChange={(x) => handleChangeStatus(x, record)}
                        defaultChecked={record.status}
                        checked={
                          record.sailing_dates && record.sailing_dates.length === 0 ? checkStatus : record.status
                        }
                        checkedChildren="On"
                        unCheckedChildren="Off"
                      />
                    </Space>
                    <br />
                    {record && record?.sailing_dates && `${record?.sailing_dates.length} Times`}
                  </React.Fragment>
                )}
              />
              <Column responsive={['sm']} title="Name" dataIndex="name" key="name" />
              <Column
                title="Rate"
                key="rate"
                responsive={['sm']}
                render={(_: any, record: DataType) => (
                  <React.Fragment>
                    {record && <>{record.rate && '$' + record.rate}</>}
                    <Button
                      style={{ border: 'none' }}
                      onClick={() => handleUpdateRate(record)}
                      icon={<EditOutlined style={{ color: `${record.color}` }} />}
                    />
                  </React.Fragment>
                )}
              />

              <Column
                responsive={['sm']}
                title="Sailing"
                dataIndex="sailing_dates"
                key="sailing_dates"
                render={(sailing_dates: string[]) => <>{sailing_dates && `${sailing_dates.length} Times`}</>}
              />
              <Column
                title="Color"
                key="color"
                render={(_: any, record: DataType) => (
                  <Space size="large">
                    {/* <img src={imagesArray(record.key)} alt={record.key} width={70} /> */}
                    <ShipComponent width={70} color={record.color} />
                  </Space>
                )}
              />
              <Column
                responsive={['sm']}
                title="Status"
                key="action"
                render={(_: any, record: DataType) => (
                  <Space size="large">
                    <Switch
                      disabled={UserData && UserData.type === 'manager'}
                      loading={cRowOperation === record._id}
                      onChange={(x) => handleChangeStatus(x, record)}
                      defaultChecked={record.status}
                      checked={record.sailing_dates && record.sailing_dates.length === 0 ? checkStatus : record.status}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                    />
                  </Space>
                )}
              />
            </NewTable>
          </Col>
        </Row>
        {rateSchedule && (
          <>
            <div style={{ margin: '25px 0px 0px 0px' }}>
              <Title level={5} style={{ fontWeight: 'bold' }}>
                Offers
              </Title>
            </div>
            <Row gutter={[30, 30]}>
              <Col span={24}>
                <NewTable dataSource={rateSchedule} pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}>
                  <Column
                    title="Offers Details"
                    responsive={['xs']}
                    render={(record: any) => (
                      <React.Fragment>
                        {record && record.rate && (
                          <>
                            {record && <>{record.rate && '$' + record.rate}</>}
                            <br />
                          </>
                        )}

                        {record && record.days && (
                          <>
                            {record.days} Days
                            <br />
                          </>
                        )}
                        {record && record.validDate && (
                          <>
                            {moment(record.validDate).format('MM-DD-YYYY')}
                            <br />
                          </>
                        )}

                        {record && (
                          <Space size="large">
                            <Switch
                              disabled={UserData && UserData.type === 'manager'}
                              loading={cRowOfferOperation === record._id}
                              onChange={(x) => handleChangeOfferStatus(x, record)}
                              defaultChecked={record.status ? record.status : false}
                              checkedChildren="On"
                              unCheckedChildren="Off"
                            />
                          </Space>
                        )}
                        <br />
                        <Space size="large" style={{ marginTop: '5px' }}>
                          <Button size={'small'} onClick={() => handleUpdateOffersRate(record)}>
                            {' '}
                            Update{' '}
                          </Button>
                        </Space>
                        <br />
                        <Space size="large" style={{ marginTop: '5px' }}>
                          <Button
                            size={'small'}
                            loading={record._id === deletedRecord?._id && deleteLoaderOffers}
                            onClick={() => handleDeleteOffersRate(record)}
                          >
                            {' '}
                            Delete{' '}
                          </Button>
                        </Space>
                      </React.Fragment>
                    )}
                  />

                  <Column
                    title="Days"
                    key="Days"
                    responsive={['sm']}
                    render={(_: any, record: any) => (
                      <React.Fragment>{record && <>{record.days && record.days}</>}</React.Fragment>
                    )}
                  />
                  <Column
                    title="Rate"
                    key="rate"
                    responsive={['sm']}
                    render={(_: any, record: any) => (
                      <React.Fragment>{record && <>{record.rate && '$' + record.rate}</>}</React.Fragment>
                    )}
                  />
                  <Column
                    title="Valid Until"
                    key="valid"
                    responsive={['sm']}
                    render={(_: any, record: any) => (
                      <React.Fragment>
                        {record && <>{record.validDate && moment(record.validDate).format('MM-DD-YYYY')}</>}
                      </React.Fragment>
                    )}
                  />
                  <Column
                    responsive={['sm']}
                    title="Status"
                    key="action"
                    render={(_: any, record: any) => (
                      <Space size="large">
                        <Switch
                          disabled={UserData && UserData.type === 'manager'}
                          loading={cRowOfferOperation === record._id}
                          onChange={(x) => handleChangeOfferStatus(x, record)}
                          defaultChecked={record.status}
                          checkedChildren="On"
                          unCheckedChildren="Off"
                        />
                      </Space>
                    )}
                  />
                  <Column
                    title="Update"
                    key="Update"
                    responsive={['sm']}
                    render={(_: any, record: any) => (
                      <React.Fragment>
                        <Button
                          size={'small'}
                          icon={<EditOutlined style={{ color: `#00509A` }} />}
                          onClick={() => handleUpdateOffersRate(record)}
                        />{' '}
                      </React.Fragment>
                    )}
                  />
                  <Column
                    title="Delete"
                    key="Delete"
                    responsive={['sm']}
                    render={(_: any, record: any) => (
                      <React.Fragment>
                        <Button
                          size={'small'}
                          loading={record._id === deletedRecord?._id && deleteLoaderOffers}
                          icon={<DeleteOutlined style={{ color: `red` }} />}
                          onClick={() => handleDeleteOffersRate(record)}
                        />{' '}
                      </React.Fragment>
                    )}
                  />
                </NewTable>
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col span={24}>
            {!_.isEmpty(shipsDetails) && (
              <Calendar dateCellRender={handleCellRender} monthCellRender={handleMonthRender} />
            )}
          </Col>
        </Row>
      </Card>
      <Modal
        footer={
          <div>
            <Button onClick={() => handleDeleteDate()} disabled={loader}>
              Yes {loader && <Spin />}
            </Button>
          </div>
        }
        title={
          <div>
            <DeleteOutlined style={{ border: 'none', color: 'red' }} /> Are you sure you want to delete this Date ?{' '}
          </div>
        }
        open={deleteModalOpen}
        onOk={setDeleteModalClose}
        onCancel={setDeleteModalClose}
        closeIcon={<CloseOutlined style={{ color: 'red' }} />}
      >
        {moment(deletedDate).format('MM-DD-YYYY')}
      </Modal>
      <Modal
        footer={
          <div>
            <Button onClick={() => handleAddDate()} disabled={loader}>
              Yes {loader && <Spin />}
            </Button>
          </div>
        }
        title={
          <div>
            <PlusOutlined style={{ border: 'none', color: 'green' }} /> Are you sure you want to add this Date ?
          </div>
        }
        open={addModalOpen}
        onOk={setAddDateModalClose}
        onCancel={setAddDateModalClose}
        closeIcon={<CloseOutlined style={{ color: 'red' }} />}
      >
        {moment(addedDate).format('MM-DD-YYYY')}
      </Modal>
      <Modal
        footer={[]}
        title="Rate Update"
        open={modalOpen}
        onOk={setModalClose}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => setModalClose()}
      >
        <UpdateRate
          key={rate ? rate.toString() : '123'}
          updateRecord={updateRecord}
          rate={rate}
          loader={loader}
          submit={(x: any) => handleRateSubmit(x)}
        />
      </Modal>
      <Modal
        footer={[]}
        title="Update Offer"
        open={modalOffersOpen}
        onOk={setModalOffersClose}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={() => setModalOffersClose()}
      >
        <UpdateOffersRateForm
          key={updateOffersRecord ? updateOffersRecord.toString() : '123'}
          updateRecord={updateOffersRecord}
          loader={loaderOffers}
          submit={(x: any) => handleOffersRateSubmit(x)}
        />
      </Modal>
    </>
  );

  return (
    <>
      <PageTitle>Ships</PageTitle>
      {isDesktop ? desktopLayout : desktopLayout}
    </>
  );
};

export default ShipsDetailsPage;
