import { Row, Col, Steps, Button, Spin, Typography, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactProps } from './types';
import { ContactSchema } from './schema';
import Input from '../../MyComponents/Input';
import DatePicker from '../../MyComponents/DatePicker';
import { ContactContainer, FormGroup, ButtonContainer } from './styles';
import SquarePaymentForm from './SquarePayment';
import { CruiseSelect } from './cruiseSelect';
import moment, { Moment } from 'moment';
import PaymentIdForm from '@app/components/forms/AddBookingForm/paymentIdForm';
import { CarBrand } from '@app/components/MyComponents/CarBrand';
import CarsJson from '@app/components/MyComponents/car.json';
import { CarModel } from '@app/components/MyComponents/CarModal';
import { useNavigate } from 'react-router-dom';
import StateJson from './States.json';
import { StateSelect } from '@app/components/MyComponents/States';
import { notificationController } from '@app/controllers/notificationController';
import { httpApi } from '@app/api/http.api';
import { doGetBilling, doGetBillingActive } from '@app/store/slices/billingSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';

//
const steps = [
  {
    title: 'Booking ',
    content: 'Booking',
  },
  {
    title: 'Personal',
    content: 'Personal',
  },
  // {
  //   title: 'Vehicle',
  //   content: 'Vehicle',
  // },
  // {
  //   title: 'Address',
  //   content: 'Address',
  // },
];

const Contact = ({ id }: ContactProps) => {
  const [current, setCurrent] = useState(0);
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const [loader, setLoader] = useState(false);
  const [paymentView, setPaymentView] = useState(false);
  const [data, setData] = useState<any>({});
  const [ship, setShips] = useState<any>([]);
  const [amount, setAmount] = useState<any>('');
  const [dates, setDates] = useState<any>([]);
  const [reservationSucess, setResrvationSuccess] = useState(false);
  const [shipsLoader, setShipsLoader] = useState(false);
  // const [checkOutDate, setCheckOutDate] = useState<any>('');
  const [availableSlots, setAvailableSlots] = useState(0);
  const [visible, setVisible] = useState(false);
  const [reservationError, setResrvationError] = useState<any>(null);
  const [checkOutDate, setCheckOutDate] = useState<any>(null);
  const [mailFlag, setMailFlag] = useState<any>(false);
  const dispatch = useAppDispatch();
  const billing = useAppSelector((state) => state.billing);
  const billingDataActive: any = billing.billingDataActive;
  const bookings = useAppSelector((state) => state.booking);
  const todaysBookingData: any = bookings.todaysBookingData;
  const navigate = useNavigate();

  const Link =
    process.env.REACT_APP_ENVIROMENTS === 'production'
      ? 'https://api.parkgenix.com/api/'
      : 'https://uat.parkgenix.com/api/';

  const apiCall = () => {
    setShipsLoader(true);
    httpApi
      .get(Link + 'reservation/get/ship/active')
      .then((res) => {
        setShips(res.data.data);
        setShipsLoader(false);
      })
      .catch(() => {
        setShipsLoader(false);
        console.log('error');
      });
  };
  useEffect(() => {
    dispatch(doGetBillingActive())
      .unwrap()
      .catch((err: any) => {
        notificationController.error({ message: err.message });
      });
  }, []);
  React.useEffect(() => {
    apiCall();
  }, []);

  const handleShip = (x: any) => {
    const dates = ship.find((w: any) => w.name == x.label);
    setDates(dates.sailing_dates);
  };

  // const validateEmail = (email: any) => {
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return re.test(String(email).toLowerCase());
  // };

  // const handleEmailBlur = (x: any) => {
  //   const v = validateEmail(x);
  //   if (v) {
  //     setMailFlag(true);
  //     axios
  //       .post('https://api.parkgenix.com/api/auth/validateEmailAddress', { email: x })
  //       .then(({ data }) => {
  //         if (data.status && data.data.isDeliverable) {
  //           setInvalidEmail(false);
  //         } else {
  //           setInvalidEmail(true);
  //           setError('email', { type: 'string', message: 'Not deliverable email.' });
  //         }
  //       })
  //       .catch((err) => {
  //         setInvalidEmail(true);
  //         setError('email', { type: 'string', message: 'Not deliverable email.' });
  //       })
  //       .finally(() => setMailFlag(false));
  //   }
  // };

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ContactSchema(current)),
  });
  //
  const prev = () => {
    setCurrent(current - 1);
  };

  const getOffers = (Offers: any, duration: any) => {
    let dur = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Offers) {
      Offers.map((item: any) => {
        const a = moment(item.validDate, 'YYYY-MM-DD');
        const b = moment(new Date(), 'YYYY-MM-DD');
        console.log(a.diff(b, 'days'));
        if (item.days === duration && item.status && a.diff(b, 'days') >= 0) {
          dur = item;
        }
      });

      return dur;
    } else {
      return dur;
    }
  };

  const getNumberOfDays = (s: any, cIn: any) => {
    let duration = 0;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const wk = s.sort((a, b) => new Date(a) - new Date(b));
    const w = wk.indexOf(moment(cIn).format('YYYY-MM-DD'));
    let a = moment(wk[w]);
    const c = moment(wk[w]).format('YYYY-MM-DD');
    let b = moment(wk[w + 1]);
    if (!wk[w + 1]) {
      a = moment(wk[w - 1]);
      b = moment(wk[w]);
    }
    //
    duration = b.diff(a, 'days');
    const checkout = moment(c, 'YYYY-MM-DD').add(6, 'days');
    setCheckOutDate(moment(checkout).format('YYYY-MM-DD'));
    return duration;
  };

  const OnSubmit = async (x: any) => {
    setResrvationError(null);
    setResrvationSuccess(false);
    if (current == 1) {
      const s = x.ship.label;
      const finalData = {
        ship: s,
      };
      let Index: any = {};
      Index = billingDataActive?.find((bill: any) => bill?.type === 'LTP');

      const dur = ship.find((i: any) => i.name === s);
      const duration = await getNumberOfDays(dur.sailing_dates, x.checkIn);
      const ratesOffers: any = await getOffers(dur.rateSchedule ? dur.rateSchedule : false, duration);

      let ltp: any = 0;
      if (Index) {
        ltp = duration * Index?.rate;
        const xx = ratesOffers ? ratesOffers.rate : duration * dur.rate;
        setAmount(xx + ltp);
      } else {
        if (ratesOffers) {
          setAmount(ratesOffers.rate);
        } else {
          setAmount(duration * dur.rate);
        }
      }
      const LTP = {
        ltp: ltp,
      };
      const w = { ...x, ...finalData, ...LTP };
      setData(w);
      setPaymentView(true);
    } else {
      setCurrent(current + 1);
    }
  };

  const checkDate = (d: any) => {
    if (moment(d).format('MM-DD-YYYY') > moment().format('MM-DD-YYYY')) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitAllData = async (d: any) => {
    const dur = ship.find((i: any) => i.name === data.ship);
    const duration = await getNumberOfDays(dur.sailing_dates, data.checkIn);
    const ratesOffers: any = await getOffers(dur.rateSchedule ? dur.rateSchedule : false, duration);
    const status = checkDate(data.checkIn);
    let Index: any = {};
    Index = billingDataActive?.find((bill: any) => bill?.type === 'LTP');
    let ltp: any = 0;
    let xxx: any = 0;
    if (Index) {
      ltp = duration * Index?.rate;
      const xx = ratesOffers ? ratesOffers.rate : duration * dur.rate;
      xxx = xx + ltp;
    } else {
      xxx = ratesOffers ? ratesOffers.rate : duration * dur.rate;
    }
    const amountDetail = {};

    Object.assign(amountDetail, { LTP: Index ? (duration * Index?.rate).toString() : '0' });
    Object.assign(amountDetail, { CI: '0' });
    Object.assign(amountDetail, { Tax: '0' });
    Object.assign(amountDetail, {
      Subtotal: (ratesOffers ? ratesOffers.rate : duration * dur.rate).toString(),
    });
    Object.assign(amountDetail, { Total: xxx.toString() });

    const dataX = {
      ...data,
      amountDetail,
      paymentMethod: d.paymentMethod,
      amount: xxx * 100,
      status: status ? 'parked' : 'reserved',
      checkIn: moment(data.checkIn).format('YYYY-MM-DD'),
      // checkOut: checkOutDate,
    };
    if (d.TransactionId) {
      Object.assign(dataX, { transactionId: d.TransactionId });
    }

    if (xxx > 0) {
      setLoader(true);
      httpApi
        .post('reservation/admin/booking', dataX)
        .then((e) => {
          setLoader(false);
          navigate(status ? '/parked' : '/reserved');
        })
        .catch((e) => {
          notificationController.error({
            message:
              (e.response && e.response.data && e.response.data?.message) ||
              'Error completing your request. Please try again in a few minutes.',
          });
          setLoader(false);
        });
    } else {
      notificationController.error({
        message: 'Error completing your request. Please try again in a few minutes.',
      });
    }
  };

  const checkSpace = (value: Moment) => {
    setLoader(true);
    axios
      .post(Link + 'reservation/check/available/space', {
        checkIn: moment(value).format('YYYY-MM-DD'),
      })
      .then((e) => {
        if (e && e.data) {
          setAvailableSlots(e.data.data);
          setVisible(true);
          setError('checkIn', {
            type: 'string',
            message: '',
          });
        }
        // setCurrent(current + 1);
      })
      .catch((err) => {
        setError('checkIn', {
          type: 'string',
          message:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'Error completing your request. Please try again in a few minutes.',
        });
      })
      .finally(() => setLoader(false));
  };

  const handlePhoneChange = (val: any) => {
    if (val.target.value.length < 13) {
      if (val && val.nativeEvent?.data && (val.target.value.length == 3 || val.target.value.length == 7)) {
        setValue('phone', val.target.value + '-');
      } else {
        setValue('phone', val.target.value);
      }
    }
  };

  return (
    <ContactContainer id={id}>
      <Row justify="center" align="middle">
        <Col lg={12} md={16} sm={24} xs={24}>
          <div />

          <div style={{ position: 'relative' }}>
            {shipsLoader && (
              <div style={{ top: '50%', left: '50%', right: 0, zIndex: 20 }}>
                {' '}
                <Spin size={'large'} />{' '}
              </div>
            )}
            {!paymentView && (
              <FormGroup autoComplete="off" onSubmit={handleSubmit(OnSubmit)}>
                {current === 0 && (
                  <>
                    <div>
                      {' '}
                      {visible && (
                        <Typography style={{ fontWeight: 'bold', float: 'right' }}>
                          Available Slots: {availableSlots}
                        </Typography>
                      )}
                    </div>
                    <Col span={24}>
                      <Controller
                        name="ship"
                        control={control}
                        render={({ field }) => (
                          <CruiseSelect
                            {...register('ship')}
                            label={'Cruise'}
                            onChange={(x: any) => {
                              field.onChange(x);
                              handleShip(x);
                              setValue('checkIn', '');
                            }}
                            value={field.value}
                            options={ship.map((i: any) => ({ label: i.name, value: i.name }))}
                            error={errors.ship && errors.ship.message}
                          />
                        )}
                      />
                    </Col>
                    <Col span={24}>
                      <Controller
                        name="checkIn"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            {...register('checkIn')}
                            name="Check In Date"
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                              if (value) {
                                checkSpace(value);
                              }
                            }}
                            todaysBookingData={todaysBookingData}
                            dates={dates}
                            error={errors.checkIn && errors.checkIn.message}
                          />
                        )}
                      />
                    </Col>
                  </>
                )}
                {current === 1 && (
                  <>
                    <Col span={24}>
                      <Controller
                        name="fullName"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...register('fullName')}
                            type="text"
                            name="name"
                            placeholder="Enter Name"
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                            error={errors.fullName && errors.fullName.message}
                          />
                        )}
                      />
                    </Col>
                    {/*<Col span={24}>*/}
                    {/*  <Controller*/}
                    {/*    name="email"*/}
                    {/*    control={control}*/}
                    {/*    render={({ field }) => (*/}
                    {/*      <Input*/}
                    {/*        {...register('email')}*/}
                    {/*        type="email"*/}
                    {/*        name="email"*/}
                    {/*        // onBlur={console.log('')}*/}
                    {/*        placeholder="Your Email"*/}
                    {/*        value={field.value}*/}
                    {/*        onChange={(value) => {*/}
                    {/*          field.onChange(value);*/}
                    {/*          handleChangeEmail(value);*/}
                    {/*        }}*/}
                    {/*        error={errors.email && errors.email.message}*/}
                    {/*      />*/}
                    {/*    )}*/}
                    {/*  />*/}
                    {/*</Col>*/}
                    <Col span={24}>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...register('phone')}
                            type="tel"
                            name="phone"
                            placeholder="Enter Phone"
                            // onBlur={handleEmailBlur}
                            value={field.value}
                            phone1={true}
                            onChange={(value: any) => handlePhoneChange(value)}
                            error={errors.phone && errors.phone.message}
                          />
                        )}
                      />
                    </Col>
                    <Col span={24}>
                      <Controller
                        name="licensePlate"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...register('licensePlate')}
                            type="phone"
                            name="License Plate"
                            placeholder="Enter License Plate"
                            value={field.value}
                            onChange={(value) => field.onChange(value)}
                            error={errors.licensePlate && errors.licensePlate.message}
                          />
                        )}
                      />
                    </Col>
                  </>
                )}
                {/*{current === 2 && (*/}
                {/*  <>*/}
                {/*    <Col span={24}>*/}
                {/*      <Controller*/}
                {/*        name="carBrand"*/}
                {/*        control={control}*/}
                {/*        render={({ field }) => (*/}
                {/*          <CarBrand*/}
                {/*            {...register('carBrand')}*/}
                {/*            onChange={(x: any) => {*/}
                {/*              field.onChange(x);*/}
                {/*              setValue('carModel', {});*/}
                {/*              handleModel(x);*/}
                {/*            }}*/}
                {/*            value={field.value}*/}
                {/*            options={CarsJson}*/}
                {/*            error={errors.carBrand && errors.carBrand.message}*/}
                {/*          />*/}
                {/*        )}*/}
                {/*      />*/}
                {/*    </Col>*/}
                {/*    <Col span={24}>*/}
                {/*      <Controller*/}
                {/*        name="carModel"*/}
                {/*        control={control}*/}
                {/*        render={({ field }) => (*/}
                {/*          <CarModel*/}
                {/*            {...register('carModel')}*/}
                {/*            value={field.value}*/}
                {/*            onChange={(x: any) => field.onChange(x)}*/}
                {/*            options={models}*/}
                {/*            error={errors.carModel && errors.carModel.message}*/}
                {/*          />*/}
                {/*        )}*/}
                {/*      />*/}
                {/*    </Col>*/}
                {/*    <Col span={24}>*/}
                {/*      <Controller*/}
                {/*        name="color"*/}
                {/*        control={control}*/}
                {/*        render={({ field }) => (*/}
                {/*          <Input*/}
                {/*            {...register('color')}*/}
                {/*            type="text"*/}
                {/*            name="Color"*/}
                {/*            placeholder="Enter Car Color"*/}
                {/*            value={field.value}*/}
                {/*            onChange={(value) => field.onChange(value)}*/}
                {/*            error={errors.color && errors.color.message}*/}
                {/*          />*/}
                {/*        )}*/}
                {/*      />*/}
                {/*    </Col>*/}
                {/*  */}
                {/*  </>*/}
                {/*)}*/}
                {/*{current === 3 && (*/}
                {/*  <>*/}
                {/*    <Col span={24}>*/}
                {/*      <Controller*/}
                {/*        name="addressLine1"*/}
                {/*        control={control}*/}
                {/*        render={({ field }) => (*/}
                {/*          <Input*/}
                {/*            {...register('addressLine1')}*/}
                {/*            type="text"*/}
                {/*            name="Address"*/}
                {/*            placeholder="House, Street Name"*/}
                {/*            value={field.value}*/}
                {/*            onChange={(value) => field.onChange(value)}*/}
                {/*            error={errors.addressLine1 && errors.addressLine1.message}*/}
                {/*          />*/}
                {/*        )}*/}
                {/*      />*/}
                {/*    </Col>*/}
                {/*    <Col span={24}>*/}
                {/*      <Controller*/}
                {/*        name="addressLine2"*/}
                {/*        control={control}*/}
                {/*        render={({ field }) => (*/}
                {/*          <Input*/}
                {/*            {...register('addressLine2')}*/}
                {/*            type="text"*/}
                {/*            name="Address Line 2 (Optional)"*/}
                {/*            placeholder="Apt, Suite, Building, Floor"*/}
                {/*            value={field.value}*/}
                {/*            onChange={(value) => field.onChange(value)}*/}
                {/*            error={errors.addressLine2 && errors.addressLine2.message}*/}
                {/*          />*/}
                {/*        )}*/}
                {/*      />*/}
                {/*    </Col>*/}
                {/*    <Row>*/}
                {/*      <Col span={8}>*/}
                {/*        <Controller*/}
                {/*          name="city"*/}
                {/*          control={control}*/}
                {/*          render={({ field }) => (*/}
                {/*            <Input*/}
                {/*              {...register('city')}*/}
                {/*              type="text"*/}
                {/*              name="City"*/}
                {/*              placeholder="Enter City"*/}
                {/*              value={field.value}*/}
                {/*              onChange={(value) => field.onChange(value)}*/}
                {/*              error={errors.city && errors.city.message}*/}
                {/*            />*/}
                {/*          )}*/}
                {/*        />*/}
                {/*      </Col>*/}
                {/*      <Col span={8}>*/}
                {/*        <Controller*/}
                {/*          name="state"*/}
                {/*          control={control}*/}
                {/*          render={({ field }) => (*/}
                {/*            <StateSelect*/}
                {/*              {...register('state')}*/}
                {/*              onChange={(x: any) => field.onChange(x)}*/}
                {/*              options={StateJson}*/}
                {/*              error={errors.state && errors.state.message}*/}
                {/*            />*/}
                {/*          )}*/}
                {/*        />*/}
                {/*      </Col>*/}
                {/*      <Col span={8}>*/}
                {/*        <Controller*/}
                {/*          name="zip"*/}
                {/*          control={control}*/}
                {/*          render={({ field }) => (*/}
                {/*            <Input*/}
                {/*              {...register('zip')}*/}
                {/*              type="text"*/}
                {/*              name="Zip"*/}
                {/*              placeholder="Enter Zip"*/}
                {/*              value={field.value}*/}
                {/*              onChange={(value) => field.onChange(value)}*/}
                {/*              error={errors.zip && errors.zip.message}*/}
                {/*            />*/}
                {/*          )}*/}
                {/*        />*/}
                {/*      </Col>*/}
                {/*    </Row>*/}
                {/*  </>*/}
                {/*)}*/}

                {current > 0 && (
                  <Col span={24} style={{ marginBottom: '15px', marginTop: '10px' }}>
                    <Steps labelPlacement={'vertical'} current={current} items={items} size={'small'} />
                  </Col>
                )}

                <ButtonContainer>
                  {current > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                      Previous
                    </Button>
                  )}

                  <Button type="primary" htmlType="submit" disabled={loader || mailFlag || shipsLoader}>
                    {current === steps.length - 1 ? 'Submit' : 'Next'} {(mailFlag || loader) && <Spin />}
                  </Button>
                </ButtonContainer>
              </FormGroup>
            )}
          </div>

          <div>
            {paymentView && (
              <>
                <SquarePaymentForm data={data} weekCount={amount} />
                <PaymentIdForm handleSubmitAllData={handleSubmitAllData} loader={loader} />
              </>
            )}
          </div>
        </Col>
      </Row>
    </ContactContainer>
  );
};

export default withTranslation()(Contact);
